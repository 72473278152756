'use strict'

import { Flex, CommonButton } from '@symbo.ls/ui'

export const AccessButtons = {
  extend: Flex,
  props: {
    gap: 'B2',
    alignItems: 'center'
  },

  childExtend: {
    extend: CommonButton,
    props: {
      flow: 'row-reverse',
      gap: 'X2',
      round: 'D',
      transition: 'defaultBezier A gap',
      ':hover': { gap: 'Z1' },
      caption: { fontSize: 'A' }
    }
  },
  ...[
    {
      extend: 'Link',
      props: {
        fontWeight: '500',
        href: '/get-early-access',
        theme: 'access',
        padding: 'Z1+U B2+V',
        Icon: {
          name: 'arrowAngleUpRight',
          margin: '-W1 - - -'
        },
        caption: { text: 'Get early access' }
      }
    },

    {
      extend: 'DocsLink',
      props: {
        href: '/schedule-demo',
        theme: 'transparent',
        color: 'white',
        Icon: {
          name: 'arrowUpRight',
          color: 'white'
        },
        caption: {
          text: 'Schedule a demo',
          color: 'white'
        }
      }
    }
  ]
}
