'use strict'

import { Button } from 'smbls'

import style, { heading } from './style'

export const label = {
  tag: 'label',
  style: heading,
  attr: { for: 'name-it' },
  caption: 'Name the component',
  span: {
    tag: 'span',
    attr: { contentEditable: true, placeholder: 'you name it', id: 'name-it' }
  }
}

export const SubHeader = {
  style,
  tag: 'header',
  label,
  nav: { childExtend: Button }
}
