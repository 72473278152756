'use strict'

export const ScrollToNode = {
  define: {
    $scrollToNode: (param, { attr, node, __ref }) => {
      const { __root } = __ref
      const { hash } = __root.state
      if (!hash) return
      if (attr.id !== hash.slice(1)) return
      const rootNode = __root.node
      const activeNode = node

      const top = activeNode.getBoundingClientRect().top + rootNode.scrollTop - 140
      if (top) rootNode.scrollTo({ behavior: 'smooth', top, left: 0 })
      else rootNode.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
    }
  }
}
