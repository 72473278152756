'use strict'

import { StateDesignElement } from '..'
import { Code } from './Code'
import { PropertyValues } from './PropertyValues'
import { detailsIintialState, detailsShowState } from './transitions'

export const MoreDetails = {
  extend: 'Grid',
  if: ({ state }) => state.category || state.properties || state.code,

  props: ({ state }) => ({
    columns: '3.45fr 11fr',
    gridColumn: 'span 4',
    margin: '0 -A',
    padding: '0 A',
    alignItems: 'flex-start',

    ...detailsIintialState,
    ...(state.__element.props.isActive ? detailsShowState : {})
  }),

  libraryItem: {
    content: {
      extend: StateDesignElement,
      if: ({ state }) => state.category
    }
  },

  properties: {
    props: {
      flexFlow: 'column',
      padding: 'C 0 C1',
      gap: 'B1'
    },

    propertyValues: PropertyValues,
    code: Code
  }
}
