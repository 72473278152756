'use strict'

import { setCookie } from '@domql/cookie'
import {
  Flex,
  WiderButton,
  ClickableItem,
  TooltipParent,
  Button
} from '..'
import { CommonFieldLike } from './Common'

const Tooltip = {
  props: {
    background: 'gray3 .95',
    position: 'absolute',
    pointerEvents: 'none',
    top: '115%',
    left: '50%',
    shape: 'tooltip',
    shapeDirection: 'top',
    fontSize: 'Z1',
    padding: 'Y Z1',
    transform: 'translate3d(-50%, 10%, 0)',
    transition: 'C defaultBezier, C defaultBezier, B defaultBezier',
    transitionProperty: 'opacity, visibility, transform',
    textAlign: 'center',
    opacity: '0',
    visibility: 'hidden',
    zIndex: 9,
    lineHeight: 1
  },
  Title: ({ parent }) => parent.props.label || parent.key,
  P: null
}

export const IconicOption = {
  extend: [ClickableItem, WiderButton, TooltipParent],
  props: ({ key, state, parent }) => {
    const active = state[parent.parent.key] === key
    return {
      active,

      '!active': {
        border: '1px, solid, transparent',
        opacity: 0.65
      },
      '.active': {
        opacity: 1,
        border: '1px, solid, gray6'
      },

      style: {
        '&:hover, &:focus-visible': {
          zIndex: 1000,
          '& [tooltip]': {
            transform: 'translate3d(-50%, 0, 0)',
            opacity: 1,
            visibility: 'visible'
          }
        }
      }
    }
  },

  Tooltip,

  on: {
    click: (ev, el, s) => {
      const key = el.parent.parent.parent.key
      const value = el.key
      setCookie(key, value)
      s.update({ [key]: value })
    }
  }
}

export const ButtonOption = {
  extend: [ClickableItem, Button],
  props: ({ key, state, parent }) => {
    const active = state[parent.parent.key] === key
    return {
      active,
      gap: 'Y',
      align: 'center flex-start',
      Icon: {
        name: 'checkmark',
        opacity: active ? 1 : 0.35
      },
      '!active': {
        border: '1px, solid, transparent',
        opacity: 0.65
      },
      '.active': {
        border: '1px, solid, gray6',
        opacity: 1
      }
    }
  },

  on: {
    click: (ev, el, s) => {
      const key = el.parent.parent.parent.key
      const value = el.key
      setCookie(key, value)
      s.update({ [key]: value })
    }
  }
}

export const ButtonOptions = {
  tag: 'section',
  extend: CommonFieldLike,

  props: {
    margin: '- - C2 -',
    gap: 'Z2',
    variant: 'iconic',
    element: {
      gap: 'A',
      maxWidth: 'I',
      alignItems: 'center'
    }
  },

  title: {},

  '.iconic': {
    element: {
      extend: Flex,
      childExtend: IconicOption
    }
  },

  '.buttons': {
    element: {
      extend: Flex,
      childExtend: ButtonOption
    }
  }
}
