'use strict'

import displayPreferences from './display-preferences-fill.svg'
import displayPreferencesOutline from './display-preferences-outline.svg'

import brightness from './brightness.svg'

import sunOutline from './sun-outline.svg'
import sun from './sun.svg'

import moonOutline from './moon-outline.svg'
import moon from './moon.svg'

import gridAlt from './grid-alt-fill.svg'
import gridAltOutline from './grid-alt-outline.svg'
import grid from './grid-fill.svg'
import gridOutline from './grid-outline.svg'

import rowsAlt from './rows-alt-fill.svg'
import rowsAltOutline from './rows-alt-outline.svg'
import rows from './rows-fill.svg'
import rowsOutline from './rows-outline.svg'

import columnsAlt from './columns-alt-fill.svg'
import columnsAltOutline from './columns-alt-outline.svg'
import columns from './columns-fill.svg'
import columnsOutline from './columns-outline.svg'

import list from './list-fill.svg'
import listOutline from './list-outline.svg'

import layoutOutline from './layout-outline.svg'
import layout from './layout-fill.svg'

import deviceBigScreen from './device-big-screen-fill.svg'
import deviceBigScreenHalfFill from './device-big-screen-half-fill.svg'
import deviceBigScreenOutline from './device-big-screen-outline.svg'
import deviceMobile from './device-mobile-fill.svg'
import deviceMobileHalfFill from './device-mobile-half-fill.svg'
import deviceMobileOutline from './device-mobile-outline.svg'
import deviceSmallScreen from './device-small-screen-fill.svg'
import deviceSmallScreenHalfFill from './device-small-screen-half-fill.svg'
import deviceSmallScreenOutline from './device-small-screen-outline.svg'
import deviceTabletLandscape from './device-tablet-landscape-fill.svg'
import deviceTabletLandscapeHalfFill from './device-tablet-landscape-half-fill.svg'
import deviceTabletLandscapeOutline from './device-tablet-landscape-outline.svg'
import deviceTabletPortrait from './device-tablet-portrait-fill.svg'
import deviceTabletPortraitHalfFill from './device-tablet-portrait-half-fill.svg'
import deviceTabletPortraitOutline from './device-tablet-portrait-outline.svg'

import zoomIn from './zoom-in.svg'
import zoomOut from './zoom-out.svg'
import zoomReset from './zoom-reset.svg'

import printOutline from './print-outline.svg'
import print from './print.svg'

export {
  displayPreferences,
  displayPreferencesOutline,

  brightness,

  sunOutline,
  sun,

  moonOutline,
  moon,

  gridAlt,
  gridAltOutline,
  grid,
  gridOutline,

  rowsAlt,
  rowsAltOutline,
  rows,
  rowsOutline,

  columnsAlt,
  columnsAltOutline,
  columns,
  columnsOutline,

  list,
  listOutline,

  layoutOutline,
  layout,

  deviceBigScreen,
  deviceBigScreenHalfFill,
  deviceBigScreenOutline,
  deviceMobile,
  deviceMobileHalfFill,
  deviceMobileOutline,
  deviceSmallScreen,
  deviceSmallScreenHalfFill,
  deviceSmallScreenOutline,
  deviceTabletLandscape,
  deviceTabletLandscapeHalfFill,
  deviceTabletLandscapeOutline,
  deviceTabletPortrait,
  deviceTabletPortraitHalfFill,
  deviceTabletPortraitOutline,

  zoomIn,
  zoomOut,
  zoomReset,

  printOutline,
  print
}
