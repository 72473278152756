'use strict'

import { DocsLink, Flex, IconText } from '@symbo.ls/ui'

export const Footer = {
  tag: 'footer',
  extend: Flex,
  props: {
    width: '100%',
    maxWidth: '1920px',
    flow: 'column',
    align: 'stretch center',
    gap: 'D',
    border: 'solid, white .25',
    borderWidth: '.5px 0 0 0',
    padding: 'C1 - - -'
  },

  navLists: {
    extend: Flex,
    props: {
      flex: '1'
    },
    childExtend: {
      tag: 'nav',
      extend: Flex,
      props: {
        flow: 'column',
        alignItems: 'flex-start'
      }
    },
    ...[{
      props: { gap: 'B2' },
      childExtend: {
        extend: [DocsLink, IconText],
        props: {
          icon: 'arrowUpRight',
          gap: 'Y2'
        }
      },
      ...[
        { props: { text: 'Get early access' } },
        { props: { text: 'Schedule a demo' } },
        { props: { text: 'Newsletter' } }
      ]
    }, {
      props: {
        gap: 'B',
        margin: '- G - F'
      },
      childExtend: {
        extend: DocsLink,
        props: {
          fontWeight: '400',
          color: 'grey'
        }
      },
      ...[
        {
          tag: 'caption',
          props: {
            text: 'Product',
            fontWeight: '700',
            color: 'white'
          }
        },
        { props: { text: 'Pricing' } },
        { props: { text: 'Features' } },
        { props: { text: 'Integrations' } },
        { props: { text: 'API' } }
      ]
    }, {
      props: {
        gap: 'B'
      },
      childExtend: {
        extend: DocsLink,
        props: {
          fontWeight: '400',
          color: 'grey'
        }
      },
      ...[
        {
          tag: 'caption',
          props: {
            text: 'Developers',
            fontWeight: '700',
            color: 'white'
          }
        },
        { props: { text: 'Integration' } },
        { props: { text: 'Design System' } },
        { props: { text: 'Components' } },
        { props: { text: 'Content' } },
        { props: { text: 'Playground' } }
      ]
    }, {
      props: {
        flow: 'row',
        margin: '- - - auto',
        gap: 'C'
      },
      childExtend: {
        extend: DocsLink,
        Icon: { fontSize: 'B' }
      },
      ...[
        { props: { Icon: { name: 'menu' } } },
        { props: { Icon: { name: 'menu' } } },
        { props: { Icon: { name: 'menu' } } },
        { props: { Icon: { name: 'menu' } } },
        { props: { Icon: { name: 'menu' } } },
        { props: { Icon: { name: 'menu' } } }
      ]
    }]
  },

  ParagraphButton: {
    props: {
      flow: 'row-reverse',
      flex: '1',
      align: 'center space-between',
      fontSize: 'A'
    },
    P: {
      props: {
        text: 'Symbols © 2020-2023',
        color: 'gray'
      }
    },
    Button: {
      text: 'Privacy and terms',
      color: 'grey',
      textDecoration: 'none'
    }
  }
}
