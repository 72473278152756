'use strict'

import {
  SquareButton,
  Icon,
  Flex,
  Button,
  ClickableItem,
  Link
} from '.'

export const WiderButton = {
  extend: [SquareButton],

  props: {
    theme: 'default',
    padding: 'Z Z2',
    flexAlign: 'center center',
    round: 'Z',
    Icon: {}
  }
}

export const ClickMeButton = {
  extend: [SquareButton],
  props: {
    fontSize: 'Z',
    text: 'Click Me',
    boxSize: `C E2`,
    round: 'D',
    padding: '0',
    background: 'green',
    color: 'white',
    gap: 'X'
  },
  icons: {
    extend: Icon,
    props: { icon: 'arrowDown' }
  }
}

export const CTAButton = {
  extend: [Button],
  props: {
    text: 'Read the Docs',
    round: 'D',
    padding: 'Z1 D1',
    fontWeight: '600',
    theme: 'primary',
    color: 'white',
    style: {
      whiteSpace: 'nowrap'
    }
  }
}

export const DeviceButtonSet = {
  extend: Flex,
  props: { gap: 'A' },

  childExtend: {
    extend: [ClickableItem, SquareButton],

    props: ({ state }) => {
      const isActive = state.__root.previewSize === state.key
      return {
        active: isActive,
        isActive: isActive,
        theme: null,
        background: 'transparent 0',
        color: 'gray7',
        icon: isActive ? state.icon : state.icon + ' outline',
        ':hover': {
          background: 'gray4',
          color: 'gray10'
        },
        '.active': {
          opacity: 1,
          background: 'gray4',
          color: 'gray13'
        }
      }
    },

    on: {
      click: (ev, el, s) => {
        const previewSize = s.key !== s.__root.previewSize ? s.key : 'unset'
        s.rootUpdate({ previewSize })
      }
    }
  },

  $setStateCollection: () => [{
    key: 'mobileM',
    icon: 'deviceMobile'
  }, {
    key: 'tabletM',
    icon: 'deviceTabletPortrait'
  }, {
    key: 'tabletL',
    icon: 'deviceTabletLandscape'
  }, {
    key: 'screenM',
    icon: 'deviceSmallScreen'
  }, {
    key: 'screenL',
    icon: 'deviceBigScreen'
  }]
}

export const ButtonTitle = {
  extend: Button,
  props: {
    fontSize: 'A',
    padding: '0',
    background: 'transparent',
    color: 'gray7',
    gap: 'Z',
    width: 'fit-content',
    ':hover > div': { background: '#252527', color: 'white' }
  },

  IconButton: {
    padding: 'X2',
    round: 'Z'
  },

  Caption: {
    fontSize: 'Z'
  }
}

export const ContinueButton = {
  extend: [Link, ClickableItem, Button],
  props: {
    text: 'Continue',
    fontSize: 'A2',
    padding: 'Z1 C2',
    theme: 'primary',
    margin: 'auto - - -Z',
    fontWeight: '500'
  }
}
