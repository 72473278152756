'use strict'

import { Flex, Icon } from 'smbls'

export const CheckboxField = {
  tag: 'label',
  attr: { for: 'toggle' },
  input: {
    attr: {
      type: 'checkbox',
      id: 'toggle',
      checked: (el, s) => el.props.checked
    },
    style: { display: 'none' },
    on: {
      change: (ev, el, s) => el.parent.props.onChange(ev, el, s)
    }
  },
  style: {
    '#toggle:checked ~ div > svg': { color: 'rgba(255, 255, 255, .85)' },
    '#toggle:checked ~ div > span': { opacity: '.85' }
  },
  fontVariable: {
    extend: Flex,
    props: { gap: 'Y' },
    style: { alignItems: 'center' },
    Icon: {
      extend: Icon,
      props: {
        name: 'checkmark',
        round: 'X',
        fontSize: 'A',
        padding: 'W'
      },
      style: {
        boxSizing: 'content-box',
        color: 'rgba(255, 255, 255, .08)',
        cursor: 'pointer',
        background: `linear-gradient(0deg,
            rgba(255,255,255,0.06) 0%,
            rgba(255,255,255,0.07) 100%
          )`
      }
    },
    span: {
      props: {
        text: 'This is variable font',
        opacity: '.5',
        cursor: 'pointer'
      }
    }
  }
}
