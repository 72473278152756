'use strict'

import { Grid, Button, Flex, SquareButton } from 'smbls'

const click = (event, element, state) => {
  var ctx = element.lookup('dropdown') || element.lookup('Dropdown')
  ctx.on.choose(event, ctx, ctx.state, element)
}

export const DropdownHeader = {
  props: {
    text: 'Title',
    position: 'sticky',
    top: '0',
    flexAlign: 'center flex-start',
    fontSize: 'Z',
    padding: 'Z1 A1',
    zIndex: '1',
    theme: 'field-static',
    backdropFilter: 'blur(3px)'
  }
}

export const Dropdown = {
  props: {
    theme: 'modal',
    round: 'Z2',

    position: 'absolute',
    left: '50%',
    transform: 'translate3d(0, -10px, 0)',
    top: '102%',
    minWidth: 'F',
    maxHeight: 'H',
    transition: 'A',
    transitionProperty: 'visibility, transform, opacity',
    opacity: '0',
    overflow: 'hidden auto',
    visibility: 'hidden',
    boxSizing: 'border-box',
    backdropFilter: 'blur(3px)',
    zIndex: 1000,

    '@dark': { boxShadow: 'black .35, 0, 10px, 50px, -10px' },
    '@light': { boxShadow: 'gray11, 0, 10px, 50px, -10px' },

    style: {
      backdropFilter: 'blur(3px)'
    },

    header: DropdownHeader.props
  },

  attr: { dropdown: true },

  tag: 'section'
}

const DropdownItem = {
  props: {
    boxShadow: 'none',
    round: '0',
    background: 'transparent 0',
    opacity: 0.75,
    transition: 'A',
    transitionProperty: 'visibility, color, background, opacity',

    '@dark': {
      color: 'gray13'
    },
    '@light': {
      background: 'gray15',
      color: 'gray4'
    },

    ':hover': {
      opacity: 1,
      style: {
        svg: { opacity: 0.15 }
      },
      '@dark': { background: 'gray6 .05' },
      '@light': { background: 'white .5' }
    },

    '.active': {
      fontWeight: '500',
      opacity: 1,
      '@dark': { background: 'gray6 .15' },
      '@light': { background: 'white' },

      '& svg': { opacity: 0.5 },

      ':hover': {
        style: {
          svg: { opacity: 0.65 }
        },
        '@dark': { background: 'gray6 .15' },
        '@light': { background: 'white' }
      }
    }
  },

  on: { click }
}

export const KeyValueColumnFields = {
  extend: [DropdownItem, Button, {
    props: ({ state }) => ({
      text: state.value || state.key || 'List Item'
    })
  }],

  props: {
    textAlign: 'start',
    align: 'center flex-start',
    padding: 'A',
    fontSize: 'Z1',

    Icon: {
      name: 'checkmark',
      opacity: '0',
      transition: 'A opacity',
      margin: '- Y1 - W1'
    },

    caption: {
      marginLeft: 'Z2',
      textTransform: 'capitalize',
      opacity: '.65',
      whiteSpace: 'nowrap'
    }
  },
  Icon: {},
  caption: {},
  // span: ({ props }) => props.caption,
  on: { click }
}

export const ListInDropdown = {
  extend: Flex,
  tag: 'nav',
  props: {
    flow: 'column',
    gap: 'W',
    align: 'stretch flex-start'
  },
  childExtend: KeyValueColumnFields
}

const GridItem = {
  extend: [DropdownItem, SquareButton],
  props: ({ key }) => ({
    padding: 'Z2',
    Icon: { name: key }
  }),
  text: null
}

export const GridInDropdown = {
  tag: 'nav',
  extend: Grid,
  props: {
    columns: 'repeat(1fr, 5)',
    autoColumns: 'auto',
    padding: 'X2',
    gap: 'Z2'
  },
  childExtend: GridItem
}

export default Dropdown
