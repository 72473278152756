'use strict'

import { getProjectThemeColors } from '@symbo.ls/platform-utils'
import { IconText, Link, Flex } from '.'

export const ThemeTemplate = {
  extend: [Link, Flex],

  props: (el, state) => {
    const documentTheme = getProjectThemeColors(state)
    return {
      flow: 'column',
      href: `/theme/${state.themeKey}`,
      fontWeight: '400',
      '@dark': { border: '1px, solid, --color-line-dark' },
      '@light': { border: '1px, solid, --color-line-light' },
      round: 'W',
      overflow: 'hidden',
      ...documentTheme,

      transition: 'C',
      transitionProperty: 'opacity, transform',
      opacity: 0.5,

      ':hover': {
        opacity: 1,
        transform: 'scale(1.015)'
      },

      title: {
        padding: 'Z1 A C2'
      }
    }
  },

  title: { text: (el, s) => s.themeKey },

  colors: {
    extend: Flex,
    if: (el, s) => s['@dark'] || s['@light'],

    childExtend: {
      extend: [IconText, Flex],
      props: {
        align: 'center flex-start',
        flex: 1
      },
      color: {
        extend: [IconText, Flex],
        props: {
          width: '100%',
          justifyContent: 'flex-start',
          padding: 'Z1 A'
        }
      }
    },

    sun: {
      if: (el, s) => s['@light'],
      props: (el, s) => ({
        ...getProjectThemeColors(s, s.parent.document, 'light'),
        color: {
          Icon: { name: 'sun' },
          ...getProjectThemeColors(s, null, 'light')
        }
      })
    },

    moon: {
      if: (el, s) => s['@dark'],
      props: (el, s) => ({
        ...getProjectThemeColors(s, s.parent.document, 'dark'),
        color: {
          Icon: { name: 'moon' },
          ...getProjectThemeColors(s, null, 'dark')
        }
      })
    }
  }
}
