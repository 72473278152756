'use strict'

import { ArticleMedium } from './Article'
import { Flex, Icon } from '.'

export const PackageItem = {
  tag: 'label',
  extend: Flex,
  props: ({ state }) => ({
    boxSize: 'fit-content',
    style: { '#toggle:checked ~ div': { outline: '1.5px solid #0079FD' } },
    input: { display: 'none' },
    cursor: 'pointer',
    content: {
      flow: 'column',
      borderStyle: 'solid',
      borderColor: 'gray3',
      borderWidth: '1.5px',
      boxSize: '100% 100%',
      padding: 'A1 C2 B B',
      minHeight: 'G',
      round: 'A',
      justifyContent: 'space-between',
      position: 'relative',
      header: {
        gap: '0',
        p: { text: state.caption },
        title: {
          text: state.title,
          fontSize: 'C1',
          fontWeight: 'bold'
        }
      },
      p: {
        text: state.p,
        fontSize: 'Z1',
        maxWidth: 'F1',
        margin: '0'
      },

      Icon: {
        icon: 'github',
        position: 'absolute',
        bottom: 'Y2',
        right: 'Y2',
        fontSize: 'A1',
        color: 'gray6'
      }
    }
  }),

  input: {
    attr: {
      type: 'checkbox',
      id: 'toggle'
    }
  },

  content: {
    extend: Flex,
    header: {
      extend: ArticleMedium,
      p: {},
      title: {}
    },
    p: {},
    Icon: { extend: Icon }
  }
}
