'use strict'

import { isArray } from '@domql/utils'
import { SEQUENCE } from '@symbo.ls/scratch'
import { findClosestNumber, findClosestNumberInFactory } from '@symbo.ls/utils'

export const BaseSizeTool = {
  props: (el, s) => {
    return {
      title: { text: 'Base size' },
      element: {
        Icon: { name: 'fontSize' },
        tool: {
          unit: 'px',
          value: s.value,
          min: 11,
          max: 96,

          onInput: (ev, el, s) => {
            s.update({ value: parseFloat(el.node.value) })
          },
          onDecrease: (ev, el, s) => {
            const value = parseFloat(s.value)
            if (value > el.props.min) {
              s.update({ value: value - 1 })
            }
          },
          onIncrease: (ev, el, s) => {
            const value = parseFloat(s.value)
            if (value < el.props.max) {
              s.update({ value: value + 1 })
            }
          }
        }
      }
    }
  }
}

export const FontSizeTool = {
  props: (el, s) => ({
    title: { text: 'Base size' },
    element: {
      Icon: { name: 'fontSize' },
      tool: {
        type: 'fontSize',
        value: s.sequence[s.currentFontSizeIndex],
        min: s.sequence[0],
        max: s.sequence[Object.keys(s.sequence).length - 1],

        onInput: (ev, el, s) => {
          const { sequence } = s
          const value = el.node.value
          const findMatch = findClosestNumber(value, s.sequence)
          const seq = isArray(sequence) ? sequence : Object.values(sequence)
          s.update({ currentFontSizeIndex: seq.indexOf(findMatch) })
        },
        onDecrease: (ev, el, s) => {
          const { currentFontSizeIndex } = s
          const val = currentFontSizeIndex - 1
          if (val < 0) return
          // el.parent.range.node.value = val
          s.update({ currentFontSizeIndex: val })
        },
        onIncrease: (ev, el, s) => {
          const { currentFontSizeIndex } = s
          const val = currentFontSizeIndex + 1
          if (val + 1 > Object.keys(s.sequence).length) return
          s.update({ currentFontSizeIndex: currentFontSizeIndex + 1 })
        }
      }
    }
  })
}

export const RatioTool = {
  props: (el, s) => {
    return {
      title: { text: 'Density' },
      element: {
        Icon: { name: 'space' },
        tool: {
          value: parseFloat(s.value),
          min: SEQUENCE['minor-second'],
          max: SEQUENCE['double-octave'],
          step: '0.001',

          onInput: (ev, el, s) => {
            const value = findClosestNumberInFactory(el.node.value, SEQUENCE)
            s.update({ value: parseFloat(value) })
          },
          onDecrease: (ev, el, s) => {
            const { value } = s
            const values = Object.values(SEQUENCE)
            const activeKey = values.indexOf(value) // keys.find(key => SEQUENCE[key] === value)
            s.update({ value: values[activeKey - 1] })
          },
          onIncrease: (ev, el, s) => {
            const { value } = s
            const values = Object.values(SEQUENCE)
            const activeKey = values.indexOf(value) // keys.find(key => SEQUENCE[key] === value)
            s.update({ value: parseFloat(values[activeKey + 1]) })
          }
        }
      }
    }
  }
}
