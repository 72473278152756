'use strict'

export const Labeled = {
  tag: 'span',
  props: {
    lineHeight: '1',
    padding: '0 W1',
    round: 'X2',
    style: { whiteSpace: 'nowrap' },
    '@dark': {
      border: 'gray6 .15, solid, 1px',
      background: 'gray3',
      color: 'gray11'
    },
    '@light': {
      border: 'gray10 .15, solid, 1px',
      background: 'white .5',
      color: 'gray4'
    }
  }
}

export const LabeledLight = {
  extend: Labeled,
  props: {
    padding: 'U V',
    '@dark': {
      color: 'gray8 .9',
      border: 'gray6 .1, solid, 1px',
      background: 'gray3 .65'
    },
    '@light': {
      border: 'gray10 .15, solid, 1px',
      background: 'white',
      color: 'gray4'
    }
  }
}

export const LabeledHighlight = {
  extend: Labeled,
  props: {
    padding: '0 X1',
    borderRadius: 'A',
    theme: 'typography',
    '@dark': null,
    '@light': null
  }
}
