'use strict'

import {
  ButtonSet,
  Flex,
  IconButton,
  ClickableItem,
  SquareButton
} from '..'

export const ToolBar = {
  extend: ButtonSet,
  props: { gap: 'Z' },
  childExtend: IconButton
}

export const ResponsiveToolBar = {
  extend: Flex,
  props: { gap: 'A' },

  DeviceButtonSet: {
    props: { flow: 'row-reverse' }
  },

  threeDots: {
    extend: [ClickableItem, SquareButton],
    props: {
      icon: 'threeDots vertical'
    }
  }
}

export const IncDecButtons = {
  extend: Flex,
  props: { gap: 'Z' },

  childExtend: IconButton,

  ...[{
    props: { icon: 'minus' }
  }, {
    props: { icon: 'plus' }
  }]
}

export const FooterBar = {
  extend: ToolBar,
  ...[
    { props: { icon: 'eyeOpen' } },
    {
      // active: el => FAVORITES[el.props.key],
      props: ({ active }) => ({
        icon: active ? 'star' : 'starOutline'
      }),
      on: {
        // click: (ev, el, s) => {
        //   const isFavorited = FAVORITES[el.props.key]
        //   if (isFavorited) delete FAVORITES[el.props.key]
        //   else FAVORITES[el.props.key] = el.props.component
        //   el.update()
        // }
      }
    },
    { props: { icon: 'copyOutline' } },
    { props: { icon: 'info' } },
    { props: { icon: 'share' } }
  ]
}
