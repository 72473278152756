'use strict'

import { isArray } from '@domql/utils'
import { Link, Flex, TooltipParent } from '.'

export const ColorShade = {
  extend: Flex,
  props: {
    position: 'absolute',
    top: '0',
    width: '15%',
    height: '100%',
    flow: 'column',
    transition: 'A defaultBezier opacity',
    opacity: '0.65',
    userSelect: 'none',
    asc: '-',
    seq: [6, 10, 16, 26, 42, 68],
    onChoose: (ev, el, s) => s.update({
      value: el.props.hex
    })
  },
  attr: { shades: true },
  $setPropsCollection: (el, s, ctx) => {
    if (!s.value) return
    const { colorStringToRgbaArray, getRgbTone, rgbToHex } = ctx.utils
    const rgb = colorStringToRgbaArray(s.value)
    const asc = el.props.asc
    return el.props.seq.map((v, k) => {
      const inRGB = getRgbTone(rgb, asc + v)
      const [r, g, b] = inRGB.split(', ')
      const inHex = rgbToHex(r, g, b)
      return {
        hex: inHex,
        background: `rgba(${inRGB}, 1)`,
        value: asc + v
      }
    })
  },
  childExtend: {
    props: {
      flex: 1,
      flexAlign: 'center center',
      fontSize: 'Y',
      span: { style: { mixBlendMode: 'contrast' } },
      ':hover': { textDecoration: 'underline' }
    },
    span: { text: el => el.parent.props.value },
    on: {
      click: (ev, el, s) => el.parent.parent.props.onChoose(ev, el, s)
    }
  }
}

export const ColorPreview = {
  props: {
    position: 'relative',
    width: 'G',
    round: 'A',
    overflow: 'hidden',
    theme: 'transparentPattern',
    ':hover [shades]': { opacity: '1' },
    value: {
      transition: 'A defaultBezier',
      transitionProperty: 'background',
      boxSize: '100%'
    }
  },

  ColorShade_left: {
    flow: 'column-reverse',
    left: '0',
    asc: '+'
  },
  value: {},
  ColorShade_right: {
    asc: '-',
    right: '0'
  }
}

export const ColorTemplate = {
  extend: [Link, Flex, TooltipParent],

  props: (el, s) => ({
    href: `/color/edit-color/${s.key}`,
    round: 'W',
    gap: 1,
    scrollToTop: false,
    opacity: 0.65,
    '@light': { opacity: 1 }
  }),

  Flex: {
    props: {
      ':hover > *': {
        opacity: 1,
        transform: 'scale(1.15)'
      },
      childProps: {
        '@dark': { border: '1px, solid, --color-line-dark' },
        '@light': { border: '1px, solid, --color-line-light' },
        transition: 'A defaultBezier',
        transitionProperty: 'opacity, transform',
        boxSize: 'B2'
      }
    },

    sun: {
      props: ({ state }) => ({
        round: 'X 0 0 X',
        background: state.value['@light'] ? state.value['@light'] : state.value || state.key,
        ':only-child': {
          borderRadius: 'X'
        }
      })
    },

    moon: {
      if: (element, state) => state.value['@dark'],
      props: ({ state }) => ({
        round: '0 X X 0',
        background: state.value[1] || state.value['@dark']
      })
    }
  },

  TooltipHidden: {
    props: (el, s) => ({
      shapeDirection: 'top',
      padding: 'X2 Z2',
      top: '117.5%',
      whiteSpace: 'nowrap',
      minWidth: '0',
      title: s.key
    }),
    P: null
  }
}

export const SemanticColorTemplate = {
  extend: ColorTemplate,

  props: (el, s) => ({
    href: `/color/edit-semantic-color/${s.key}`
  }),

  Flex: {
    props: {
      ':hover > *': {
        opacity: 1,
        border: 'none',
        transform: 'scale(1.15)'
      },
      childProps: {
        transition: 'A defaultBezier',
        transitionProperty: 'opacity, transform',
        boxSize: 'C C1'
      }
    },

    sun: {
      props: ({ state }) => {
        const refValue = state.value['@light'] || (isArray(state.value) && state.value[0]) || state.value || state.key
        const value = state.parent[refValue.slice(2)]
        return {
          round: 'X 0 0 X',
          background: value,
          ':only-child': {
            boxSize: 'C C1*2',
            borderRadius: 'X'
          }
        }
      }
    },

    moon: {
      if: (element, state) => state.value['@dark'] || (isArray(state.value) && state.value[1]),
      props: ({ state }) => {
        const refValue = state.value['@dark'] || state.value[1]
        const value = state.parent[refValue.slice(2)]
        return {
          round: '0 X X 0',
          background: value
        }
      }
    }
  },

  TooltipHidden: {
    props: (el, s) => ({
      shapeDirection: 'top',
      padding: 'X2 Z2',
      top: '117.5%',
      whiteSpace: 'nowrap',
      minWidth: '0',
      title: s.key
    }),
    P: null
  }
}
