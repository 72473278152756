'use strict'

import { ClickableButton, Link, IconCaption, Article, DocsLink } from '.'

export const ArticleLink = {
  extend: [Link, ClickableButton],

  props: {
    padding: 'Z1',
    round: 'Z2',
    flexFlow: 'column',
    flexAlign: 'flex-start flex-start',
    borderWidth: '1px',

    '@dark': {
      theme: null,
      color: 'gray11',
      border: 'gray2, solid'
    },

    '@light': {
      theme: null,
      color: 'gray11',
      border: 'gray11, solid'
    },

    caption: {
      fontSize: 'Y',
      color: 'gray7',
      fontWeight: '500',
      style: { textTransform: 'uppercase' }
    },

    title: {
      margin: '0',
      fontWeight: '500',

      '@dark': { color: 'gray11' },
      '@light': { color: 'gray3' }
    }
  },

  caption: {},
  title: {}
}

export const IconLinkWithPath = {
  extend: Article,

  props: ({ state }) => ({
    gap: 'W',
    padding: '0 0 0 A1',

    title: {
      href: state.href,
      margin: '0 0 0 -A1',
      target: '_blank',
      gap: 'X1',

      name: {
        text: state.title
      },

      Icon: {
        icon: 'github'
      }
    },

    p: { text: state.description },

    href: {
      text: state.href,
      href: state.href,
      target: '_blank',
      color: 'grassgreen .85',
      fontWeight: '400'
    }
  }),

  title: {
    extend: [IconCaption, DocsLink]
  },
  p: {},
  href: { extend: DocsLink }
}
