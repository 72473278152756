'use strict'

import { CreatingContent } from './CreatingContent'

export const GetAccess = {
  extend: ['Link', CreatingContent],

  props: {
    theme: 'access',
    position: 'relative',
    overflow: 'hidden',
    href: '/get-early-access',

    ':hover': {
      '> h1': {
        textShadow: 'gray1, 10px, 10px',
        transform: 'translate3d(-1%, -2%, 1px)'
      }
    }
  },

  H1: {
    color: 'white',
    fontWeight: '700',
    fontSize: 'K1',
    maxWidth: 'D2',
    transition: 'A defaultBezier',
    transitionProperty: 'text-shadow, transform',
    text: 'Get yearly access now'
  },

  ParagraphButton: {
    P: {
      text: 'Need a personalized invite?',
      color: 'white .7'
    },
    Button: {
      text: 'Request the demo',
      color: 'white',
      Icon: { name: 'arrowUpRight' }
    }
  },

  Icon: {
    name: 'arrowUpRight',
    position: 'absolute',
    top: '-E2+B2',
    right: '-F+A2',
    boxSize: 'I1+A',
    style: {
      color: 'transparent',
      stroke: 'rgba(255, 255, 255, .35)',
      strokeWidth: '0.035px'
    }
  }
}
