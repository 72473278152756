'use strict'

import style from './style'

import { Link } from '..'

export const Breadcrumb = {
  tag: 'nav',
  style,

  childExtend: {
    extend: [Link],
    props: {
      color: 'white 0.35',
      fontSize: 'Z'
    }
  }
}
