'use strict'

import { Icon, Paragraph, Flex } from '.'

export const Article = {
  extend: Flex,
  props: {
    flow: 'column',
    gap: 'X',
    color: 'title',
    title: {
      fontWeight: '500',
      margin: '0'
    },
    p: {
      lineHeight: `${21 / 14}em`,
      margin: '0',
      fontWeight: '400',
      maxWidth: `I_default`,
      color: 'paragraph'
    }
  },

  title: {},
  p: {}
}

export const ArticleBig = {
  extend: Article,
  props: {
    title: {
      fontSize: 'E',
      fontWeight: '700'
    }
  },

  title: { tag: 'h1' }
}

export const ArticleMedium = {
  extend: Article,
  props: {
    gap: 'X',
    title: { fontSize: 'B' }
  },

  title: { tag: 'h2' }
}

export const ArticleSmall = {
  extend: Article,
  props: {
    gap: 'X',
    title: { fontSize: 'A' }
  },
  title: { tag: 'h4' }
}

export const ArticleXS = {
  extend: Article,
  props: {
    gap: 'X',
    title: { fontSize: 'Z1' },
    p: { fontSize: 'Z1' }
  },
  title: { tag: 'h5' }
}

export const ArticleTiny = {
  extend: Article,
  props: {
    gap: '0',
    p: { fontSize: 'Z' }
  }
}

export const DesignElementArticle = {
  props: {
    flexFlow: 'column',
    gap: 'Y2',
    Icon: {
      background: 'blue .25',
      boxSize: 'C B1',
      padding: 'Z',
      flexAlign: 'center center',
      round: 'Z',
      spaces: {
        boxSize: 'A A'
      }
    }
  },

  Icon: { spaces: { extend: Icon } },
  p: {
    extend: Paragraph,
    props: {
      fontSize: 'Z',
      color: 'white .6',
      maxWidth: 'F1_default',
      lineHeight: `${20 / 14}em`,
      margin: '0',
      padding: '0 0 0 V'
    },
    ...[
      'This property belongs to ',
      { props: { color: 'white .85' }, text: 'shape' },
      'component'
    ]
  }
}
