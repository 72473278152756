'use strict'

export const DIRECTIONS = [
  { value: 'ltr', caption: 'left to right' },
  { value: 'rtl', caption: 'right to left' }
]

export const SHAPES = [
  { value: 'round' },
  { value: 'rectangle' }
]

export const SPACINGS = [
  { value: 1.067, caption: 'minor second' },
  { value: 1.125, caption: 'major second' },
  { value: 1.200, caption: 'minor third' },
  { value: 1.250, caption: 'major third' },
  { value: 1.333, caption: 'perfect fourth' },
  { value: 1.414, caption: 'augmented fourth' },
  { value: 1.5, caption: 'perfect fifth' },
  { value: 1.618, caption: 'golden ratio' },
  { value: 1.66, caption: 'major sixth' }
]
