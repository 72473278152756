'use strict'

import { Svg, Paragraph } from '.'

const props = {
  color: 'white .8',
  flexAlign: 'center flex-start',
  gap: 'A',
  image: {
    boxSize: 'B1_default C2_default',
    color: 'gray6',
    style: { fill: 'currentColor' }
  }
}

export const ImageParagraph = {
  props,

  image: { extend: Svg },
  p: {
    extend: Paragraph,
    props: {
      maxWidth: 'E2_default',
      padding: 'Z 0 0 0'
    }
  }
}
