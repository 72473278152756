'use strict'

import { Pseudo } from '.'

const TypeBox = {
  tag: 'span',
  props: {
    text: 'value',
    padding: 'X Y1',
    round: 'Y',
    lineHeight: '1.2'
  }
}

const props = {
  flexAlign: 'center flex-start',
  position: 'relative',
  gap: 'Y',
  ':before': {
    content: '\'"\'',
    display: 'block',
    color: 'gray7',
    position: 'absolute',
    left: '-7px'
  },
  ':after': {
    content: '\'"\'',
    display: 'block',
    color: 'gray7',
    position: 'absolute',
    right: '-7px'
  }
}

export const StringBox = {
  extend: Pseudo,
  props,
  childExtend: TypeBox
}

export const IntBox = {
  props: {
    flexAlign: 'center flex-start',
    position: 'relative',
    gap: 'Y'
  },
  childExtend: TypeBox
}

export const BooleanBox = {
  extend: IntBox
}
