'use strict'

import { Flex } from '.'

const state = {
  numberValue: '20',
  letterValue: 'B1'
}

export const SizeUnits = {
  extend: Flex,
  state,
  props: ({ state }) => ({
    flow: 'column',
    align: 'center flex-start',
    gap: 'V',
    numberValue: {
      text: (el, s) => s.numberValue,
      outline: 'none',
      fontSize: 'Z1'
    },

    letterValue: {
      text: (el, s) => s.letterValue,
      fontSize: 'Y',
      fontWeight: '500',
      color: 'white'
    }
  }),

  numberValue: { attr: { contentEditable: true } },
  letterValue: {},

  on: {
    init: (el, s) => {
      //  if(s.letterValue === "") {
      //   s.update({letterValue: '-'})
      //  }

      if (s.numberValue === '') {
        s.update({ numberValue: 'none' })
      }
    }
  }
}
