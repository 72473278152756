'use strict'

import {
  CommonFieldLike,
  Flex,
  Input
} from '.'

import {
  ColorSelect
} from './Selects'

export const ColorPicker = {
  extend: ColorSelect,
  props: {
    position: 'relative',
    padding: 'Z',
    title: {
      box: {
        boxSize: 'B B',
        position: 'relative',
        zIndex: 2,
        background: 'white .05',
        border: '0',
        padding: '0',
        style: {
          appearance: 'none'
        },
        '::-webkit-color-swatch-wrapper': {
          padding: '0'
        },
        '::-webkit-color-swatch': {
          border: 'none',
          round: 'Y'
        }
      },
      value: {
        theme: 'field',
        round: 'Z',
        position: 'absolute',
        inset: '0 0 0 0',
        padding: '- - - B2+W1',
        textAlign: 'start',
        lineHeight: '100%',
        placeholder: 'Color value',
        ':focus': {
          outline: 'solid, X, blue .3'
        }
      }
    }
  },
  title: {
    extend: Flex,
    box: {
      extend: 'Focusable',
      tag: 'input',
      attr: {
        type: 'color',
        value: ({ state }) => state.value
      },
      on: {
        update: (el, s) => {
          el.node.value = s.value || ''
        },
        input: (ev, el, s) => {
          s.update({ value: el.node.value || '' })
        }
      }
    },
    value: {
      extend: Input,
      attr: {
        value: ({ state }) => state.value,
        placeholder: ({ props }) => props.placeholder
      },
      on: {
        update: (el, s) => {
          el.node.value = s.value || ''
        },
        input: (ev, el, s) => {
          s.update({ value: el.node.value || '' })
        }
      }
    }
  },
  buttons: null
}

export const ColorPickerWithTitle = {
  extend: CommonFieldLike,
  props: {
    width: '100%'
  },
  title: {},
  element: {
    extend: ColorPicker
  }
}
