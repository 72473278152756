
import { Flex } from '@symbo.ls/ui'
import { transformDOMQLEmotion } from '@domql/emotion'
import { createEmotion, create, activateConfig } from 'smbls'
import { deepDestringify, deepClone } from '@domql/utils'
import DEFAULT_CONFIG from '@symbo.ls/default-config'

export const renderStateComponent = async (code, el, s, options = {}) => {
  if (!code || el.data.frameelem) return
  const {
    useLibrary,
    frameProps = {
      align: 'center center',
      position: 'absolute',
      inset: '0 0 0 0',
      width: '100%',
      padding: '0',
      height: '100%'
    }
  } = options

  const rootState = s.__root
  const frameDocument = el.node.contentDocument
  if (!frameDocument) return

  const emotion = createEmotion('demo', frameDocument.head)
  const emotionDefine = transformDOMQLEmotion(emotion)

  const clonedCode = deepClone(code)
  let stringifiedCode
  try {
    stringifiedCode = deepDestringify(clonedCode)
  } catch (e) {
    stringifiedCode = deepClone(clonedCode)
  }

  const globalTheme = rootState.sceneTheme || rootState.DATA.designSystem.globalTheme

  const props = s.props || {}
  try {
    el.data.frameelem = await create({
      extend: Flex,
      props: {
        base: s.base,
        fontSize: s.base + 'px',
        spacingRatio: s.ratio,
        direction: s.direction,
        ...frameProps,
        ...props,
        style: { '&, & *': { transition: 'all 255ms cubic-bezier(.29,.67,.51,.97)' } }
      },
      comp: {
        extend: [{ state: s.state }, stringifiedCode],
        // ...stringifiedCode,
        props: {
          inheritSpacingRatio: true,
          fontSize: props.base && (props.base + 'px'),
          spacingRatio: props.ratio && props.ratio,
          variant: s.variant,
          transition: 'all, B, defaultBezier'
        }
      },
      on: {
        init: (changes, el, s) => {
          activateConfig('1')
        },
        initUpdate: (changes, el, s) => {
          activateConfig('1')
        },
        update: (el, s) => {
          activateConfig('0')
        },
        render: (el, s) => {
          activateConfig('0')
        }
      }
    }, {
      key: 'demo',
      parent: frameDocument,
      state: { ...deepClone(rootState.DATA.state), globalTheme },
      designSystem: { ...(useLibrary ? deepClone(rootState.DATA.designSystem) : deepClone(DEFAULT_CONFIG)), globalTheme },
      components: useLibrary && deepClone(rootState.DATA.components),
      pages: useLibrary && deepClone(rootState.DATA.pages),
      snippets: { ...rootState.DATA.pages, router: () => {} },
      registry: emotionDefine,
      initOptions: {
        useDocumentTheme: useLibrary,
        newConfig: {},
        emotion
      },
      domqlOptions: {
        alowRefReference: true
      }
    })
  } catch (e) {
    console.warn(e)
  }

  // console.log(el.data.frameelem)

  activateConfig('0')

  return el.data.frameelem
}

export const renderPreview = async (code, el, s, options = {}) => {
}
