'use strict'

import { IconText } from 'smbls'

export const CheckParagraph = {
  extend: IconText,
  props: ({ state }) => ({
    text: state.text,
    gap: 'Y',
    color: 'gray9',
    width: 'fit-content',
    Icon: {
      name: 'checkmark',
      color: 'gray7'
    }
  })
}
