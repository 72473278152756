'use strict'

import { Flex, IconText, Link, SquareButton } from '@symbo.ls/ui'

export const ResponsiveOverlay = {
  extend: Flex,

  if: ({ state }) => state.userToken && process.env.NODE_ENV === 'production',

  props: {
    display: 'none',
    align: 'center space-between',
    flow: 'column',
    position: 'fixed',
    inset: '0 0 0 0',
    boxSize: '100%',
    padding: 'C',

    gap: 'A',
    '@tabletL': { display: 'flex' },

    '@dark': { background: 'black .9' },
    '@light': { background: 'gray15 .95' },
    style: { backdropFilter: 'blur(3px)', zIndex: '999999' }
  },

  logo: {
    extend: [Link, SquareButton],
    props: {
      theme: 'quaternary',
      color: 'white',
      icon: 'logo',
      href: 'https://symbols.app'
    }
  },

  disable: {
    extend: IconText,
    props: {
      icon: 'deviceTabletLandscapeHalf fill',
      lineHeight: '1.4',
      gap: 'Z',
      text: 'Small view is temporarily disabled, please check from larger screens',

      '@mobileM': {
        flow: 'column',
        textAlign: 'center'
      }
    }
  },

  openanyways: {
    extend: 'Button',
    props: {
      theme: 'quaternary',
      round: 'B',
      padding: 'Z2 A2'
    },
    text: 'Open anyway',
    on: {
      click: (ev, el) => el.parent.remove()
    }
  }
}
