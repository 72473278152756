'use strict'

import { setCookie } from '@domql/cookie'
import { NavbarButtonSet } from './NavbarButtonSet'
import { Flex, Link, DeviceButtonSet } from '..'
import { getActiveRoute } from '@symbo.ls/router'

export const Navbar = {
  extend: Flex,
  tag: 'aside',

  props: {
    position: 'sticky',
    top: '0',
    flow: 'row',
    zIndex: 9999,
    theme: 'header',
    align: 'center space-between',
    // animation: 'fadeInUp',
    // animationDuration: 'C',
    padding: 'Z A',
    backdropFilter: 'blur(15px)'
  },

  NavbarTooltip: {}
}

export const NavbarOnHover = {
  extend: Navbar,

  props: {
    animation: null,
    width: 'auto',
    minWidth: '100%',
    margin: '-C+A -Z 0',
    opacity: '0',
    transform: 'translate3d(0, -65%, 0)',
    transition: 'B defaultBezier',
    transitionProperty: 'opacity, visibility, transform, background, color',
    ':hover': {
      transform: 'translate3d(0, 0, 0)',
      opacity: '1',
      visibility: 'visible'
    }
  }
}

export const NavbarTheming = {
  extend: NavbarButtonSet,
  props: {
    margin: '- C1 - auto'
  },

  childExtend: {
    props: ({ state, key }) => ({
      isActive: state.__root.sceneTheme === key,

      theme: 'sepia',
      background: 'transparent 0',
      opacity: '0.5',

      '.isActive': {
        opacity: '1',
        theme: 'sepia .active',
        ':hover': { opacity: '1' },
        '@mobileL': { display: 'none' }
      }
    }),
    on: {
      click: (ev, el) => {
        const { state, key } = el
        state.rootUpdate({ globalTheme: key, sceneTheme: key }, { forcePreviewUpdate: true })
        setCookie('sceneTheme', key)
        setCookie('globalTheme', key)
      }
    }
  },

  light: {
    props: ({ state, key }) => ({
      icon: state.__root.sceneTheme === key ? 'sun' : 'sun outline'
    })
  },

  dark: {
    props: ({ state, key }) => ({
      icon: state.__root.sceneTheme === key ? 'moon' : 'moon outline'
    })
  }
}

export const NavbarResponsive = {
  extend: [NavbarButtonSet, DeviceButtonSet],
  props: { flow: 'row-reverse' },
  childExtend: {
    extend: [
      NavbarButtonSet.childExtend,
      DeviceButtonSet.childExtend
    ],
    props: ({ state }) => ({
      active: state.__root.previewSize === state.key,
      '.active': {
        opacity: 1,
        background: 'gray4',
        color: 'gray13'
      }
    })
  }
}

export const NavbarComponents = {
  extend: NavbarButtonSet,

  childExtend: {
    extend: [Link],
    props: ({ state }) => ({
      active: state.active || window.location.pathname === state.href,
      icon: state.icon,
      href: state.href || '/components'
    })
  },

  layout: {
    state: {
      active: getActiveRoute(0) === '/layout' || getActiveRoute(0) === '/layout',
      icon: 'layout outline',
      href: '/layout'
    }
  },

  components: {
    state: {
      active: getActiveRoute(0) === '/components' || getActiveRoute(0) === '/component',
      icon: 'grid',
      href: '/components'
    }
  },

  plugins: {
    state: {
      active: getActiveRoute(0) === '/plugins' || getActiveRoute(0) === '/plugins',
      icon: 'fuse outline',
      href: '/plugins'
    }
  }
}

export const NavbarCodePreview = {
  extend: NavbarButtonSet,
  props: {
    margin: '- C1 - -'
  },

  cod: {
    props: ({ state, key }) => ({
      icon: state.__root.codeSidebarToggle ? 'object' : 'object outline'
    }),
    on: {
      click: (ev, { state, key }) => {
        const { codeSidebarToggle, studioSidebarToggle } = state.__root
        state.rootUpdate({
          studioSidebarToggle: studioSidebarToggle && !codeSidebarToggle ? false : undefined,
          codeSidebarToggle: !codeSidebarToggle
        })
        setCookie('codeSidebarToggle', state.__root.codeSidebarToggle)
      }
    }
  }
}

export const NavbarLanguageDir = {
  extend: NavbarButtonSet,
  ...[{
    props: () => ({
      title: 'Languages',
      icon: 'languageColored'
    })
  }, {
    props: () => ({
      title: 'Direction',
      icon: 'languageDirection'
    }),
    on: {
      click: (ev, el, s) => {
        s.update({
          props: {
            direction: s.props?.direction === 'rtl' ? 'ltr' : 'rtl'
          }
        }, {
          forcePreviewUpdate: true
        })
      }
    }
  }]
}

export const NavbarZoom = {
  extend: NavbarButtonSet,
  props: {
    margin: '0 auto'
  },
  ...[{
    props: (el, s) => ({
      active: s.zoomLevel < 1,
      title: 'Zoom out',
      icon: 'zoom out'
    }),
    on: {
      click: (ev, el, s) => {
        if (s.zoomLevel <= 0.5) return
        s.update({
          zoomLevel: s.zoomLevel - 0.1
        })
      }
    }
  }, {
    props: () => ({
      title: 'Reset Zoom',
      icon: 'zoom reset'
    }),
    on: {
      click: (ev, el, s) => {
        s.update({
          zoomLevel: 1
        })
      }
    }
  }, {
    props: (el, s) => ({
      active: s.zoomLevel > 1,
      title: 'Zoom in',
      icon: 'zoom in'
    }),
    on: {
      click: (ev, el, s) => {
        if (s.zoomLevel >= 3) return
        s.update({
          zoomLevel: s.zoomLevel + 0.1
        })
      }
    }
  }]
}

export const NavbarStudioPreview = {
  extend: NavbarButtonSet,
  props: {
    margin: '- 0 - auto'
  },

  play: {
    props: ({ state, key }) => ({
      icon: state.__root.studioSidebarToggle ? 'play' : 'play outline'
    }),
    on: {
      click: (ev, { state, key }) => {
        state.rootUpdate({ studioSidebarToggle: !state.__root.studioSidebarToggle })
        setCookie('studioSidebarToggle', state.__root.studioSidebarToggle)
      }
    }
  }
}
