'use strict'

import { setCookie } from '@domql/cookie'
import { Navbar } from './Navbar'

export const DefaultComponentPreviewNavbar = {
  extend: Navbar,

  NavbarBackButton: {
    margin: '- B2 - -'
  },

  NavbarButtonSet_layers: {
    props: {
      margin: '- B2 - -'
    },
    ...[{
      state: {
        title: 'Layers',
        key: 'layers',
        icon: 'layers'
      },
      props: ({ state }) => ({
        active: state.__root.showComponentLayers,
        title: state.title,
        icon: state.__root.showComponentLayers ? state.icon : `${state.icon} outline`
      }),
      on: {
        click: (ev, { state, props }) => {
          const val = !state.__root.showComponentLayers
          state.rootUpdate({ showComponentLayers: val })
          setCookie('showComponentLayers', val)
        }
      }
    }]
  },

  NavbarButtonSet_layerTools: {
    props: { margin: '- auto - -' },
    childExtend: {
      props: ({ state }) => ({
        active: state.__root.componentLayerTools === state.key,
        title: state.title,
        icon: state.__root.componentLayerTools === state.key ? (state.activeIcon || state.icon) : `${state.icon} outline`
      }),
      on: {
        click: (ev, { state, props }) => {
          const val = state.__root.componentLayerTools === state.key ? null : state.key
          state.rootUpdate({ componentLayerTools: val, showComponentMetaInfo: false })
          setCookie('componentLayerTools', val)
          setCookie('showComponentMetaInfo', false)
        }
      }
    },
    ...[{
      state: {
        title: 'Properties',
        key: 'properties',
        icon: 'properties'
      }
    }, {
      state: {
        title: 'Code',
        key: 'code',
        icon: 'object'
      }
    }]
  },

  NavbarZoom: {},

  NavbarTheming: {
    props: { margin: '- auto' }
  },

  NavbarResponsive: {
    props: { margin: '- auto' }
  },

  NavbarLanguageDir: {
    props: { margin: '- auto' },
    0: null
  },

  NavbarButtonSet_meta: {
    props: { margin: '- - - auto' },
    childExtend: {
      props: ({ state }) => ({
        active: state.__root.showComponentMetaInfo === state.key,
        title: state.title,
        icon: state.__root.showComponentMetaInfo === state.key ? (state.activeIcon || state.icon) : `${state.icon} outline`
      }),
      on: {
        click: (ev, { state, props }) => {
          const val = state.__root.showComponentMetaInfo === state.key ? null : state.key
          state.rootUpdate({ showComponentMetaInfo: val, componentLayerTools: false })
          setCookie('showComponentMetaInfo', val)
          setCookie('componentLayerTools', false)
        }
      }
    },
    ...[{
      state: {
        title: 'Comments',
        key: 'comments',
        icon: 'comments'
      }
    }, {
      state: {
        title: 'Info',
        key: 'info',
        icon: 'info'
      }
    }]
  }
}

export const ComponentPreviewNavbar = {
  // extend: [NavbarOnHover, DefaultComponentPreviewNavbar]
  extend: [DefaultComponentPreviewNavbar]
}

//

// AI navbar
// mic: {
//   props: {
//     icon: 'mic outline'
//   },
//   on: {
//     click: (ev, el, s) => {
//       const { speech } = s.parent
//       if (speech) speech.recognition.startCapture()
//     }
//   }
// },
// wave: {
//   props: {
//     icon: 'wave outline'
//   },
//   on: {
//     click: (ev, el, s) => {
//       const { speech } = s.parent
//       if (speech) speech.recognition.startCapture()
//     }
//   }
// },
