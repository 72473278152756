'use strict'

import { Input } from '..'
import { SurveyLabel } from './SurveyLabel'

export const SurveyInput = {
  extend: SurveyLabel,
  props: {
    position: 'relative',
    align: 'stretch flex-start',
    element: {
      theme: 'quaternary',
      margin: '- -Z',
      width: 'auto',
      padding: 'Z1 A2'
    }
  },
  title: {},
  element: {
    extend: Input,
    props: (el, s) => {
      const fieldKey = el.parent.key
      const key = fieldKey.includes('.') ? fieldKey.split('.')[1] : fieldKey
      return {
        placeholder: 'Please specify',
        value: s[key],
        required: true
      }
    },
    on: {
      change: (ev, el, s) => {
        const fieldKey = el.parent.key
        const key = fieldKey.includes('.') ? fieldKey.split('.')[1] : fieldKey
        s.update({ [key]: ev.target.value })
      }
    }
  }
}
