'use strict'

import { Dropdown, DropdownParent, KeyValueColumnFields, ListInDropdown } from '.'

import { setCookie } from '@domql/cookie'

const FRAMEWORKS = [
  { key: 'domql', text: 'DOMQL' },
  { key: 'react', text: 'React' }
  // { key: 'iframe', text: 'Iframe' }
]

export const FrameworkSwitcher = {
  extend: DropdownParent,

  props: {
    ':hover section': {
      transform: 'translate3d(0, 0, 0)',
      visibility: 'visible',
      opacity: '1'
    },
    tooltip: {
      shape: 'tooltip',
      shapeDirection: 'bottom',
      shapeDirectionColor: 'gray4',
      round: 'Z2',
      position: 'absolute',
      right: '0',
      bottom: '125%',
      transform: 'translate3d(0, 10px, 0)',
      transition: 'A',
      transitionProperty: 'visibility, transform, opacity',
      opacity: '0',
      visibility: 'hidden',
      fontFamily: 'smbls',

      '@dark': { background: 'gray3' },
      '@light': { background: 'white' },

      ':before': {
        left: '87.5%'
        // left: '0',
        // top: 'A2'
      }
    }
  },

  tooltip: {
    tag: 'section',
    dropdown: {
      extend: Dropdown,
      props: {
        position: 'relative',
        transform: 'translate3d(0, 0, 0)',
        opacity: '1',
        left: '0',
        top: '0',
        theme: null,
        visibility: 'auto',
        header: { text: 'Choose Framework' }
      },

      header: {},
      list: {
        extend: ListInDropdown,

        childExtend: {
          extend: KeyValueColumnFields,
          key: ({ state }) => state.key,
          props: {
            Icon: {
              // active: ({ state }) => state.__root.framework === state.key
            }
          },
          Icon: {},
          text: ({ state }) => state.text
        },

        $setStateCollection: () => FRAMEWORKS
      },
      on: {
        choose: (ev, el, state, ctx) => {
          const globalState = state.__root
          setCookie('framework', ctx.state.key, 31)
          globalState.update({ framework: ctx.state.key })
        }
      }
    }
  }
}
