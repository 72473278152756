'use strict'

export const TYPOGRAPHY = {
  subSequence: true,
  base: 17,
  ratio: 1.2,

  '@screenM': {
    base: 16
  },
  '@tabletS': {
    base: 15
  }
}
