'use strict'

import { IconText } from 'smbls'
import { CommonFieldLike } from '../Fields'

export const AccessibilityCheck = {
  extend: CommonFieldLike,
  props: {
    flexFlow: 'column',
    flexAlign: 'flex-start flex-start',
    gap: 'A'
  },
  title: {
    text: 'WCAG accessibility checks'
  },
  element: {
    props: {
      flexAlign: 'center flex-start',
      gap: 'B'
    },
    childExtend: {
      extend: IconText,
      props: {
        gap: 'Y',
        Icon: {
          icon: 'checkmark',
          color: 'grassgreen'
        }
      }
    },
    ...[
      { props: { text: 'Normal AAA' } },
      { props: { text: 'Large AAA' } },
      { props: { text: 'Large AA' } },
      { props: { text: 'UI Components' } },
      { props: { text: 'Normal AA' } }
    ]
  }
}
