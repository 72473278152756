'use strict'

import typography from './typography.svg'
import typographyOutline from './typography-outline.svg'

import textAlignCenter from './text-align-center.svg'
import textAlignJustify from './text-align-justify.svg'
import textAlignLeft from './text-align-left.svg'
import textAlignRight from './text-align-right.svg'
import textCaseCapital from './text-case-capital.svg'
import textCaseLower from './text-case-lower.svg'
import textCaseTitle from './text-case-title.svg'
import textLetterSpacing from './text-letter-spacing.svg'
import textLetterWidth from './text-letter-width.svg'
import textLineHeight from './text-line-height.svg'

import fontVariableColored from './font-variable-colored.svg'
import fontVariable from './font-variable.svg'

import fontOpacityAlt from './font-opacity-alt.svg'
import fontOpacity from './font-opacity.svg'
import fontSizeMinus from './font-size-minus.svg'
import fontSizePlus from './font-size-plus.svg'
import fontSize from './font-size.svg'
import fontStyleItalic from './font-style-italic.svg'
import fontStyleUnderlineColored from './font-style-underline-colored.svg'
import fontStyleUnderline from './font-style-underline.svg'
import fontWeightColored from './font-weight-colored.svg'
import fontWeight from './font-weight-outline.svg'
import fontFace from './font-face.svg'
import fontFaceColored from './font-face-colored.svg'

import languageColored from './language-colored.svg'
import languageDirectionAlt from './language-direction-alt.svg'
import languageDirection from './language-direction.svg'
import language from './language.svg'
import languageAlt from './language-alt.svg'

export {
  typography,
  typographyOutline,

  textAlignCenter,
  textAlignJustify,
  textAlignLeft,
  textAlignRight,
  textCaseCapital,
  textCaseLower,
  textCaseTitle,
  textLetterSpacing,
  textLetterWidth,
  textLineHeight,

  fontFace,
  fontFaceColored,
  fontVariableColored,
  fontVariable,
  fontOpacityAlt,
  fontOpacity,
  fontSizeMinus,
  fontSizePlus,
  fontSize,
  fontStyleItalic,
  fontStyleUnderlineColored,
  fontStyleUnderline,
  fontWeightColored,
  fontWeight,

  languageColored,
  languageDirectionAlt,
  languageDirection,
  language,
  languageAlt
}
