'use strict'

import {
  Button,
  Link,
  Flex,
  ClickableItem,
  SquareButton,
  Icon,
  WiderButton
} from '..'

import { router } from '@domql/router'
import { setCookie } from '@domql/cookie'
import { getActiveRoute } from '@symbo.ls/router'

import {
  styleHeaderOfGuest,
  styleTabHeader
} from './style'

import {
  isDesignSystemRouteActive,
  isLibraryRouteActive,
  isSnippetsRouteActive,
  isStateRouteActive
} from '@symbo.ls/platform-utils/route'

import { setHeaderTooltipPosition } from './HeaderTooltip'

export const Logo = {
  extend: [Link, SquareButton],

  props: () => ({
    icon: 'logo',
    active: window.location.pathname === '/dashboard',
    fontSize: 'C',
    href: '/',
    position: 'relative',
    color: 'gray13',
    transition: 'B defaultBezier',
    transitionProperty: 'background, color',
    background: 'transparent 0',
    '@dark': { color: 'white' },
    '@light': { color: 'black' },
    margin: 'W',
    padding: 'Y',
    round: '100%',
    ':hover': {
      '@dark': {
        color: 'gray15',
        background: 'gray3'
      },
      '@light': {
        color: 'black',
        background: 'white'
      }
    },
    '.active': {
      '@dark': {
        color: 'gray15',
        background: 'gray3'
      },
      '@light': {
        color: 'black',
        background: 'white'
      }
    }
  }),

  Span: {
    animation: 'slideInOut',
    animationDelay: 'B',
    animationDuration: 'K',
    animationTimingFunction: 'defaultBezier',
    position: 'absolute',
    top: '50%',
    left: '85%',
    color: 'orange',
    fontSize: '.45em',
    fontWeight: '500',
    text: 'BETA',
    transform: 'translate3d(0, 50%, 0)',
    opacity: '0'
  }
}

export const Header = {
  tag: 'header',
  extend: Flex,

  props: {
    align: 'center',
    padding: 'Y2 Z2',
    width: '100%',
    zIndex: '99999',
    boxSizing: 'border-box',
    gap: 'C',
    // theme: 'header',
    style: {
      backdropFilter: 'blur(3px)'
    },

    transition: 'Å defaultBezier',
    transitionProperty: 'background, color',
    // transition: 'C',
    // transitionProperty: 'border-color, box-shadow',

    nav2: {
      gap: 'B1',
      display: 'flex',
      background: 'transparent',
      color: 'gray8',
      fontSize: 'A2'
    },

    user: {
      fontSize: 'B1'
    },

    ':not(:hover) [tooltip]': {
      opacity: '0',
      visibility: 'hidden'
    }
  },

  Logo: {},

  nav: {
    extend: Flex,
    props: {
      position: 'relative',
      gap: 'A2',
      fontSize: 'A2',
      color: 'gray8',
      flex: 1
    },

    childExtend: {
      extend: [Link, ClickableItem, SquareButton],

      props: ({ state }) => ({
        ...state,
        active: state.active || window.location.pathname.includes(state.href),
        theme: null,
        padding: 'Z',
        round: '100%',
        transitionProperty: 'opacity, transform, background, color',
        '!active': {
          ':hover': {
            '@dark': {
              color: 'gray15',
              background: 'gray2'
            },
            '@light': {
              color: 'black',
              background: 'white'
            }
          }
        }
      }),

      on: {
        update: (el, s) => {
          const active = window.location.pathname.includes(s.href)
          if (active) setHeaderTooltipPosition(el, s)
        },
        mouseover: (ev, el, s) => {
          if (!s.hover) {
            s.hover = true
            setHeaderTooltipPosition(el, s, { visible: true })
          }
        },
        mouseout: (ev, el, s) => {
          if (s.hover) {
            s.hover = false
            const Tooltip = el.lookup('HeaderTooltip')
            Tooltip.setProps({
              opacity: '0',
              visibility: 'hidden'
            })
          }
        }
      }
    }
  },

  HeaderTooltip: {},

  nav2: {
    tag: 'nav',
    extend: Flex,
    childExtend: [Link, SquareButton]
  },

  user: {
    extend: Link,
    props: ({ state }) => ({
      href: '/account/' + (state.appKey ? state.appKey.split('.')[0] : ''),
      Avatar: {
        animation: (state.icon && state.icon.pending) ? 'scaleOut' : 'scaleIn',
        display: 'block',
        src: state.icon && state.icon.src,
        key: state.projectName || state.usersName || state.name,
        borderRadius: 'Y',
        boxSize: null,
        maxWidth: 'B',
        maxHeight: 'B',
        transition: 'C defaultBezier opacity',
        opacity: (state.icon && state.icon.src) ? '1' : '.25'
      }
    }),
    Avatar: {}
  }
}

export const HeaderInitial = {
  extend: Header,

  props: {
    justifyContent: 'space-between'
  },

  Logo: {},
  nav: null,
  nav2: null,
  user: {}
}

export const HeaderOfPromo = {
  extend: Header,

  props: {
    style: {
      'a, button': {
        cursor: 'pointer'
      }
    }
  },

  class: {
    styleHeaderOfGuest,
    floating: el => {
      const { isFloating } = el.props
      const borderBottomColor = isFloating ? 'transparent' : 'white'
      const boxShadow = isFloating ? '0 5px 35px 5px black' : 'none'
      return { borderBottomColor, boxShadow }
    }
  },

  Logo: {},

  nav: {
    childExtend: {
      props: ({ props }, s) => ({
        active: window.location.pathname === props.href,
        padding: 'Z',
        fontWeight: 400,
        textDecoration: 'none',

        '@dark': { color: 'gray10' },
        '@light': { color: 'gray4' },

        '.active': {
          fontWeight: 600,
          '@dark': { color: 'white' },
          '@light': { color: 'black' }
        }
      })
    }
  },

  nav2: {
    invert: {
      props: {
        target: '_blank',
        href: 'https://github.com/symbo-ls/',
        icon: 'github'
      }
    },

    gitHub: {
      props: {
        target: '_blank',
        href: 'https://twitter.com/symbo_ls',
        icon: 'twitter'
      }
    },

    medium: {
      props: {
        target: '_blank',
        href: 'https://symbols.blog',
        icon: 'medium'
      }
    }
  },

  user: null
}

export const HeaderSignup = {
  extend: Header,

  props: {
    width: '100%',
    maxWidth: '100%',
    '@mobileL': {
      flexAlign: 'center space-between',
      padding: 'Z A Z A'
    },
    '@mobileS': {
      flexAlign: 'center space-between',
      padding: 'Z'
    },
    links: {
      gap: 'B1',
      color: 'gray8',
      margin: '0 0 0 auto'
    }
  },

  Logo: {},

  links: {
    extend: Flex,
    childExtend: {
      extend: Link,
      props: {
        fontWeight: '500',
        color: 'gray8',
        Icon: {
          color: 'gray6',
          display: 'none',
          '@mobileL': { display: 'block' }
        },
        span: { '@mobileL': { display: 'none' } }
      },
      Icon: { extend: Icon },
      span: {}
    },
    ...[{
      props: {
        href: 'mailto:hello@symbo.ls',
        text: 'Support'
      }
    }]
  },

  nav: null,
  nav2: null,
  user: null
}

export const HeaderOfGuest = {
  extend: Header,

  props: {
    maxWidth: '100%',
    width: '100%',

    '@mobileS': {
      align: 'center space-between',
      padding: 'Z'
    },

    style: {
      'a, button': {
        cursor: 'pointer'
      }
    }
  },

  Logo: {
    props: () => ({
      href: window.location.pathname === '/developers' ? '/' : '/developers',
      '@tabletS': { margin: '0 A 0 0' },
      '@mobileL': { margin: '0' },
      '@mobileXS': {
        fontSize: 'B'
      }
    })
  },

  links: {
    extend: Flex,
    props: {
      flex: 1,
      gap: 'C1',
      color: 'gray8',
      '@mobileL': {
        flex: '0 1 auto',
        margin: '0 0 0 auto',
        gap: 'A1'
      },
      '@mobileS': {
        gap: 'Z'
      },
      '@mobileXS': {
        gap: '0'
      }
    },
    childExtend: {
      extend: Link,
      props: ({ props }) => ({
        fontWeight: '500',
        ':hover': {
          color: 'gray10'
        },
        '@mobileL': {
          fontSize: 'A2',
          padding: 'A'
        },

        isActive: getActiveRoute() === getActiveRoute(0, props.href),
        '.isActive': {
          color: 'gray11'
        },
        '!isActive': {
          color: 'gray8'
        }
      }),
      Icon: {
        color: 'gray8',
        display: 'none',
        '@mobileL': { display: 'block' }
      },
      Span: {
        '@mobileL': { display: 'none' }
      }
    },
    ...[{
      props: {
        href: '/developers',
        Span: { text: 'Developers' },
        Icon: { name: 'document outline' }
      }
    }, {
      props: {
        href: '/components',
        Span: { text: 'Components' },
        Icon: { name: 'grid' }
      }
    }, {
      props: {
        href: 'https://symbols.blog',
        target: '_blank',
        Span: { text: 'Blog' },
        Icon: { name: 'grid' }
      }
    }]
  },

  nav: null,
  nav2: null,
  user: null,

  displayModes: {
    tag: 'nav',
    props: {
      margin: '0 0 0 auto',
      display: 'flex',
      fontSize: 'A2',
      gap: 'A',
      '@mobileL': { margin: '0' }
    },

    childExtend: {
      props: { gap: 'X1' },
      childExtend: {
        extend: WiderButton,
        props: {
          opacity: '0.5',
          transition: '150ms',
          transitionProperty: 'opacity, color, background',
          background: 'transparent 0'
        }
      }
    },

    zoom: {
      extend: Flex,
      props: {
        '@tabletL': {},
        '@mobileL': { display: 'none' }
      },
      childExtend: {
        props: {
          theme: null,
          '@dark': {
            color: 'gray7',
            ':hover': { color: 'gray10' },
            ':active': { color: 'gray15', background: 'gray7 .35' }
          },
          '@light': {
            color: 'gray8',
            ':hover': { color: 'gray4' },
            ':active': { color: 'gray2', background: 'gray7 .35' }
          }
        }
      },
      zoomOut: {
        props: { icon: 'zoomOut' }
      },
      zoomReset: {
        props: { icon: 'zoomReset' }
      },
      zoomIn: {
        props: { icon: 'zoomIn' }
      }
    },

    themes: {
      extend: Flex,
      childExtend: {
        props: ({ state, key }) => ({
          isActive: state.globalTheme === key,
          isNotActive: state.globalTheme !== key,

          theme: 'sepia',
          background: 'transparent 0',
          opacity: '0.5',

          $isActive: {
            opacity: '1',
            theme: 'sepia .active',
            '@mobileL': { display: 'none' }
          }
        }),
        on: {
          click: (ev, { state, key }) => {
            state.update({ globalTheme: key })
            setCookie('globalTheme', key, 31)
          }
        }
      },

      light: {
        props: ({ state, key }) => ({
          icon: state.globalTheme === key ? 'sun' : 'sun outline'
        })
      },

      dark: {
        props: ({ state, key }) => ({
          icon: state.globalTheme === key ? 'moon' : 'moon outline'
        })
      }
    }
  },

  ContinueButton: {
    margin: '- - - C1',
    href: '/signin',
    theme: 'primary',
    text: 'Get Started',
    '@mobileL': { display: 'none' }
  }
}

export const HeaderOfDocs = {
  extend: HeaderOfGuest,
  Logo: {},
  links: {
    ...[{
      href: '/docs/intro',
      Span: { text: 'Documentation' },
      Icon: { name: 'document outline' }
    }, {
      href: '/components',
      Span: { text: 'Components' },
      Icon: { name: 'grid' }
    }, {
      href: '/props',
      Span: { text: 'Properties' },
      Icon: { name: 'properties' }
    }, {
      color: 'gray5',
      pointerEvents: 'none',
      href: '/api',
      Span: { text: 'API' },
      Icon: {
        color: 'gray5',
        name: 'api'
      }
    }, {
      color: 'gray5',
      pointerEvents: 'none',
      href: '/plugins',
      Span: { text: 'Plugins' },
      Icon: {
        color: 'gray5',
        name: 'fuse'
      }
    }].map(props => ({ props }))
  },
  displayModes: null
}

export const HeaderOfLanding = {
  extend: Header,

  props: {
    maxWidth: '100%',
    '@mobileL': {
      flexAlign: 'center space-between',
      padding: 'Z A Z A'
    },
    '@mobileS': {
      flexAlign: 'center space-between',
      padding: 'Z'
    },

    style: {
      'a, button': {
        cursor: 'pointer'
      }
    }
  },

  Logo: {
    fontSize: 'C2',
    margin: '0 B1 0 0',
    '@tabletS': { margin: '0 A 0 0' },
    '@mobileL': { margin: '0' }
  },

  nav: null,
  nav2: null,
  user: null,
  displayModes: null,

  Span: {
    extend: Flex,
    props: {
      flex: 1,
      align: 'flex-end center',
      gap: 'X',
      '@mobileS': { display: 'none' }
    },
    Strong: { text: 'Symbols' },
    Span: { text: ' of Single Source' }
  },

  Link: {
    margin: '- - - C1',
    fontWeight: '400',
    href: '/developers',
    text: '/developers',
    ':hover': { textDecoration: 'underline' }
  }
}

const HeaderNav = {
  extend: Header.nav,
  props: {
    flow: 'column',
    flex: '0 1 auto'
  },

  childExtend: {
    on: {
      update: (el, s) => {
        const active = window.location.pathname.includes(s.href)
        if (active) setHeaderTooltipPosition(el, s)
      },
      mouseenter: (ev, el, s) => {
        if (!s.hover) {
          s.hover = true
          setHeaderTooltipPosition(el, s, {
            visible: true
          })
        }
      }
    }
  }
}

export const HeaderOfMember = {
  extend: Header,
  props: {
    flow: 'column',
    height: '100%',
    width: 'fit-content',
    padding: 'Y2',

    nav: {
      flow: 'column'
    },
    user: {
      margin: '0'
    }
  },

  Logo: {},

  nav: {
    $setStateCollection: ({ state }) => {
      const isDesignSystemActive = isDesignSystemRouteActive()
      const isStateActive = isStateRouteActive()
      const isLibraryActive = isLibraryRouteActive()

      const { DATA, SCHEMA } = state // eslint-disable-line

      const isSubscribed = state.package > 1
      const hasState = SCHEMA.state && Object.keys(SCHEMA.state).length > 0
      const hasProjects = state.projects && state.projects.length

      return hasProjects ? [{
        title: isSubscribed ? 'Preview' : 'Start',
        icon: 'play outline',
        href: isSubscribed ? '/studio' : '/init/personalize'
      }, {
        title: 'Components',
        icon: 'grid',
        href: '/library',
        active: isLibraryActive
      }, {
        title: 'Design System',
        icon: 'tree',
        href: '/design-system',
        active: isDesignSystemActive
      }, hasState && {
        title: 'State',
        icon: 'state',
        href: '/state',
        active: isStateActive
      }, isSubscribed && {
        title: 'Functions',
        icon: 'fn outline',
        href: '/snippets',
        active: isSnippetsRouteActive()
      }, {
        title: 'Connect and Integrate',
        icon: 'install',
        href: '/install'
      }].filter(x => x) : []
    }
  },

  nav2: null,

  user: {},

  preview: {
    extend: HeaderNav,
    $setStateCollection: ({ state }) => [state.package > 1 && {
      title: 'Publish',
      icon: 'uploadOutline',
      scrollToTop: false,
      href: window.location.pathname + '/publish'
    }, {
      title: 'Developers',
      icon: 'code',
      href: '/developers'
    }, {
      title: 'Feedback report',
      scrollToTop: false,
      icon: 'signal',
      href: window.location.pathname + '/feedback'
    }].filter(x => x)
  }
}
// }, {
//   title: 'Data',
//   icon: 'database outline',
//   href: '/data'
// }, {
//   title: 'Search',
//   icon: 'searchOutline',
//   href: '/search'

export const HeaderEditor = {
  extend: HeaderOfMember,
  props: () => {
    const route = window.location.pathname
    const routes = route.slice(1).split('/')
    const componentKey = routes[1]
    return {
      Logo: { href: `/export/${componentKey}` },
      '@dark': {
        theme: null,
        background: 'transparent 0'
      },
      '@light': {
        theme: null,
        background: 'transparent 0'
      },
      theme: null,
      background: 'transparent 0',
      maxWidth: '100%',
      style: { backdropFilter: 'blur(0px)' },
      nav2: { margin: '- - - auto' },
      preview: { margin: '- - - auto' }
    }
  },

  Logo: {},
  nav: null,
  middleNav: null,
  nav2: null,
  preview: null,
  user: null
}

let cacheToken
let lastRoute = window.location.pathname
let hasInitiated
let wasEditorActive = window.location.pathname.includes('/editor')
export const HeaderOnRouter = {
  props: ({ state }) => ({
    maxHeight: '100%',
    signedIn: state.userToken,
    '.signedIn': {
      width: 'fit-content',
      height: '100%'
    },
    '!signedIn': {
      width: '100%',
      height: 'fit-content'
    },
    zIndex: '99999',
    position: 'sticky',
    top: '0',
    left: '0',
    gap: 'A',

    '@mobileL': window.location.pathname === '/' ? {
      top: '0',
      bottom: 'none',
      order: '0'
    } : {
      top: 'none',
      bottom: '0',
      order: '3'
    }
  }),

  routes: {
    '/member': HeaderOfMember,
    '/*': HeaderOfDocs,
    '/init': Header,
    '/': HeaderOfLanding,
    '/guest': HeaderOfGuest,
    '/developers': HeaderOfDocs,
    '/docs': HeaderOfDocs,
    '/signup': HeaderSignup,
    '/signin': HeaderSignup,
    '/waitlist': HeaderSignup,
    '/editor': HeaderEditor
  },

  on: {
    initUpdate: (changes, element, s) => {
      const pathname = window.location.pathname
      const mainRoute = pathname === '/' ? '/' : getActiveRoute(0)
      const hasContent = element.routes[mainRoute]

      const routeHasChanged = lastRoute !== mainRoute
      const isEditorActive = window.location.pathname.includes('/editor')
      const isEditorChanged = wasEditorActive !== isEditorActive

      // const activeProject = s.activeProject !== ''

      if (s.userToken) {
        const memberRoute = isEditorActive ? '/editor' : '/member'
        if (cacheToken !== s.userToken) {
          cacheToken = s.userToken
          router(memberRoute, element, {}, { updateState: false, pushState: false })
        } else if (isEditorChanged) {
          wasEditorActive = window.location.pathname.includes('/editor')
          router(memberRoute, element, {}, { updateState: false, pushState: false })
        } else {
          element.updateContent()
        }
      } else if (routeHasChanged && hasContent) {
        router(pathname, element, {}, { updateState: false, pushState: false })
      } else if (!hasInitiated) {
        hasInitiated = true
        const route = hasContent ? pathname : '/*'
        router(route, element, {}, { updateState: false, pushState: false })
      } else {
        element.updateContent()
      }

      lastRoute = pathname
    }
  }
}

export const TabHeader = {
  style: styleTabHeader,
  props: {
    margin: 'C -B2 B'
  },

  tabs: {
    extend: [Flex],
    props: { gap: 'W' },

    childExtend: {
      extend: [ClickableItem, Button],
      props: (el, s) => ({
        active: el.key === s.activeTab ? { opacity: 1 } : null,
        round: 'Z',
        padding: 'Z1 A',
        fontSize: 'Z',
        href: `/${s.activePage}/${el.key}`
      }),
      active: (el, s) => el.key === s.activeTab ? { opacity: 1 } : null,
      on: {
        click: (ev, el, s) => {
          s.update({ activeTab: el.key })
          Link.on.click(ev, el, s)
        }
      }
    }
  }
}

export * from './HeaderTooltip'
