'use strict'

import { Flex, CommonFieldLike, ClickableItem, UploadIcon } from '.'

export const UploadAvatar = {
  extend: [CommonFieldLike],
  title: { text: 'Pick the avatar' },

  element: {
    extend: [UploadIcon, Flex, ClickableItem],
    props: {
      border: 'none',
      theme: 'quaternary',
      padding: 'Z Z2',
      gap: 'Z',
      flow: 'row'
    },
    Avatar: {
      props: {
        boxSize: null,
        maxWidth: 'C+X',
        maxHeight: 'C+X'
      }
    },
    Icon: { fontSize: '52px' },
    P: {
      Span: null,
      text: 'Upload or drop the image'
    },
    Input: {
      props: { type: 'file' },
      on: {
        change: async (ev, el, s) => {}
      }
    }
  }
}
