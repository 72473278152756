'use strict'

export const Scene = {
  props: ({ state }) => ({
    display: 'flex',
    borderRadius: 'A',
    padding: 'A',
    theme: 'scene @' + state.__root.sceneTheme,
    transition: 'B defaultBezier',
    transitionProperty: 'max-width, background',
    backdropFilter: 'blur(5px)',

    scene: {
      display: 'flex',
      width: '100%',
      height: '100%',
      padding: 'B',
      overflow: 'auto',
      flexAlign: 'center space-around',
      margin: '0 auto',
      theme: 'dots @' + state.__root.sceneTheme,

      style: {
        backgroundPosition: `top`,
        backgroundRepeat: `repeat`,
        backgroundSize: '20px 20px'
      }
    }
  }),

  scene: {}

  // on: {
  //   attachNode: el => el.node.attachShadow({ mode: 'open' })
  // }
}
