'use strict'

export const SearchField = {
  props: {
    flexAlign: 'center flex-start',
    gap: 'X',
    theme: 'quaternary',
    round: 'Y',
    padding: '0 0 0 Z1',
    cursor: 'pointer',
    width: `${205 / 16}em`,
    height: `${36 / 16}em`
  },

  Icon: {
    name: 'search outline',
    fontSize: 'Z'
  },

  Input: {
    fontSize: 'Z',
    border: 'none',
    flex: 1,
    background: 'transparent',
    color: 'white .85',
    padding: '0',
    style: { outline: 'none' }
  }
}
