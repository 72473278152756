'use strict'

import { isValidHtmlTag } from '@domql/utils'
import { FontObject } from '../FontObject'

export const TypePreview = {
  extend: FontObject,

  props: ({ state }) => ({
    round: 'Z',
    caption: {
      color: 'dim'
    },

    demo: {
      margin: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      fontWeight: '500',
      color: 'caption',

      text: state.sampleText || state.parent.sampleText || state.parent.parent.sampleText,

      style: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    },

    span: {
      display: 'flex',
      alignItems: 'center',
      gap: '2px',
      color: 'dim'
    }
  }),

  title: null,
  p: null,
  description: null,

  demo: {
    tag: ({ state }) => state.key === 'body' ? 'div' : isValidHtmlTag(state.key) ? state.key : 'h1'
  }
}
