'use strict'

export const Line = {
  props: {
    margin: 'D 0',
    height: '.2px',
    theme: 'line'
  }
}

export const VerticalLine = {
  props: {
    margin: '0 D',
    width: '.2px',
    theme: 'line'
  }
}
