import { ArrayBox } from './ArrayBox'

export const ObjectBox = {
  extend: ArrayBox,
  props: {
    lexAlign: 'center flex-start',
    position: 'relative',
    ':before': {
      content: '"{"'
    },
    ':after': {
      content: '"}"'
    }
  }
}
