'use strict'

import { getActiveRoute } from '@symbo.ls/router'

export const isDesignSystemRouteActive = () => {
  const route = getActiveRoute(0) || ''
  return [
    '/design-system', '/typography', '/color', '/icons',
    '/shape', '/font', '/timing', '/theme',
    '/media-query', '/space'
  ].includes(route)
}

export const isStateRouteActive = () => {
  const route = getActiveRoute(0) || ''
  return [
    '/state',
    '/data'
  ].includes(route)
}

export const isLibraryRouteActive = () => {
  const route = getActiveRoute(0) || ''
  return [
    '/component',
    '/components',
    '/export',
    '/preview',
    '/library'
  ].includes(route)
}

export const isSnippetsRouteActive = () => {
  const route = getActiveRoute(0) || ''
  return [
    '/playgroumd',
    '/snippets'
  ].includes(route)
}

export const isStudioRouteActive = () => {
  const route = getActiveRoute(0) || ''
  return [
    '/studio'
  ].includes(route)
}

export const isSchemaRouteActive = () => {
  const route = getActiveRoute(0) || ''
  return [
    '/schema'
  ].includes(route)
}
