'use strict'

export const TogglableSidebar = {
  props: {
    padding: 'Z',
    transition: 'A defaultBezier',
    minHeight: '100%',
    overflow: 'visible',
    transitionProperty: 'max-width, visibility, opacity, display',
    '.show': { opacity: '1', visibility: 'visible' },
    '!show': {
      padding: 'Z 0',
      opacity: '0',
      overflow: 'hidden',
      widthRange: '0',
      visibility: 'hidden',
      display: 'none',
      transitionDelay: '0, 0, 0, C'
    }
  }
}
