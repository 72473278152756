'use strict'

import { Flex } from 'smbls'

import { ArticleBig, ContinueButton, CheckParagraph } from '.'

const props = {
  flow: 'column',
  width: 'fit-content',

  header: {
    p: { text: 'The bill for the next year' },
    title: {
      fontSize: 'D2',
      text: '$1890.00'
    }
  },

  list: {
    flow: 'column',
    gap: 'Z2',
    padding: 'B2 - C2 -'
  },

  button: {
    fontSize: 'Z2',
    width: 'fit-content',
    padding: 'Z2+U1 C1',
    margin: '0'
  }
}

export const PackageBill = {
  extend: Flex,
  props,

  header: {
    extend: ArticleBig,
    p: {}
  },

  list: {
    extend: Flex,
    childExtend: {
      extend: CheckParagraph,
      props: { color: 'gray7' }
    }
  },

  button: { extend: ContinueButton }
}
