'use strict'

import { Link } from 'smbls'
import { NavbarOnHover } from './Navbar'
import { DefaultComponentPreviewNavbar } from './ComponentPreviewNavbar'
import { getActiveRoute } from '@symbo.ls/router'

export const StudioNavbar = {
  extend: NavbarOnHover,

  props: {
    margin: '-C1+Z1 -Z 0'
  },

  NavbarButtonSet: {
    if: ({ state }) => state.__root.userId === 'usb148d6dd',

    props: { margin: '- auto - -' },

    childExtend: {
      extend: [Link],
      props: ({ state }) => ({
        active: state.active || window.location.pathname === state.href,
        icon: state.icon,
        href: state.href || '/components'
      })
    },

    studio: {
      state: {
        active: getActiveRoute(0) === '/studio',
        icon: 'play outline',
        href: '/studio'
      }
    },

    schema: {
      state: {
        active: getActiveRoute(0) === '/schema',
        icon: 'fuse',
        href: '/schema'
      }
    }
  },

  NavbarButtonSet_layerTools: {
    ...DefaultComponentPreviewNavbar.NavbarButtonSet_layerTools,
    props: { margin: '- auto - -' }
  },

  NavbarTheming: {},

  NavbarLanguageDir: {
    props: { margin: '0' },
    0: null
  },

  NavbarResponsive: {},

  NavbarButtonSet_open: {
    props: { margin: '- - - C1' },

    link: {
      extend: Link,
      props: (el, s) => ({
        icon: 'arrowUpRight',
        href: `https://${s.__root.appKey}/`,
        target: '_blank'
      })
    }
  }
}
