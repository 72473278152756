'use strict'

import { Link } from '.'

export const DocsLink = {
  extend: Link,
  tag: 'a',
  props: {
    fontWeight: 500,
    color: 'caption',
    style: { whiteSpace: 'nowrap' },
    ':hover': { textDecoration: 'underline' }
  }
}
