'use strict'

import { Flex } from '.'

const props = {
  gap: 'C',
  childProps: {
    fontSize: 'Z1',
    color: 'gray7',
    maxWidth: `${272 / 14}em`
  }
}

export const ColumnParagraphs = {
  props,
  extend: Flex,

  childExtend: { tag: 'p' },
  ...[{ props: { text: 'To personalize your Symbols experience, we need to get started with themes' } },
    { props: { text: 'Please fill in the document dark and light themes' } }]

}
