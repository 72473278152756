'use strict'

import code from './code-open.svg'
import api from './api.svg'
import apiOutline from './api-outline.svg'

import cdn from './cdn.svg'
import cdnOutline from './cdn-outline.svg'

import boolean from './type-boolean-fill.svg'
import booleanOutline from './type-boolean-outline.svg'
import array from './type-array-fill.svg'
import arrayOutline from './type-array-outline.svg'
import date from './type-date-fill.svg'
import dateOutline from './type-date-outline.svg'
import int from './type-int-fill.svg'
import intOutline from './type-int-outline.svg'
import object from './type-object-fill.svg'
import objectOutline from './type-object-outline.svg'
import string from './type-string-fill.svg'
import stringOutline from './type-string-outline.svg'

import terminal from './terminal.svg'
import fork from './fork.svg'
import properties from './properties.svg'

import bug from './bug.svg'
import bugAlt from './bug-alt.svg'
import bugAlt2 from './bug-alt-2.svg'
import bugAlt3 from './bug-alt-3.svg'

import stateColored from './state-colored.svg'
import stateOutline from './state-outline.svg'
import state from './state.svg'

import version from './version-fill.svg'
import versionOutline from './version-outline.svg'

import npm from './npm-fill.svg'
import npmOutline from './npm-outline.svg'

import yarn from './yarn-fill.svg'
import yarnOutline from './yarn-outline.svg'

import pnpm from './pnpm-fill.svg'
import pnpmOutline from './pnpm-outline.svg'

export {
  code,
  api,
  apiOutline,

  cdn,
  cdnOutline,

  boolean,
  booleanOutline,
  array,
  arrayOutline,
  date,
  dateOutline,
  int,
  intOutline,
  object,
  objectOutline,
  string,
  stringOutline,

  terminal,
  fork,
  properties,

  version,
  versionOutline,

  npm,
  npmOutline,

  yarn,
  yarnOutline,

  pnpm,
  pnpmOutline,

  bug,
  bugAlt,
  bugAlt2,
  bugAlt3,

  stateColored,
  stateOutline,
  state
}
