'use strict'

import { Box, Grid, ValueElement, ObjectBox, Pseudo } from '..'

const ValueRow = {
  extend: Grid,
  props: {
    columns: '4fr 7fr',
    property: {
      flexAlign: 'center flex-start',
      gap: 'B'
    },
    description: {
      margin: '0'
    }
  },

  property: {
    caption: {
      extend: ValueElement,
      props: ({ state }) => ({
        text: state.text,
        theme: state.type || 'default'
      })
    },
    values: {
      extend: ObjectBox,
      style: {
        opacity: 0.1,
        cursor: 'not-available',
        '> span': {
          background: 'none',
          padding: '0'
        }
      }
    }
  },

  description: {
    extend: 'Paragraph',
    props: 'match',
    text: ({ state }) => state.description
  }
}

export const PropertyValues = {
  if: ({ state }) => state.properties,

  title: {
    tag: 'caption',
    props: {
      text: 'values',
      fontWeight: '500',
      padding: '0 0 Z2 0',
      style: { textTransform: 'uppercase' },

      '@dark': { color: 'gray7' },
      '@light': { color: 'gray8' }
    }
  },

  values: {
    props: { flexFlow: 'column', gap: 'Z' },
    childExtend: ValueRow,
    $setStateCollection: ({ state }) => state.properties
  }
}

export const propsData = [{
  props: {
    property: { name: { text: 'modifier', background: 'white .1' } },
    description: { text: 'Modifier options pre-defined in Shapes' }
  },

  property: {
    caption: {},
    values: {
      props: {
        style: {
          '&:before': {
            content: `'"{'`,
            left: '-12px'
          },
          '&:after': {
            content: `'}"'`,
            right: '-12px'
          },
          '> span': {
            '&:after': {
              content: `'|'`
            }
          }
        }
      },
      ...[
        { props: { text: 'position' } },
        { props: { text: 'direction' } },
        { props: { text: 'length' } }
      ]
    }
  }
}, {
  props: {
    property: { name: { text: 'position', background: 'yellow .3' } },
    description: { text: 'Additional property defining shape position' }
  },

  property: {
    caption: {},
    values: {
      extend: Pseudo,

      props: {
        flexAlign: 'flex-center flex-start',
        gap: 'A2',
        position: 'relative',

        ':before': {
          content: `'"'`,
          display: 'block',
          color: 'gray7',
          position: 'absolute',
          left: '-13px'
        },
        ':after': {
          content: `'"'`,
          display: 'block',
          color: 'gray7',
          position: 'absolute',
          right: '-13px'
        }
      },
      childExtend: {
        extend: ObjectBox,
        props: {
          padding: '0',
          style: {
            '> span': {
              background: 'transparent',
              padding: '0'
            }
          }
        }
      },
      ...[
        {
          ...[
            { props: { text: 'block' } },
            { props: { text: 'inline' } }
          ]
        },

        {
          ...[
            { props: { text: 'start' } },
            { props: { text: 'center' } },
            { props: { text: 'end' } }
          ]
        }
      ]
    }
  }
}, {
  props: {
    property: { name: { text: 'direction', background: 'yellow .25' } },
    description: { text: 'Additional property defining shape position' }
  },

  property: {
    caption: {},
    values: {
      extend: Box,
      props: {
        flexAlign: 'flex-center flex-start',
        gap: 'A2',
        position: 'relative',
        style: {
          '&:before': {
            content: `'"'`,
            display: 'block',
            color: 'gray7',
            position: 'absolute',
            left: '-13px'
          },
          '&:after': {
            content: `'"'`,
            display: 'block',
            color: 'gray7',
            position: 'absolute',
            right: '-13px'
          }

        }
      },
      childExtend: {
        extend: ObjectBox,
        props: {
          padding: '0',
          style: {
            '> span': {
              background: 'transparent',
              padding: '0'
            }
          }
        }
      },
      ...[
        {
          ...[
            { props: { text: 'north' } },
            { props: { text: 'south' } }
          ]
        },

        {
          ...[
            { props: { text: 'east' } },
            { props: { text: 'west' } }
          ]
        }
      ]
    }
  }
}, {
  props: {
    property: { name: { text: 'length', background: 'blue .25' } },
    description: { text: 'Length property of the modifier' }
  },

  property: {
    caption: {},
    values: {
      props: {
        gap: '0',
        style: {
          '> span': {
            display: 'flex',
            gap: '0px'
          },
          '> span:not(:first-child)': {
            '&:after': {
              right: '0',
              position: 'relative',
              paddingRight: '5px'
            }

          }
          // border: '2px solid yellow'
        }
      },
      ...[
        {
          props: {
            text: 'A2',
            flexAlign: 'flex-start flex-start',
            gap: '0',
            style: {
              '&:before': {
                content: `'"'`,
                display: 'block',
                color: '#818186',
                position: 'relative',
                left: '0'
              },
              '&:after': {
                content: `'"|'`,
                color: '#818186',
                position: 'relative',
                letterSpacing: '3px',
                right: '0'
              }
            }
          }
        },
        {
          props: {
            text: '%',
            style: {
              '&:before': {
                content: `'"'`,
                display: 'block',
                color: '#818186',
                position: 'relative'
              }
            }
          }
        },
        { props: { text: 'em' } },
        { props: { text: 'rem' } },
        {
          props: {
            style: {
              '&:after': {
                content: `'..."'`,
                display: 'block',
                color: '#818186',
                position: 'relative'
              }
            },
            text: 'px'
          }
        },
        {
          props: {
            text: 'Number',
            style: {
              '&:before': {
                content: `'|'`,
                display: 'block',
                color: '#818186',
                position: 'relative',
                paddingRight: '5px'
              }
            }
          }
        }
      ]
    }
  }
}]
