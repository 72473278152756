'use strict'

import { debounce } from '@domql/utils'
import { InputField } from '.'

export const TestText = {
  extend: InputField,

  props: (el, s) => ({
    minWidth: 'G2',
    align: 'stretch',
    element: {
      theme: 'quaternary',
      padding: 'Z A',
      round: 'Y'
    }
  }),

  title: { text: 'Test text:' },

  element: {
    state: 'sampleText',
    props: (el, s) => ({
      value: s.value
    }),
    on: {
      input: (ev, el, s) => {
        s.update({ value: el.node.value }, {
          execStateFunction: false
        })
        // debounce(() => , 100)
      }
    }
  }
}
