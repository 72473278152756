'use strict'

import { TooltipParent } from '.'

export const QuestionMarkTooltip = {
  extend: TooltipParent,
  props: {
    margin: 'X auto - X'
  },
  Icon: {
    color: 'gray7',
    opacity: '.35',
    name: 'questionMark fill'
  },
  TooltipHidden: {
    props: {
      padding: 'Z A',
      shapeDirection: 'bottom',
      title: null,
      minWidth: 'F1'
    }
  }
}
