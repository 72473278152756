'use strict'

export const init = (el, state) => {
  const isActive = state.title === window.location.hash.slice(1)
  if (isActive) { state.active = true }
}

export const click = (ev, el, s) => {
  if (s.active) return
  Object.values(el.parent.parse())
    .filter(v => v && v.state)
    .map(v => v.state && v.state.update({ active: !s.active && s.title === v.state.title }))
  ev.preventDefault()
}
