'use strict'

import { opacify } from '@symbo.ls/scratch'

export default {
  display: 'flex',
  alignItems: 'center',
  opacity: 1,
  margin: 0,
  gap: 0,

  a: {
    padding: 0,
    textDecoration: 'none',

    '&[href]': {
      color: opacify('white', 0.5),
      '&:hover': {
        textDecoration: 'underline'
      }
    },

    '&:not([href])': {
      cursor: 'default'
    },

    '&:not(:first-child):before': {
      content: '""',
      display: 'inline-block',
      verticalAlign: '0.2em',
      width: '2px',
      height: '2px',
      borderRadius: '100%',
      background: 'white',
      marginInline: '.65em',
      opacity: '.5'
    }
  }
}
