'use strict'

import { ArticleSmall, Flex, MenuItem, Pseudo, DocsLink, Link, IconButton } from '.'

const discussions = {
  extend: ArticleSmall,
  props: {
    maxWidth: 'G2',
    padding: '0 E1 0 0',
    title: { text: 'Discussions' },
    p: {
      margin: '0 0 A',
      text: 'Join our discussion boards at Github and Discord'
    }
  },

  title: {},
  p: {},
  socialLinks: {
    tag: 'nav',
    childExtend: MenuItem,
    props: {
      display: 'flex',
      gap: 'Z'
    },

    discord: {
      props: {
        target: '_blank',
        href: 'https://discord.com/invite/crdFSkapFY',
        icon: 'discord'
      }
    },

    github: {
      props: {
        target: '_blank',
        href: 'https://github.com/symbo-ls/',
        icon: 'github'
      }
    },

    gitHub: {
      props: {
        target: '_blank',
        href: 'https://twitter.com/symbo_ls',
        icon: 'twitter'
      }
    }
  }
}

const fetchReleases = s => {
  const username = 'symbo-ls'
  const repoName = 'smbls'
  const accessToken = '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' // Optional

  const apiUrl = `https://api.github.com/repos/${username}/${repoName}/releases`

  const headers = accessToken ? { Authorization: `token ${accessToken}` } : {}

  window.fetch(apiUrl, { headers })
    .then(response => response.json())
    .then(releases => {
      // Sort the releases by published date in descending order
      releases.sort((a, b) => new Date(b.published_at) - new Date(a.published_at))

      // Extract the last three releases
      const lastThreeReleases = releases.slice(0, 3)
      s.update(lastThreeReleases)
    })
    .catch(error => {
      console.error('Error fetching GitHub releases:', error)
    })
}

const releasedNotes = {
  extend: ArticleSmall,
  state: [],
  props: {
    // '@tabletL': { minWidth: 'G_default' },
    title: { text: 'Release Notes' },
    p: {
      display: 'flex',
      flexFlow: 'column',
      gap: 'X1_default',
      color: 'paragraph'
    }
  },
  title: {},
  p: {
    childExtend: {
      span: {
        extend: DocsLink,
        props: ({ state }) => ({
          target: '_blank',
          href: `https://github.com/symbo-ls/smbls/releases/tag/${state.name}`,
          text: state.name
        })
      },

      text: ({ state }) => ': ' + state.body.slice(0, 35) + '...'
    },

    on: {
      init: (el, s) => fetchReleases(s)
    },

    $setStateCollection: ({ state }) => state

    // [{
    //   span: '2.11.16',
    //   text: ' - stable release'
    // }]
  }
}

const support = {
  extend: ArticleSmall,
  props: {
    maxWidth: 'G2',
    margin: '- - - auto',
    title: { text: 'Support' }
  },
  title: {},
  p: {
    ...[
      'Feel free to reach out to us at ',
      { extend: DocsLink,
        props: {
          fontWeight: 400,
          text: 'Discord',
          href: 'https://discord.com/invite/crdFSkapFY'
        } },
      ', ',
      { extend: DocsLink,
        props: {
          fontWeight: 400,
          text: 'Twitter',
          href: 'https://twitter.com/symbo_ls'
        } },
      ', or ',
      { extend: DocsLink,
        props: {
          fontWeight: 400,
          text: 'hello@symbo.ls',
          href: 'mailto:hello@symbo.ls'
        } }
    ]
  }
}

const props = {
  padding: 'C 0 0',
  width: '100%',
  margin: '- auto',
  align: 'flex-start flex-start',
  gap: 'B',
  '@tabletL': {
    flow: 'column',
    gap: 'C1'
  },
  childProps: {
    '@tabletL': {
      width: '100%',
      padding: '0'
    }
  }
}

export const DocsFooter = {
  tag: 'footer',
  props: {
    position: 'relative',
    minWidth: '100%',

    sh: {
      position: 'absolute',
      width: '1px',
      height: '100%',
      top: '0',
      right: '0',
      display: 'none',

      '::after': {
        content: '""',
        position: 'sticky',
        display: 'block',
        width: 'C',
        height: '100%',
        top: '0',
        right: '0',
        margin: '- -C - -'
      },
      '@dark': {
        '::after': {
          background: 'black',
          boxShadow: 'black, 0 0 50px 50px'
        }
      },
      '@light': {
        '::after': {
          background: 'gray15',
          boxShadow: 'gray15, 0 0px 50px 50px'
        }
      }
    }
  },

  container: {
    extend: Flex,
    props,
    release: {
      extend: releasedNotes,
      props: { display: 'none' }
    },
    discussions,
    rel: {
      extend: releasedNotes,
      props: { '@mobileS': { display: 'none' } }
    },
    support
  },
  sh: { extend: Pseudo }
}

export const FooteLite = {
  tag: 'footer',

  extend: Flex,

  props: {
    ...props,

    align: 'center space-between',
    padding: 'B 0 0',

    copy: {
      display: 'flex',
      gap: 'X2',
      lineHeight: '1',

      '@dark': { color: 'gray8' },
      '@light': { color: 'gray5' },

      version: {
        margin: '- - - A2',
        '@dark': { color: 'gray9' },
        '@light': { color: 'gray6' }
      }
    }
  },

  copy: {
    symbols: {
      extend: 'DocsLink',
      props: {
        target: '_blank',
        href: 'https://symbols.app',
        text: 'Symbols'
      }
    },

    year: {
      text: ' © 2022'
    },

    version: {
      extend: 'DocsLink',
      props: {
        target: '_blank',
        href: 'https://www.npmjs.com/package/smbls/v/1.1.1',
        text: 'smbls@^1.1.1' // pkg.version
      }
    }
  },

  buttons: { extend: discussions.socialLinks }
}

export const MainFooter = {
  extend: Flex,
  props: {
    padding: 'A B2',
    gap: 'A',
    align: 'center space-between',
    '@mobileL': {
      flow: 'column'
    }
  },
  Flex_nav: {
    props: {
      gap: 'B',
      childProps: {
        color: 'gray8',
        fontWeight: 'normal',
        ':hover': {
          textDecoration: 'underline'
        }
      }
    },
    Link_access: {
      href: '/signin',
      text: 'Access'
    },
    Link_privacy: {
      href: '/privacy',
      text: 'Privacy'
    },
    Link: {
      href: 'mailto:hello@symbo.ls',
      text: 'Contact'
    }
  },
  Span_copyright: {
    flex: 1,
    textAlign: 'center',
    color: 'gray6',
    text: '2020-2023 © Symbols.app',
    '@mobileL': {
      order: 3
    }
  },
  Flex_icons: {
    props: {
      gap: 'Z2',
      childProps: {
        color: 'gray8',
        theme: null,
        background: 'transparent',
        target: '_blank',
        fontSize: 'A2',
        ':hover': { color: 'gray8' }
      }
    },
    childExtend: {
      extend: [Link, IconButton],
      props: ({ key }) => ({ icon: key })
    },
    medium: {
      props: { href: 'https://symbols.blog/' }
    },
    discord: {
      props: { href: 'https://discord.com/invite/crdFSkapFY' }
    },
    github: {
      props: { href: 'https://github.com/symbo-ls/' }
    }
  }
}
