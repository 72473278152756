'use strict'

import { getCookie, isString } from '@domql/utils' // eslint-disable-line no-unused-vars

const APP_PAGES = [
  '/dashboard',
  '/design-system',
  '/init',
  '/studio',
  '/schema',
  '/editor',
  '/library',
  '/preview',
  '/export',
  '/account',
  '/data',
  '/state',
  '/logout',
  '/color',
  '/theme',
  '/install',
  '/integrations',
  '/typography',
  '/space',
  '/shape',
  '/font',
  '/icons',
  '/media-query'
]

const checkIfStringIsInArray = (string, arr) => {
  if (!string) return
  return arr.filter(v => string.includes(v)).length
}

const GUEST_PAGES = [
  '/signin',
  '/access-signup',
  '/signup',
  '/waitlist'
]

const GREEN_PAGES = [
  '/docs',
  '/pricing',
  '/props',
  '/snippets',
  '/snippet',
  '/functions',
  '/function',
  '/playground',
  '/fiddles',
  '/developers',
  '/timeline',
  '/index',
  '/components',
  '/component',
  '/properties',
  '/convert',
  '/markdown'
]

export const initLoadbyAuth = (el, s) => {
  const { pathname } = window.location

  if (pathname === '/') {
    return getCookie('route') || '/dashboard'
  }
}

export const redirectInside = (route, el, s) => {
  const pathname = route
  const state = s.__root
  const { designSystem } = state.DATA

  if (!state.initialized) {
    const hasUserData = state.appKey && state.usersName // eslint-disable-line
    const hasProjects = state.projects && state.projects.length
    const hasOneProject = hasProjects === 1 // eslint-disable-line
    const hasProjectInfo = state.projectId && state.projectName && state.appKey
    const activeProject = state.activeProject !== ''

    const isInApp = checkIfStringIsInArray(pathname, APP_PAGES)
    const isGreen = checkIfStringIsInArray(pathname, GREEN_PAGES)

    const isInGuests = checkIfStringIsInArray(pathname, GUEST_PAGES)
    if (isInGuests) return '/dashboard'

    const hasProjectDesignSystem = designSystem &&
      designSystem.COLOR &&
      designSystem.FONT_FAMILY &&
      designSystem.THEME

    if (hasProjectDesignSystem) {
      const { THEME, COLOR, FONT_FAMILY } = designSystem

      const hasThemes = THEME && Object.keys(THEME).length
      const hasColors = COLOR && Object.keys(COLOR).length
      const hasFonts = FONT_FAMILY && Object.keys(FONT_FAMILY).length
      const hasEnv = state.framework && state.language

      if (!hasThemes) {
        if (pathname !== '/init/theme') {
          return '/init/theme'
        }
      } else if (!hasColors) {
        if (pathname !== '/init/pallete') {
          return '/init/pallete'
        }
      } else if (!hasFonts) {
        if (pathname !== '/init/font') {
          return '/init/font'
        }
      } else if (!hasEnv) {
        if (pathname !== '/init/env') {
          return '/init/env'
        }
      }
    }

    if (state.appKey) {
      if (!hasProjectInfo) {
        if (pathname !== '/init/personalize') {
          return '/init/personalize'
        }
      }
    } else if (state.name || state.usersName) {
      if (!hasProjects) {
        if (!pathname || (isString(pathname) && !pathname.includes('/account'))) {
          return '/init/create'
        }
      } else if (!activeProject) {
        if (pathname !== '/init') {
          return '/init'
        }
      } else if (!hasProjectInfo) {
        if (pathname !== '/init/personalize') {
          return '/init/personalize'
        }
      } else state.initialized = true
    }

    if (!route || route === '/') return '/dashboard'

    if (isInApp || isGreen) return pathname
    else return '/dashboard'
  }
}

export const redirectOutside = (route, el, s) => {
  const isInGuests = checkIfStringIsInArray(route, GUEST_PAGES)
  const isGreen = checkIfStringIsInArray(route, GREEN_PAGES)
  if (isInGuests || isGreen) return route
  return '/'
}

export const redirectBasedOnAuth = (route, el, s) => {
  if (s.__root.userToken) {
    return redirectInside(route, el, s)
  } else return redirectOutside(route, el, s)
}
