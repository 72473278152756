'use strict'

import { Flex } from '@symbo.ls/ui'

import { IconTitleParagraph } from '../components'
import { WebsiteTypes } from '../BannerShmaneri'

export const DreamBuild = {
  tag: 'section',
  extend: Flex,
  props: {
    margin: 'F1 - F -',
    gap: 'D2+X2'
  },

  TitleParagraph: {
    props: {
      alignItems: 'center',
      gap: 'A1'
    },
    Title: {
      tag: 'h1',
      props: {
        text: 'Dream it. Build it.',
        fontSize: 'K'
      }
    },

    Paragraph: {
      tag: 'p',
      props: {
        flow: 'column',
        color: 'grey1',
        textAlign: 'center',
        fontSize: 'D2',
        fontWeight: '100',
        maxWidth: 'G',
        display: 'inline'
      },
      ...[
        'Design, develop and run powerful web project ',
        {
          props: {
            text: 'Up to 10x faster.',
            display: 'inline',
            fontWeight: '700'
          }
        }
      ]
    }
  },

  Flex: {
    props: { gap: 'D' },
    childExtend: IconTitleParagraph,
    ...[{
      Title: {
        props: {
          icon: 'plus',
          text: 'Create'
        }
      },
      Paragraph: {
        props: { text: 'Build customer-facing web products and internal tools in hours instead of weeks.' }
      }
    }, {
      Title: {
        props: {
          icon: 'checkmark',
          text: 'Launch'
        }
      },
      Paragraph: {
        props: { text: 'Publish your site effortlessly and share it with the world in realtime.' }
      }

    }, {
      Title: {
        props: {
          icon: 'analytics',
          text: 'Iterate'
        }
      },
      Paragraph: {
        props: { text: 'Empower anyone on your team to make changes, not just developers.' }
      }
    }, {
      Title: {
        props: {
          icon: 'textAlignLeft',
          text: 'Scale'
        }
      },
      Paragraph: {
        props: { text: 'Empower anyone on your team to make changes, not just developers.' }
      }
    }]
  },

  BannerShmaneri: { extend: WebsiteTypes }
}
