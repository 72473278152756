'use strict'

import { Flex } from '..'

export const SwitchField = {
  tag: 'label',
  props: {
    boxSize: 'A2 B2',
    position: 'relative'
  },

  Input: {
    props: {
      type: 'checkbox',
      display: 'none',
      ':checked + span': { background: 'white .2' },
      ':checked + span:before': {
        left: '68%',
        background: 'white .85'
      }
    },
    on: {
      change: (ev, el, s) => el.parent.props.onChange(ev, el.parent, s)
    }
  },

  Pseudo: {
    tag: 'span',
    boxSize: 'A2 B2',
    position: 'absolute',
    cursor: 'pointer',
    inset: '0 0 0 0',
    background: 'white .06',
    borderRadius: 'C',
    transition: 'B',

    ':before': {
      content: '""',
      position: 'absolute',
      height: 'A',
      width: 'A',
      borderRadius: 'C',
      top: '50%',
      left: '32%',
      transform: 'translate3d(-50%, -50%, 0)',
      bottom: '4px',
      backgroundColor: 'white .1',
      transition: 'B'
    }
  }
}

export const SwitchFieldWithCaption = {
  extend: Flex,
  tag: 'label',

  props: {
    align: 'center space-between',
    gap: 'Y',
    userSelect: 'none',
    Checkbox: { display: 'none' }
  },

  Input: {
    props: {
      type: 'checkbox',
      display: 'none',
      ':checked ~ caption': { opacity: 1 },
      ':checked ~ span > span': {
        background: 'dim',
        ':before': { left: '68%', background: 'caption' }
      }
    },
    on: SwitchField.Input.on
  },

  element: {
    extend: SwitchField,
    tag: 'span',
    Input: null
  },

  caption: {
    props: {
      padding: '0',
      opacity: '.65',
      color: 'gray7',
      text: 'Disable this style'
    }
  }
}
