'use strict'

import { Flex, Link } from '.'

export const DesignElement = {
  extend: [Flex, Link],

  props: {
    width: '100%',
    flow: 'column',
    href: '/design-system',
    gap: 'Z1',
    padding: 'Z2 A',
    maxWidth: 'F1_default',
    borderRadius: 'Z',
    transition: 'background 250ms',
    ':hover': {
      '@dark': { background: 'white .05' },
      '@light': { background: 'black .05' }
    },

    button: {
      flexAlign: 'center center',
      round: 'Z',
      theme: 'default'
    },
    heading: {
      gap: 'W'
    }
  },

  button: {
    extend: 'WiderButton',
    attr: { tabIndex: '-1' }
  },
  heading: { extend: 'ArticleTiny' }
}
