'use strict'

import { Flex, TitleParagraph } from '@symbo.ls/ui'

export const FrontendBenefits = {
  tag: 'section',
  extend: Flex,
  props: { gap: 'D3+V2' },

  TitleParagraph: {
    props: {
      alignItems: 'center',
      gap: 'A2'
    },
    Title: {
      tag: 'h1',
      text: 'Frontend benefits',
      span: 'for any web project',
      props: {
        fontSize: 'H',
        flow: 'column',
        gap: 'V2',
        span: { fontWeight: '100' }
      }
    },
    Paragraph: {
      tag: 'p',
      props: {
        text: 'Using building blocks of Symbols',
        color: 'grey'
      }
    }
  },

  Grid: {
    props: {
      columns: 'repeat(3, 1fr)',
      columnGap: 'E',
      rowGap: 'C3'
    },
    childExtend: {
      extend: TitleParagraph,
      props: {
        alignItems: 'center',
        maxWidth: 'G1+Y2'
      },
      Title: {
        tag: 'h4',
        props: { color: 'headline' }
      },
      Paragraph: {
        tag: 'p',
        props: {
          color: 'caption',
          fontWeight: '100',
          textAlign: 'center'
        }
      }
    },
    ...[{
      Title: { props: { text: 'Save up to 90%' } },
      Paragraph: { props: { text: 'In development costs and resources' } }
    }, {
      Title: {
        text: 'No-code',
        span: 'allows',
        props: {
          gap: 'X',
          span: {
            fontWeight: '100',
            color: 'grey1'
          }
        }
      },
      Paragraph: { props: { text: 'non-developers to design and build any User Interface' } }
    }, {
      Title: { props: { text: 'Design, develop, scale' } },
      Paragraph: { props: { text: 'any type of UI without limitations' } }
    }, {
      Title: { props: { text: 'Up to 10x faster' } },
      Paragraph: { props: { text: 'to build and scale any UI' } }
    }, {
      Title: { props: { text: 'Open-Source development' } },
      Paragraph: { props: { text: 'with full ownership' } }
    }, {
      Title: { props: { text: 'Remove bureaucracy' } },
      Paragraph: { props: { text: 'throughout the development cycle' } }
    }]
  },

  AccessButtons: {}
}
