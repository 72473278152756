'use strict'

export const ValueElement = {
  tag: 'span',
  props: {
    text: 'color1',
    padding: 'X Y1',
    round: 'Y',
    position: 'relative',
    flexAlign: 'center flex-start',
    lineHeight: '1.2',
    gap: 'V'
  }
}
