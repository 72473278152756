'use strict'

import { Flex } from 'smbls'

export const TypeSizeTitle = {
  extend: Flex,
  props: {
    flow: 'row',
    gap: 'C',

    title: {
      fontWeight: '500',
      textAlign: 'end',
      fontSize: 'Z2',
      marginBottom: '.6em'
    },
    '& caption': {
      fontSize: 'Z2',
      '@dark': { color: 'gray6' },
      '@light': { color: 'gray9' }
    }
  },

  title: {
    text: ({ state }) => state.letterKey || state.key
  },
  caption: {
    text: ({ state }) => `${state.val}px ∙ ~${state.scaling}em`
  }
}
