'use strict'

import { SingleClick } from './SingleClick'

export const Reuse = {
  extend: SingleClick,
  props: {
    theme: 'redAlert'
  },

  H1: {
    text: 'Reuse across projects',
    color: 'white',
    '-webkit-text-stroke': 'none'
  }
}
