'use strict'

import colorContrast from './color-contrast.svg'
import colorContrastAlt from './color-contrast-alt-fill.svg'
import colorContrastAltOutline from './color-contrast-alt-outline.svg'

import accessibility from './accessibility-fill.svg'
import accessibilityOutline from './accessibility-outline.svg'

import voiceoverOutline from './voiceover-outline.svg'
import voiceover from './voiceover-fill.svg'

import eyeClosed from './eye-closed.svg'
import eyeOpen from './eye-open.svg'

export {
  accessibility,
  accessibilityOutline,

  colorContrast,
  colorContrastAlt,
  colorContrastAltOutline,

  voiceoverOutline,
  voiceover,

  eyeClosed,
  eyeOpen
}
