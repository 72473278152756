'use strict'

// A
import analytics from './analytics.svg'
import alignCenter from './align-center.svg'
import alignJustify from './align-justify.svg'
import alignLeft from './align-left.svg'
import alignRight from './align-right.svg'
import animationsAlt from './animations-alt-fill.svg'
import animationsAltOutline from './animations-alt-outline.svg'
import animations from './animations-fill.svg'
import animationsOutline from './animations-outline.svg'

// B
import burgerMenu from './burger-menu.svg'

// C
import clock from './clock.svg'
import clockOutline from './clock-outline.svg'
import clockColored from './clock-colored.svg'
import clockOutlineColored from './clock-outline-colored.svg'
import cloud from './cloud-fill.svg'
import cloudOutline from './cloud-outline.svg'
import copyOutline from './copy-outline.svg'
import copy from './copy.svg'
import content from './content.svg'
import comments from './comments.svg'
import customize from './customize.svg'
import customizeOutline from './customize-outline.svg'

// D
import databaseOutline from './database-outline.svg'
import database from './database-fill.svg'
import disabled from './disabled.svg'
import discussion from './discussion-outline.svg'
import discussionAlt from './discussion-alt-outline.svg'
import document from './document-outline.svg'
import download from './download.svg'
import downloadOutline from './download-outline.svg'

// E
import edit from './edit.svg'
import editor from './editor-fill.svg'
import editorOutline from './editor-outline.svg'
import exclMark from './exclamation-mark.svg'

// F
import filter from './filter-border.svg'
import fnOutline from './fn-outline.svg'
import fn from './fn-fill.svg'
import flow from './flow.svg'
import fuse from './fuse.svg'

// I
import iconsBold from './icons-bold.svg'
import iconsColored from './icons-colored.svg'
import icons from './icons-fill.svg'
import iconsOutline from './icons-outline.svg'
import iconsOutlineColored from './icons-outline-colored.svg'
import images from './images.svg'

// L
import layersOutline from './layers-outline.svg'
import layers from './layers-fill.svg'
import library from './library.svg'
import lock from './lock.svg'
import lockOutline from './lock-outline.svg'
import logo from './logo.svg'

// M
import menuAlt from './menu-alt.svg'
import menu from './menu.svg'
import magic from './magic-outline.svg'
import magicstar from './magic-star-fill.svg'
import magicstarOutline from './magic-star-outline.svg'
import shootingstar from './shooting-star-fill.svg'
import shootingstaHalfOutline from './shooting-star-half-outline.svg'
import shootingstarOutline from './shooting-star-outline.svg'
import micOutline from './mic-outline.svg'
import mic from './mic-fill.svg'

// P
import preferencesOutline from './preferences-outline.svg'
import preferences from './preferences-fill.svg'
import priceTag from './price-tag.svg'
import playground from './playground.svg'

// Q
import qr from './qr.svg'

// R
import ratingPointer from './rating-pointer.svg'
import ratingFrame from './rating-frame.svg'
import ratingStar from './rating-star.svg'
import report from './report-outline.svg'
import reportAlt from './report-alt-outline.svg'

import install from './install.svg'
import installAlt from './install-alt.svg'

// S
import searchOutline from './search-outline.svg'
import settings from './settings.svg'
import shape from './shapes.svg'
import shapeColored from './shapes-colored.svg'
import shapeOutline from './shapes-outline.svg'
import shapeOutlineColored from './shapes-outline-colored.svg'
import share from './share.svg'
import starOutline from './star-outline.svg'
import star from './star.svg'
import signal from './signal.svg'
import sync from './sync.svg'
import saveColored from './save-colored.svg'
import saveAlt from './save-fill-alt.svg'
import saveAltOutline from './save-outline-alt.svg'

// T
import theme from './theme-fill.svg'
import themeOutline from './theme-outline.svg'
import threeDots from './three-dots.svg'
import threeDotsVertical from './three-dots-vertical.svg'
import touchscreenOutline from './touchscreen-outline.svg'
import touchscreen from './touchscreen-fill.svg'
import tree from './tree.svg'
import trashAlt from './trash-alt.svg'
import trash from './trash.svg'

// U
import user from './user.svg'
import upload from './upload.svg'
import uploadOutline from './upload-outline.svg'
import url from './url.svg'
import unlock from './unlock.svg'
import unlockOutline from './unlock-outline.svg'
import unlockAlt from './unlock-alt.svg'
import unlockAltOutline from './unlock-alt-outline.svg'
import unsaveColored from './unsave-colored.svg'
import wave from './wave-fill.svg'
import waveOutline from './wave-outline.svg'

// Z

import noIcon from './no-icon.svg'

export {
  analytics,
  alignCenter,
  alignJustify,
  alignLeft,
  alignRight,
  animationsAlt,
  animationsAltOutline,
  animations,
  animationsOutline,
  burgerMenu,
  clock,
  clockOutline,
  clockColored,
  clockOutlineColored,
  cloud,
  cloudOutline,
  copyOutline,
  copy,
  comments,
  customize,
  customizeOutline,
  databaseOutline,
  database,
  disabled,
  discussion,
  discussionAlt,
  document,
  download,
  downloadOutline,
  edit,
  editor,
  editorOutline,
  exclMark,
  filter,
  fnOutline,
  fn,
  iconsBold,
  iconsColored,
  icons,
  iconsOutline,
  iconsOutlineColored,
  images,
  layersOutline,
  layers,
  library,
  lock,
  lockOutline,
  logo,
  menuAlt,
  menu,
  magic,
  magicstar,
  magicstarOutline,
  mic,
  micOutline,
  shootingstar,
  shootingstaHalfOutline,
  shootingstarOutline,
  preferencesOutline,
  preferences,
  priceTag,
  playground,
  ratingPointer,
  ratingFrame,
  ratingStar,
  report,
  reportAlt,
  searchOutline,
  install,
  installAlt,
  settings,
  shapeColored,
  shapeOutline,
  shapeOutlineColored,
  shape,
  share,
  starOutline,
  star,
  signal,
  theme,
  themeOutline,
  threeDots,
  threeDotsVertical,
  touchscreenOutline,
  touchscreen,
  tree,
  trashAlt,
  trash,
  user,
  uploadOutline,
  upload,
  unlock,
  unlockOutline,
  unlockAlt,
  unlockAltOutline,
  url,
  content,
  flow,
  fuse,
  qr,
  sync,
  saveColored,
  saveAlt,
  saveAltOutline,
  unsaveColored,
  wave,
  waveOutline,
  noIcon
}
