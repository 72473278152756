'use strict'

import { isObject } from '@domql/utils'
import { colorStringToRgbaArray } from '@symbo.ls/scratch'

export const getGlobalTheme = (state) => {
  const { designSystem } = state.__root.DATA
  return state.__root.sceneTheme || designSystem.globalTheme
}

export const getThemeState = (state) => {
  const themeState = state.themeKey ? state : state.parent
  return themeState.themeKey ? themeState : themeState.parent
}

export const getProjectThemeValues = (state, forcedTheme, globalTheme) => {
  const sceneTheme = globalTheme || getGlobalTheme(state)
  const themeState = state.themeKey ? state : state.parent
  const themeScope = (forcedTheme || themeState.value || themeState)[`@${sceneTheme}`] || {}
  const { color, background, borderColor, outlineColor, ...rest } = themeScope

  return { color, background, borderColor, outlineColor, ...rest }
}

export const findColorReference = (s, FACTORY, value) => {
  const globalTheme = getGlobalTheme(s)
  const [colorValue] = value.split(' ')
  let color = FACTORY[colorValue]
  if (globalTheme && isObject(color)) {
    color = color[`@${globalTheme}`]
  }
  if (color && color.slice(0, 2) === '--') {
    color = findColorReference(s, FACTORY, color.slice(2))
  }
  return color
}

export const getColorReference = (state, value) => {
  const { designSystem } = state.__root.DATA
  return findColorReference(state, designSystem.COLOR, value)
}

export const getGradientReference = (state, value) => {
  const { designSystem } = state.__root.DATA
  return findColorReference(state, designSystem.GRADIENT, value)
}

export const getProjectColorByValue = (state, value) => {
  if (!value) return
  const color = getColorReference(state, value)
  if (color) {
    const colorValue = color.split(' ')[0]
    const opacity = value.split(' ')[1]
    const colorArr = colorStringToRgbaArray(colorValue)
    const [r, g, b] = colorArr
    return `rgba(${r}, ${g}, ${b}, ${opacity || 1})`
  }
  const gradient = getGradientReference(state, value)
  return gradient
}

export const getProjectColorsFactory = (state) => {
  return (key) => {
    return getProjectColorByValue(state, key)
  }
}

export const getProjectThemeColors = (state, forcedTheme, sceneTheme) => {
  const {
    color,
    background,
    borderColor,
    outlineColor,
    ...rest
  } = getProjectThemeValues(state, forcedTheme, sceneTheme)
  const getFromColorFactory = getProjectColorsFactory(state)

  return {
    color: getFromColorFactory(color),
    background: getFromColorFactory(background),
    borderColor: getFromColorFactory(borderColor),
    outlineColor: getFromColorFactory(outlineColor),
    ...rest
  }
}

export const getActiveThemeValue = (s, themeKey) => {
  const { DATA, sceneTheme } = s.__root
  const { THEME } = DATA.designSystem
  if (!themeKey) themeKey = getThemeState(s).themeKey
  return sceneTheme ? THEME[themeKey][`@${sceneTheme}`] : THEME[themeKey]
}

export const setThemeValueInProject = (s, themeKey, key, val, options) => {
  const { sceneTheme } = s.__root
  const value = { [key]: val }
  const THEME = {
    [themeKey]: sceneTheme ? {
      [`@${sceneTheme}`]: value
    } : value
  }
  s.rootUpdate({
    DATA: { designSystem: { THEME } }
  }, options)
}
