'use strict'

import { Flex, Img } from '@symbo.ls/ui'

import MAINGIF from '../assets/main.gif'
import $200_PNG from '../assets/200+.png'
import FORIT_PNG from '../assets/forIt.png'
import COLAB_PNG from '../assets/colab.png'
import BLOCKS_PNG from '../assets/blocks.png'
import BB_PNG from '../assets/bb.png'
import SPOTIFY_PNG from '../assets/spotify.png'
import { registerScrollListener } from '../functions/scroll'

export const Hero = {
  tag: 'section',
  extend: Flex,

  props: {
    padding: '25vh - -'
  },

  TitleParagraph: {
    props: { gap: 'A', color: 'white' },
    Title: {
      tag: 'h1',
      props: {
        fontSize: 'H2',
        fontWeight: '100',
        flow: 'column',
        lineHeight: '1.15em'
      },
      ...[
        'Build and scale better',
        {
          props: {
            text: 'User Interfaces in hours not months',
            fontWeight: '700'
          }
        }
      ]
    },
    Paragraph: {
      tag: 'p',
      props: {
        textAlign: 'center',
        flow: 'column',
        color: 'grey'
      },
      ...[
        'Build and scale better websites in hours not days using',
        {
          ...[
            'building blocks of ',
            {
              props: {
                text: 'Symbols',
                display: 'inline',
                fontWeight: '500'
              }
            }
          ]
        }
      ]
    }
  },

  AccessButtons: { margin: 'D1 - - -' },

  VideoSection: {
    extend: 'Flex',
    props: {
      width: '100%',
      align: 'center center',
      transform: 'perspective(2000) rotateX(60deg) scale(0.85)',
      transformOrigin: '50% 100%',
      border: 'rgba(36, 133, 240, 0.1) solid 1px',
      overflow: 'hidden',
      round: 'A',

      '@dark': { textShadow: '--theme-document-dark-background, 0px, 0px, 1px' },
      '@light': { textShadow: '--theme-document-light-background, 0px, 0px, 1px' }
    },
    on: {
      init: registerScrollListener,
      scroll: (ev, el, s) => {
        const { scrollY } = window
        const { parent, node } = el
        const parentTop = parent.node.getBoundingClientRect().top + 400
        const innerHeight = window.innerHeight
        const calcY = parseInt(parentTop / 10)

        const rotated = calcY > 0 ? calcY < 65 ? calcY : 65 : 0
        const moveRatio = scrollY / 1.15 - innerHeight / 2.5
        const translated = scrollY > innerHeight / 2.5
          ? scrollY < innerHeight
              ? moveRatio
              : moveRatio + (innerHeight - scrollY)
          : 0

        const shadowDistance = scrollY - innerHeight / 3
        const shadowRatio = scrollY > innerHeight / 3 ? shadowDistance > 100 ? shadowDistance : 100 : 0

        const perspective = 'perspective(4000)'
        const rotate = `rotateX(${-rotated}deg)`
        const scale = 'scale(0.65)'
        const translate = `translate3d(0, ${translated}px, 0)`

        const transform = `-webkit-transform: ${perspective} ${rotate} ${scale} ${translate};`
        const opacity = `opacity: ${scrollY / 50};`
        const boxShadow = `box-shadow: var(--color-black) 0 0 ${parseInt(shadowRatio / 2)}px, rgba(36, 133, 240, .5) 0 25px ${parseInt(shadowRatio)}px -35px;`

        node.style = transform + opacity + boxShadow
      }
    },

    Img: {
      width: '100%',
      src: MAINGIF
    }
  },

  Box: {
    props: {
      boxSize: 'D 100%',
      margin: '55vh - - -',
      overflow: 'hidden',
      position: 'relative',
      ':before': {
        content: '""',
        boxSize: '100% E',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'linear-gradient(to right, rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 0) 100%)',
        zIndex: '2'
      },
      ':after': {
        content: '""',
        boxSize: '100% D',
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '2',
        background: 'linear-gradient(to left, rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 0) 100%)'
      }
    },

    Flex: {
      props: {
        gap: 'C2',
        overflowX: 'auto',
        alignItems: 'center'
      },

      childExtend: {
        extend: Flex,
        props: {
          align: 'center flex-start',
          gap: 'C2',
          style: { filter: 'grayscale(100%)' }
        },
        childExtend: {
          extend: Img,
          props: {
          }
        },
        ...[
          {
            props: {
              src: $200_PNG,
              boxSize: 'C1 F'
            }
          },
          {
            props: {
              src: FORIT_PNG,
              boxSize: 'A2 B1'
            }
          },
          {
            props: {
              src: COLAB_PNG,
              boxSize: 'A D+W'
            }
          },
          {
            props: {
              src: BLOCKS_PNG,
              boxSize: 'B2 B2+V'
            }
          },
          {
            props: {
              src: BB_PNG,
              boxSize: 'B+V B'
            }
          },
          {
            props: {
              src: SPOTIFY_PNG,
              boxSize: 'B B+W'
            }
          }
        ]
      },
      ...[{}, {}, {}]
    }
  }
}
