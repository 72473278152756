'use strict'

import { isObject } from '@domql/utils'
import { Link, WiderButton, FrameworkSwitcher } from '..'
import { buttonsShowState, detailsIintialState } from './transitions'

export const Buttons = {
  tag: 'section',
  props: ({ state }) => ({
    flexAlign: 'center flex-start',
    gridColumn: 'span 4',
    gap: 'X2',
    ...detailsIintialState,
    ...(state.__element.props.isActive ? buttonsShowState : {})
  }),

  childExtend: {
    extend: [WiderButton],
    props: {
      fontSize: 'A',
      transition: 'A',
      transitionProperty: 'background, color, border',
      theme: 'secondary-highlight'
    }
  },

  ...[{
    extend: Link,
    tag: 'a',
    props: ({ state }) => ({
      href: `${window.location.pathname}#${state.title}-property`,
      Icon: { name: 'url' }
    })
  }, {
    extend: FrameworkSwitcher,
    if: ({ state }) => state.code && isObject(state.code),
    props: ({ state }) => ({
      Icon: { name: state.__root.framework },
      padding: 'Z B Z Z2',
      tooltip: {
        left: '-X2',
        right: 'auto',
        bottom: '125%',
        top: 'auto',
        ':before': {
          left: 'A2'
        }
      }
    }),
    Icon_arrow: {
      position: 'absolute',
      opacity: 0.5,
      fontSize: 'Z',
      right: 'Z1',
      icon: 'arrowAngleMirroringVertical'
    }
  }, {
    props: {
      margin: '0 0 0 auto',
      Icon: { name: 'crossmark' }
    },
    on: {
      click: (ev, { state }) => {
        ev.stopPropagation()
        state.update({ active: false })
      }
    }
  }]
}
