'use strict'

export const NumbCaption = {
  props: {
    flexAlign: 'center flex-start',
    numb: {
      width: 'A2',
      fontWeight: '400',
      opacity: '0.65',
      color: 'paragraph'
    },
    name: { fontWeight: '500' }
  },

  numb: {},
  name: {}
}

export const IconCaption = {
  props: {
    flexAlign: 'center flex-start',
    gap: 'Y',
    color: 'caption'
  },

  Icon: {
    icon: 'github',
    color: 'paragraph'
  },

  name: {}
}

export const DotCaption = {
  tag: 'section',

  props: {
    flexAlign: 'center flex-start',
    dot: {
      width: 'A1',
      text: '•',
      textDecoration: 'none',
      opacity: '0.35',
      color: 'paragraph'
    },
    name: {
      ':hover': { textDecoration: 'underline' }
    }
  },

  dot: {},
  name: {}
}
