'use strict'

import { Flex } from '@symbo.ls/ui'

import CMS_PNG from '../assets/content.webp'
import { positions, registerScrollListener } from '../functions/scroll'

export const CreateCMS = {
  tag: 'section',
  extend: Flex,

  TitleParagraph: {
    props: {
      alignSelf: 'flex-start',
      gap: 'A1',
      padding: '- - C C2'
    },
    Title: {
      tag: 'h1',
      props: {
        text: 'Create CMS for any website',
        fontSize: 'I2',
        maxWidth: 'E2',
        color: 'white'
      }

    },
    Paragraph: {
      props: {
        text: 'Build CMS that is designed for your content',
        fontSize: 'C2',
        color: 'white'
      }
    }
  },

  ContentImg: {
    props: {
      position: 'relative',
      overflow: 'hidden',
      padding: 'A2',
      round: 'A',
      theme: 'common-card-outline',
      borderStyle: 'solid',
      borderWidth: '1px',
      background: 'gray2',
      cursor: 'none'
    },

    Img: {
      src: CMS_PNG,
      width: '100%'
    },

    Overlay: { background: 'black .8', position: 'absolute', inset: '0 0 0 0' },

    Light: {
      on: {
        init: registerScrollListener,
        scroll: (ev, el, s) => {
          let { mouseX, mouseY } = positions
          const { top, left } = el.parent.node.getBoundingClientRect()

          mouseX = mouseX - 325
          mouseY = mouseY - 325

          const translate = `translate3d(${mouseX - left}px, ${mouseY - top}px, 1px)`
          const transform = `transform: ${translate}`
          el.node.style = transform
        }
      }
    }
  },

  Flex: {
    props: {
      width: '100%',
      alignSelf: 'flex-start',
      justifyContent: 'space-between',
      padding: 'B2 B1 D+W2 B2+X'
    },

    SectionTitle: {
      props: { maxWidth: 'G2' },
      ...[
        'Build from',
        {
          props: {
            text: ' extensive portfolios to interactive stories ',
            fontWeight: '700'
          }
        },
        'for your web project'
      ]
    },

    AccessButtons: {}
  },

  Flex2: {
    extend: Flex,
    props: {
      alignSelf: 'flex-start',
      align: 'center flex-start',
      gap: 'D',
      padding: '- B2+X',
      fontWeight: '500'
    },
    ...[{
      props: { text: 'Blog post' }
    }, {
      props: { text: 'Article' }
    }, {
      props: { text: 'Vlog' }
    }, {
      props: { text: 'Portfolio' }
    }, {
      props: { text: 'User story' }
    }, {
      props: { text: 'Research' }
    }, {
      props: { text: 'Report' }
    }, {
      props: { text: 'Essay' }
    }, {
      props: { text: 'Notes' }
    }]
  }
}
