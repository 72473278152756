'use strict'

import { toDescriptionCase } from '@symbo.ls/utils'
import { Notification } from '.'
import { getColorValue } from '@symbo.ls/platform-utils/color'

export const GlobalThemeTemplate = {
  extend: [Notification],

  props: (el, s) => {
    const { background, color } = s.parent[s.value]

    const theme = {
      color: getColorValue(color, el, s),
      background: getColorValue(background, el, s)
    }

    return {
      textDecoration: 'none',
      gap: 'Z2',
      round: 'W',
      align: 'flex-start flex-start',
      padding: 'A D2 A A1',
      fontWeight: '400',
      href: `/color/global-theme/${s.value}`,
      scrollToTop: false,
      theme: null,

      opacity: '.65',
      '@dark': { border: '1px, solid, --color-line-dark' },
      '@light': { opacity: 1, border: '1px, solid, --color-line-light' },
      ...theme
    }
  },

  IconText: {
    props: ({ state }) => ({
      padding: 'Y',
      // round: 'Z',
      theme: `sepia @${state.value}`,
      Icon: {
        name: state.value === '@light' ? 'sun' : state.value === '@dark' ? 'moon' : 'threeDots'
      }
    })
  },

  Flex: {
    props: {
      gap: 'X',
      pointerEvents: 'none'
    },
    Title: {
      text: ({ state }) => toDescriptionCase(state.value.slice(1)) + ' theme'
    },
    P: {
      span: 'prefers-color-scheme: ',
      text: ({ state }) => state.value
    }
  }
}
