'use strict'

import { exec, isDefined, isString } from '@domql/utils'
import { router } from '@symbo.ls/router'
import { RouterLink } from '@symbo.ls/uikit'

RouterLink.on.click = (event, element, state) => {
  var root = element.__ref.__root
  var href = exec(element.props.href || element.attr.href, element)
  if (!isString(href)) return
  var firstFour = href.slice(0, 4)
  if (firstFour !== 'http' && firstFour !== 'sket' && firstFour !== 'mail') {
    const { scrollToTop } = element.props
    const routerOptions = element.props.routerOptions || {
      scrollToOptions: { behavior: 'instant' }
    }
    router(href, root, {}, {
      ...routerOptions,
      scrollToTop: isDefined(scrollToTop) ? scrollToTop : true
    })
    event.preventDefault()
  }
}
