'use strict'

import { Flex } from '@symbo.ls/ui'

import { OpenSource, GetAccess } from '../BannerShmaneri'

export const OpenEcosystem = {
  tag: 'section',
  extend: Flex,
  props: {
    padding: 'E - D -'
  },

  BannerShmaneri: {
    extend: OpenSource
  },

  SectionTitle: {
    props: {
      textAlign: 'center',
      padding: 'C2 -'
    },
    ...[
      {
        props: {
          text: 'Whatever you create in Symbols, ',
          fontWeight: '700'
        }
      },
      'can all be fully exported away from the platform with the source code. Giving you absolute peace of mind for whatever you build, you fully own.'
    ]
  },

  BannerShmaneri2: { extend: GetAccess }

}
