'use strict'

import { Scene, Button, Pseudo, Flex } from '..'
import { ResponsiveGrid } from '.'

const exampleButton = {
  extend: Button,
  props: {
    text: 'Continue',
    padding: 'Z1 D2',
    color: 'currentColor',
    background: 'transparent',
    round: 'A2',
    border: '2px, solid, blue .2'
  }
}

const scene = {
  extend: Scene,

  props: ({ state, context }) => {
    const { designSystem } = context
    const devices = designSystem.DEVICES
    const [widthVal, HeightVal] = devices[state.previewSize]
    const width = `calc(${widthVal + 'px'} - 1.8em)`
    const height = `calc(${HeightVal + 'px'} - 5em)`
    const sidebarIsOn = state.__root.studioSidebarToggle

    return {
      transition: 'A defaultBezier',
      transitionProperty: 'width, height',
      sidebarIsOn,

      width: width || '100%',
      height,
      position: 'absolute',
      top: 'Z2',
      right: 'Z2',
      zIndex: '5',

      '.sidebarIsOn': {
        padding: '0'
      }
    }
  },

  scene: {
    props: ({ state }) => ({
      position: 'relative',
      sidebarIsOn: state.__root.studioSidebarToggle,
      '.sidebarIsOn': {
        position: 'absolute',
        inset: '0 0 0 0',
        padding: '0'
      }
    }),

    StudioFramePreview: {
      props: {
        position: 'absolute',
        inset: '0 0 0 0',
        padding: '0',
        maxWidth: '100%',
        Iframe: {
          height: '100%',
          minHeight: '100%',
          position: 'absolute',
          width: '100%',
          minWidth: '0',
          inset: '0 0 0 0'
        }
      },
      if: ({ state }) => {
        return state.__root.studioSidebarToggle
      },
      on: {
        initUpdate: () => false
      }
    },
    exampleButton
  }
}

const grid = {
  extend: ResponsiveGrid,

  props: {
    display: 'block',
    minHeight: 'calc(100vh - var(--spacing-F))',
    borderStyle: 'solid',
    borderColor: 'line-highlight',
    borderWidth: '1px',
    position: 'relative',
    overflow: 'hidden'
  },

  childExtend: {
    extend: Pseudo,
    props: ({ state }) => ({
      padding: 'A',
      minWidth: '320px',
      minHeight: '320px',
      width: state.width + 'px',
      height: state.height + 'px',
      align: 'flex-end flex-start',
      '@dark': {
        background: 'gray1 .75',
        boxShadow: '--theme-document-dark-background, -65px, 35px, 65px, -15px' },
      '@light': {
        background: 'gray15 .75',
        boxShadow: '--theme-document-light-background, -65px, 35px, 65px, -15px' },

      borderColor: 'line',
      borderStyle: 'solid',
      borderWidth: '1px',
      pointerEvents: 'none',

      ButtonTitle: {
        pointerEvents: 'all',
        ':hover': {
          color: 'caption',
          '@dark': { textShadow: '--theme-document-dark-background, 0px, 0px, 1px' },
          '@light': { textShadow: '--theme-document-light-background, 0px, 0px, 1px' }
        },
        IconButton: {
          Icon: {
            name: state.icon + (state.key === state.parent.previewSize ? '' : ' outline')
          }
        }
      },

      active: state.key === state.parent.previewSize,
      '.active': {
        zIndex: 2,
        ':before': {
          content: '""',
          position: 'absolute',
          inset: '0 0 0 0',
          borderColor: 'line-highlight',
          borderStyle: 'solid',
          borderWidth: '1px',
          zIndex: 1,
          pointerEvents: 'none',
          transition: 'A defaultBezier',
          transitionProperty: 'width, height'
        }
      },

      ':hover': {
        style: {
          button: {
            opacity: 0.85,
            zIndex: 3
          }
        }
      },

      ':first-child': {
        position: 'relative',
        margin: '0 0 0 auto'
      },

      ':not(:first-child)': {
        position: 'absolute',
        top: '-1px',
        right: '-1px'
      }
    }),
    ButtonTitle: {
      on: {
        click: (ev, el, s) => {
          const previewSize = s.parent.previewSize !== s.key ? s.key : 'mobileS'
          s.parentUpdate({ previewSize })
        }
      }
    }
  },

  $setStateCollection: ({ state }) => state.devices
}

export const ResponsiveSreensPreview = {
  extend: Flex,

  state: ({ context }) => {
    const { designSystem } = context
    const devices = designSystem.DEVICES
    return {
      previewSize: 'mobileS',
      devices: Object.keys(devices).map(key => {
        const iconMap = {
          deviceMobile: ['mobileXS', 'mobileS', 'mobileM', 'mobileL'],
          deviceTabletPortrait: ['tabletS'],
          deviceTabletLandscape: ['tabletM', 'tabletL'],
          deviceSmallScreen: ['screenS', 'screenM'],
          deviceBigScreen: ['screenL']
        }

        let icon

        const keys = Object.keys(iconMap)
        Object.values(iconMap).map((device, index) => {
          const isInArray = device.indexOf(key)
          if (isInArray > -1) icon = keys[index]
        })

        const [width, height] = devices[key]
        return {
          key,
          icon,
          width,
          height
        }
      })
    }
  },

  props: {
    flow: 'column',
    gap: 'B',

    content: {
      margin: '0 -Z2',
      position: 'relative'
    }
  },

  content: {
    grid,
    scene
  }
}
