'use strict'

import { Flex, Grid } from '@symbo.ls/ui'

import { IconTitleParagraph } from '../components'
import { UnlimitComponents } from '../BannerShmaneri'

export const UnlimitedComponents = {
  tag: 'section',
  extend: Flex,
  props: { },

  BannerShmaneri: { extend: UnlimitComponents },

  Flex: {
    props: {
      align: 'flex-start space-between',
      width: '100%',
      padding: 'D+Z B1 C2 B2+X'
    },
    SectionTitle: {
      props: {},
      ...[
        {
          props: {
            text: 'Build anything with a UI components library. ',
            fontWeight: '700'
          }
        },
        'Build any type of web project from stunning landing pages with high conversion rates, immersive portfolios and blogs, to the next big SaaS application.'
      ]
    },
    AccessButtons: {
      props: {},
      ...[{
        props: { theme: 'seaBlue' }
      }]
    }
  },

  Gifs: {
    extend: Grid,
    props: {
      boxSize: 'G 100%',
      columns: '1fr 0.5fr 1fr',
      gap: 'Z2',
      boxSizing: 'border-box',
      padding: '- B'

    },
    childExtend: {
      props: {
        theme: 'secondary',
        round: 'A'
      }
    },
    ...[{}, {}, {}]
  },

  SectionTitle: {
    props: {
      alignSelf: 'flex-start',
      margin: 'C - B3 A2'
    },
    ...[
      {
        props: {
          text: 'Use 200+ components from our marketplace ',
          fontWeight: '700'
        }
      },
      'within our React, DOMQL and soon others ecosystem and feature rich UI Framework.'
    ]
  },

  Gif: {
    width: 'calc(100% - 50px)',
    height: 'H1+Z',
    theme: 'secondary',
    round: 'A'
  },

  Flex2: {
    extend: Flex,
    props: {
      align: 'center space-between',
      width: 'calc(100% - 50px)',
      padding: 'C3 A2 D+X1 A2'
    },
    childExtend: {
      extend: IconTitleParagraph,
      props: {
        Paragraph: { maxWidth: 'F2+C' }
      }
    },
    ...[{
      Title: {
        props: {
          icon: 'menu',
          text: '-'
        }
      },
      Paragraph: {
        props: { text: 'Use your Design System in any framework or no-code builder of your choise' }
      }
    }, {
      Title: {
        props: {
          icon: 'menu',
          text: '-'
        }
      },
      Paragraph: {
        props: { text: 'Get systems synchrnoize in your favorite tools' }
      }
    }, {
      Title: {
        props: {
          icon: 'menu',
          text: '-'
        }
      },
      Paragraph: {
        props: { text: 'Apply your changes to your website offline and online using our cloud platform and tools' }
      }
    }]
  }
}
