'use strict'

import {
  CommonFieldLike,
  Dropdown,
  DropdownParent,
  ListInDropdown,
  SelectField
} from '..'

export const FontSelect = {
  extend: SelectField
}

export const FontSelectWithTitle = {
  extend: CommonFieldLike,
  title: {
    text: 'Choose Font'
  },
  element: {
    extend: FontSelect
  }
}

export const FontDropwdown = {
  extend: Dropdown,

  props: {
    top: '121%',
    minWidth: 'F3',
    left: '0'
  },

  header: null,

  list: {
    extend: ListInDropdown,

    state: ({ parent }) => {
      const { FONT } = parent.state.__root.DATA.designSystem
      if (!FONT) return
      const list = Object.keys(FONT).map(value => ({
        value: FONT[value],
        key: value
      }))
      return {
        title: 'Fonts',
        list
      }
    },

    childExtend: {
      extend: [{
        props: (el, s) => ({
          align: 'center flex-start',
          text: s.key
        })
      }]
    },

    $setStateCollection: ({ state }) => state.list
  },

  on: {
    choose: (ev, el, s, ctx) => {
      s.update({ value: ctx.state.key }, {
        // preventHoistElementUpdate: true
      })
    }
  }
}

export const FontSelectDropdown = {
  extend: [FontSelect, DropdownParent],
  dropdown: { extend: FontDropwdown }
}

export const FontSelectDropdownWithTitle = {
  extend: CommonFieldLike,
  props: {
    minWidth: 'F1',
    element: {
      width: '100%',
      dropdown: {
        width: '100%',
        top: '110%'
      }
    }
  },
  title: {},
  element: {
    extend: FontSelectDropdown
  }
}

export const FontFamilyDropwdown = {
  extend: FontDropwdown,

  list: {
    state: ({ parent }) => {
      const { FONT_FAMILY } = parent.state.__root.DATA.designSystem
      if (!FONT_FAMILY) return
      const list = Object.keys(FONT_FAMILY).map(value => ({
        value: FONT_FAMILY[value],
        key: value
      }))
      return {
        title: 'Fonts',
        list
      }
    }
  }
}

export const FontFamilySelectDropdown = {
  extend: [FontSelect, DropdownParent],
  dropdown: { extend: FontFamilyDropwdown }
}

export const FontFamilyDropdownWithTitle = {
  extend: CommonFieldLike,
  props: {
    minWidth: 'F1',
    element: {
      width: '100%',
      dropdown: {
        width: '100%',
        top: '110%'
      }
    }
  },
  title: {},
  element: {
    extend: FontFamilySelectDropdown
  }
}
