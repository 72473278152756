'use strict'

import { Pseudo } from '.'
import { ValueElement } from './ValueElement'

const props = {
  flexAlign: 'center flex-start',
  position: 'relative',
  gap: 'Z1',

  ':before': {
    content: `'['`,
    display: 'block',
    color: 'gray7',
    position: 'absolute',
    left: '-7px'
  },
  ':after': {
    content: `']'`,
    display: 'block',
    color: 'gray7',
    position: 'absolute',
    right: '-7px'
  },

  style: { '> span:last-child:after': { display: 'none' } }
}

export const ArrayBox = {
  extend: Pseudo,

  props,

  childExtend: {
    extend: [ValueElement, Pseudo],
    props: {
      ':after': {
        content: `','`,
        display: 'block',
        color: '#818186',
        position: 'absolute',
        right: '-5.5px',
        bottom: '1px'
      }
    }
  }
}
