'use strict'

import { Scene } from './'
import { isString } from '@domql/utils'

export const SceneTemplate = {
  extend: Scene,

  props: (el, s) => {
    const { BREAKPOINTS } = el.context && el.context.designSystem
    const previewSize = BREAKPOINTS[s.__root.previewSize]
    return {
      width: '100%',
      overflow: 'hidden',
      maxWidth: previewSize ? previewSize + 'px' : '100%',
      margin: '- auto'
    }
  },

  scene: {
    props: {
      padding: 'B 0',
      maxHeight: '40em',
      minHeight: '65vh'
    }
  },

  on: {
    update: (el, s) => {
      el.__ref.__root.node.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}

export const ExportPreviewComponent = {
  extend: SceneTemplate,

  scene: {
    class: {
      z: (el, s) => ({
        '> *': {
          transform: `scale(${parseFloat(s.zoomLevel, 2)})`
        }
      })
    },

    content: {
      props: {
        transition: 'all, B, defaultBezier',
        style: {
          '*': { transition: 'all 255ms cubic-bezier(.29,.67,.51,.97)' }
        }
      },

      comp: (el, s) => ({
        extend: isString(s.component) ? document.eval(s.component) : s.component,
        props: { inheritSpacingRatio: true }
      }),

      on: {
        initUpdate: (changes, el, s) => {
          el.setProps({
            base: s.base,
            fontSize: s.base + 'px',
            spacingRatio: s.ratio,
            direction: s.direction,
            ...s.component.props
          }, { preventUpdate: true })
        }
      }
    }
  }
}
