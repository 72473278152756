'use strict'

import { Flex, IconText } from '..'

export const MultipleSelect = {
  extend: Flex,
  props: {
    theme: 'field',
    width: 'F2',
    flow: 'column',
    align: 'flex-start center',
    round: 'Z',
    padding: 'Y X',
    overflow: 'auto',
    svg: { opacity: 0 }
  },

  childExtend: {
    extend: IconText,
    props: {
      width: '100%',
      gap: 'Z',
      padding: 'Z',
      style: { cursor: 'pointer' },
      Icon: {
        name: 'checkmark',
        opacity: '0'
      },
      ':hover': {
        style: {
          svg: { opacity: '1' }
        }
      }
    }
  }
}
