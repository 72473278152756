'use strict'

import { Flex, Link } from 'smbls'
import { IconButton } from '../Button'
import { Overlay } from '../Overlay'

export const PageTemplate = {
  tag: 'main',
  extend: Flex,

  props: {
    maxWidth: 'K_default',
    margin: '- auto',
    width: '100%',
    padding: 'D2 E B1',
    flex: 1,
    flow: 'column'

    // '@screenS': { padding: 'C1 C B1' },
    // '@tabletS': { padding: 'C1 B' }
  }
}

export const Page = {
  tag: 'main',
  extend: PageTemplate,

  props: {
    width: '100%',
    maxWidth: 'K_default',
    margin: '- auto'
  }
}

export const InitPage = {
  tag: 'main',
  extend: Flex,

  props: {
    width: '100%',
    maxWidth: 'J',
    flow: 'column',
    position: 'relative',
    align: 'flex-start',

    content: { width: '100%' }
  },

  IconButton_back: {
    extend: [Link, IconButton],
    props: {
      position: 'absolute',
      fontSize: 'A1',
      top: '0',
      right: '102.5%',
      icon: 'arrow angle left'
    }
  }
}

export const DisabledPage = {
  props: {
    maxHeight: 'none',
    overflow: 'visible',
    zIndex: '100',
    position: 'absolute',
    inset: '0 0 0 0',
    height: '100%',

    '.active': {
      maxHeight: '100vh',
      overflow: 'hidden'
    },

    '!active': {
      maxHeight: 'none',
      overflow: 'visible'
    }
  },

  overlay: {
    extend: [Overlay, Flex],

    props: {
      overflow: 'hidden',
      background: 'shadow-overlay',
      '!active': {
        animation: 'fadeOutDown',
        animationDuration: 'G',
        transition: 'G defaultBezier',
        transitionProperty: 'opacity, transform, visibility',
        opacity: '0',
        visibility: 'hidden',
        transform: 'translate3d(0, 12.5%, 0)',
        pointerEvents: 'none'
      },
      '.active': {
        animation: 'fadeInUp',
        animationDuration: 'G'
      }
    },

    // Overlay: {
    //   background: 'gradient-colorful',
    //   inset: '0 0 0 0',
    //   opacity: '.9',
    //   style: {
    //     WebkitMaskImage: 'url(' + MASK1 + ')',
    //     WebkitMaskSize: '100% 100%'
    //   }
    // },

    message: {
      props: {
        animation: 'fadeInUpShort',
        animationDuration: 'G',
        animationDelay: 'G',
        margin: 'auto',
        padding: 'F E D',
        width: '100%',
        maxWidth: 'K_default',
        ArticleBig: {
          align: 'center',
          p: {
            color: 'gray8',
            margin: 'X 0 0',
            animation: 'fadeInUpShort',
            animationDuration: 'G',
            animationDelay: 'G1'
          }
        }
      },

      ArticleBig: {
        p: 'Add components from the marketplace.'
      }
    }
  }
}
