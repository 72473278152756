'use strict'

import { ProgressLine } from '@symbo.ls/ui'

export const GifWithProgress = {
  props: {
    boxSize: 'H+D G1',
    minWidth: 'G1',
    round: 'B+V1',
    overflow: 'hidden',
    position: 'relative'
  },

  Image: {
    props: {
      boxSize: '100%',
      background: 'black .65',
      '.isActive': { background: 'black' }
    }
  },

  ProgressLine: {
    extend: ProgressLine,
    props: {
      minWidth: 'calc(100% - 74px)',
      value: 0.7,
      position: 'absolute',
      height: 'W2',
      left: '50%',
      bottom: 'A2',
      transform: 'translate(-50%, -50%)',
      zIndex: '100',
      theme: 'primary @dark .inactive',
      background: 'white',
      '::-webkit-progress-bar': {
        theme: 'primary @dark .inactive'
      },
      '::-webkit-progress-value': {
        theme: 'primary',
        borderRadius: '2px'
      }
    }
  }
}
