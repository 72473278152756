'use strict'

export default {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  minHeight: '8em',
  boxSizing: 'borderBox',
  paddingRight: '3.2em',
  paddingLeft: '2.6em',
  label: {
    marginTop: '1em'

  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: '10em',
    'button:not(:last-child)': {
      marginRight: '2.6em'
    },
    button: {
      background: 'transparent',
      padding: '1em',
      borderRadius: '100%',
      // backgroundColor: 'rgba(255, 255, 255, .03)',
      svg: {
        width: '1.4em',
        height: '1.4em',
        opacity: '0.63'
      }
    }
  }
}

export const heading = {
  maxHeight: '5.2em',
  display: 'flex',
  flexFlow: 'column',
  caption: {
    opacity: '.7',
    // paddingLeft: 0,
    paddingRight: '1em'
  },
  span: {
    outline: 'none',
    borderBottom: '1px dashed rgba(255, 255, 255, 0.1)',
    ':empty::before': {
      content: 'attr(placeholder)',
      color: 'rgba(255, 255, 255, 0.35)'
    }
  }
}
