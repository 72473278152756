'use strict'

import { Button, SquareButton } from 'smbls'
import { ClickableItem } from '..'

export const ClickableButton = {
  tag: 'button',
  extend: [ClickableItem],
  props: {
    style: Button.props.style
  }
}

export const IconButton = {
  extend: [SquareButton, ClickableItem],
  props: { round: 'Z' }
}

export const CheckButton = {
  extend: [Button, ClickableItem],
  props: {
    icon: 'checkmark',
    text: 'Okay',
    padding: 'Z1 B1 Z1 B',
    round: 'B',
    flexAlign: 'center center',
    theme: 'quaternary',
    gap: 'X2'
  }
}
