'use strict'

import { Link, ClickableItem, TooltipParent, IconButton } from '@symbo.ls/ui'
import { setNavbarTooltipPosition } from './NavbarTooltip'
import { checkRouteModal } from '@symbo.ls/platform-utils'
import { router } from '@symbo.ls/router'

export const NavbarButton = {
  extend: [ClickableItem, Link, TooltipParent],

  props: ({ props }) => ({
    active: window.location.pathname.includes(props.href),
    isActive: window.location.pathname.includes(props.href),
    theme: null,
    position: 'relative'
  }),

  on: {
    update: (el, s) => {
      const active = window.location.pathname.includes(s.href)
      if (active) setNavbarTooltipPosition(el, s)
    },
    mouseover: (ev, el, s) => {
      if (!s.hover) {
        s.hover = true
        setNavbarTooltipPosition(el, s, { visible: true })
      }
    },
    mouseleave: (ev, el, s) => {
      if (s.hover) {
        s.hover = false
        const Tooltip = el.lookup('NavbarTooltip')
        if (Tooltip) {
          Tooltip.setProps({
            opacity: '0',
            visibility: 'hidden'
          })
        }
      }
    }
  }
}

export const NavbarBackButton = {
  extend: IconButton,
  props: {
    fontSize: 'A2',
    padding: 'Y2',
    theme: 'transparent',
    icon: 'arrow angle left',
    ':hover': { theme: 'quinary' }
  },

  on: {
    click: (ev, el, s) => {
      const referrer = new URL(window.history.state.prevUrl || document.referrer)
      if (!referrer.pathname) router('/', el.__ref.__root)

      const origin = window.location.origin
      const isModal = checkRouteModal(referrer.pathname, el)
      const isSame = referrer.pathname === window.location.pathname && referrer.origin === origin

      if (isSame) {
        window.history.go(-2)
      } else if (isModal) {
        window.history.go(-3)
      } else if (referrer.origin === origin) {
        window.history.back()
      } else {
        router('/', el.__ref.__root)
      }
    }
  }
}
