'use strict'

const props = ({ state }) => ({
  align: 'flex-start flex-start',
  flow: 'column',
  overflow: 'hidden',
  transition: 'max-height 150ms',
  minHeight: 'C2',
  maxHeight: state.__element.props.isActive ? 'none' : 'D',
  gap: 'Z',
  margin: '0 -Z',
  padding: '0 Z'
})

export const Values = {
  extend: 'Flex',

  props,

  $setStateCollection: ({ state }) => state.values,
  childExtend: {
    component: (el, state) => {
      const { type } = state
      const Extend = type.slice(0, 1).toUpperCase() + type.slice(1) + 'Box'
      return Extend
    },

    $setStateCollection: ({ state }) => state.args,
    childExtend: {
      on: {
        render: (element, state) => {
          element.update({
            props: {
              text: state.text,
              theme: state.type || 'default'
            }
          })
        }
      }
    }
  }
}
