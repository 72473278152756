'use strict'

import { IconText, ArticleMedium, Flex } from '.'

const props = {
  gap: 'A',
  alignItems: 'flex-start',

  iconText: {
    color: 'gray8',
    text: '11',
    flow: 'column',
    gap: 'Z',
    margin: 'V+V - - -',

    Icon: {
      name: 'arrowAngleUp',
      color: 'gray6'
    }
  },

  article: {
    gap: '0',
    title: {
      text: 'Flexbox in Editor',
      fontWeight: '700'
    },
    p: { text: 'by kiaynwang 3 hours ago ・ 49 commnts' }
  }

}

export const Feedback = {
  extend: Flex,
  props,

  iconText: { extend: IconText },
  article: { extend: ArticleMedium }
}
