'use strict'

import { WiderButton } from './Buttons'

const CodePreview = {
  props: {
    flex: 1,
    minWidth: 'G',
    maxHeight: '100%',
    overflow: 'auto',
    display: 'block',
    lineHeight: 1.8,
    // maxWidth: 'I_default',
    fontSize: '0.765em',
    fontFamily: 'Code',
    round: 'A1',
    height: 'max-content',
    transition: 'B padding',
    padding: 'A_default A1_default D_default',
    theme: 'common-card',

    '@dark': {
      style: {
        '& .token': {
          '&.keyword': { color: '#4f82ae' },
          '&.string': { color: '#c8749c' },
          '&.punctuation': { color: '#A3A3A8' },
          '&.function': { color: '#c7c981' },
          '&.property': { color: '#87b6cb' },
          '&.string-property': { color: '#C584C0' },
          '&.operator': { color: '#74b6d4' },
          '&.boolean': { color: '#589BD6' },
          '&.number': { color: '#B5CFA8' },
          '&.comment': { color: '#6A9A55' },
          '&.constant': { color: '#fff' }
        }
      }
    },
    '@light': {
      style: {
        '& .token': {
          '&.keyword': { color: '#1373c6' },
          '&.string': { color: '#e34c94' },
          '&.punctuation': { color: '#8c8ca6' },
          '&.function': { color: '#a59300' },
          '&.property': { color: '#348cb3' },
          '&.string-property': { color: '#cd4cc3' },
          '&.operator': { color: '#74b6d4' },
          '&.boolean': { color: '#589BD6' },
          '&.number': { color: '#B5CFA8' },
          '&.comment': { color: '#6A9A55' },
          '&.constant': { color: 'black' }
        }
      }
    }
  }
}

export const CodePreviewWithTitle = {
  tag: 'pre',

  props: {
    margin: '- 0 0',

    title: {
      fontFamily: 'smbls',
      text: 'Code Example',
      color: 'caption',
      margin: 'B2 0 0',
      fontSize: 'Z',
      padding: '0 0 Z1',
      fontWeight: '500',
      whiteSpace: 'nowrap'
    }
  },

  title: { tag: 'h5' },
  code: { extend: CodePreview }
}

export const CodeWithTitleAndButtons = {
  extend: CodePreviewWithTitle,

  props: {
    margin: '0 ',
    maxWidth: '50%',
    '@tabletS': { maxWidth: 'none' },

    buttons: {
      flexAlign: 'center flex-start',
      gridColumn: 'span 4',
      margin: '-C -X2 0',
      gap: 'X2',
      transition: 'B opacity'
    }
  },

  title: 'Code Example',
  code: {},

  buttons: {
    childExtend: {
      extend: WiderButton,
      props: {
        fontSize: 'A',
        transition: 'A',
        transitionProperty: 'background, color, border',
        theme: 'secondary-highlight'
      }
    }
  }
}
