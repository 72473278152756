'use strict'

import { RangeFieldWithTitleAndIcon, Flex } from '..'
import { BaseSizeTool, RatioTool } from './Tools'

export const SequenceSliders = {
  extend: Flex,

  props: {
    align: 'center flex-start',
    gap: 'C'
  },

  childExtend: RangeFieldWithTitleAndIcon,

  base: {
    extend: BaseSizeTool,
    state: 'base'
  },
  ratio: {
    extend: RatioTool,
    state: 'ratio'
  }
}
