'use strict'

import cmd from './cmd.svg'
import cmdAlt from './cmd-alt.svg'

import ctrlMac from './ctrl-mac.svg'
import ctrlMacAlt from './ctrl-mac-alt.svg'
import ctrlWin from './ctrl-win.svg'
import ctrlWinAlt from './ctrl-win-alt.svg'
import ctrlWinAlt2 from './ctrl-win-alt-2.svg'

import option from './option.svg'
import optionAlt from './option-alt.svg'

import shift from './shift.svg'
import shiftAlt from './shift-alt.svg'
import shiftAlt2 from './shift-alt-2.svg'

export {
  cmd,
  cmdAlt,
  ctrlMac,
  ctrlMacAlt,
  ctrlWin,
  ctrlWinAlt,
  ctrlWinAlt2,
  option,
  optionAlt,
  shift,
  shiftAlt,
  shiftAlt2
}
