'use strict'

import { Flex } from '@symbo.ls/ui'

import { Collaborate } from '../BannerShmaneri'

export const CollaborateAnyone = {
  tag: 'section',
  extend: Flex,
  props: {
    gap: 'D'
  },

  BannerShmaneri: { extend: Collaborate },

  AccessButtons: {
    props: {},
    ...[{
      props: { theme: 'tertiary' }
    }]
  }
}
