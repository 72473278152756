'use strict'

import { getActiveRoute } from '@symbo.ls/router'
import { Link } from '..'
import { Navbar } from './Navbar'

export const StateNavbar = {
  extend: Navbar,

  NavbarBackButton: {
    margin: '- B2 - -'
  },

  NavbarButtonSet: {
    props: {
      margin: '- auto - -'
    },

    childExtend: {
      extend: [Link]
    },

    0: {
      props: () => ({
        active: getActiveRoute(0) === '/state',
        title: 'State',
        href: '/state',
        icon: 'state'
      })
    },

    1: {
      props: () => ({
        opacity: 0.35,
        // pointerEvents: 'none',
        active: getActiveRoute(0) === '/data',
        title: 'Data (TBA)',
        // href: '/data',
        icon: 'database'
      })
    }
  },

  NavbarLanguageDir: {}
}
