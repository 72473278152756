'use strict'

import { Reuse } from './Reuse'

export const Collaborate = {
  extend: Reuse,
  props: {
    theme: 'transparent',
    border: 'solid, white .35',
    borderWidth: '1px',
    padding: 'E2 B1 B1 C'
  },

  H1: { text: 'Collaborate with anyone' },
  Content: {},
  Gif: null

}
