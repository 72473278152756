'use strict'

import { InputField } from './Input'

export const PasswordField = {
  extend: InputField,

  props: {
    position: 'relative',
    element: {
      type: 'password'
    }
  },

  title: {},
  element: {},
  IconButton: {
    position: 'absolute',
    bottom: 'Z',
    right: 'A2',
    icon: 'eyeOpen',
    padding: '0',
    boxSize: 'B B',
    theme: null,
    color: 'gray8',
    background: 'transparent'
  }
}
