'use strict'

import { Flex, IconButton } from '..'
import { NavbarButton } from './NavbarButton'

export const NavbarButtonSet = {
  extend: Flex,

  props: {
    gap: 'Y2',
    // background: 'gray2',
    // color: 'gray8',
    fontSize: 'A2',
    padding: 'W X2',
    width: 'fit-content',
    flow: 'row',
    justifyContent: 'space-between'
  },

  childExtend: {
    extend: [IconButton, NavbarButton],
    props: {
      padding: 'Y2',
      theme: 'transparent',
      ':hover': { theme: 'secondary' },
      ':active': { theme: 'secondary-highlight' }
    }
  }
}
