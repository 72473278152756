'use strict'

import { Link, Button, SectionHeader } from '..'

export const apps = {
  extend: SectionHeader,

  props: {
    flow: 'column',
    align: 'flex-start flex-start',
    gap: 'B2',
    padding: '0 A2 0 C2',
    width: 'G2',
    borderColor: 'gray3',
    borderStyle: 'solid',
    borderWidth: '0 0 0 1px',
    margin: '0',

    title: 'Get early access',
    p: 'We\'re kicking off invite-only. Get early signup to make it to the top.'
  },

  heading: {
    title: {},
    p: {}
  },

  button: {
    extend: [Link, Button],
    props: {
      margin: '0 0 0 -X',
      theme: 'quaternary',
      fontWeight: '500',
      text: 'Join up',
      href: '/waitlist'
    }
  },

  nav: null
}
