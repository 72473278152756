'use strict'

export const ANIMATION = {
  modalIn: {
    from: {
      transform: 'scale(0.95)',
      opacity: 0
    },
    to: {
      transform: '',
      opacity: 1
    }
  },
  modalOut: {
    to: {
      transform: 'scale(0.95)',
      opacity: 0
    }
  },

  fadeInUpShort: {
    from: {
      transform: 'translate3d(0, 6.5%, 0)',
      opacity: 0
    },
    to: {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1
    }
  },

  fadeInUp: {
    from: {
      transform: 'translate3d(0, 12.5%, 0)',
      opacity: 0
    },
    to: {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1
    }
  },

  fadeInLeft: {
    from: {
      transform: 'translate3d(12.5%, 0, 0)',
      opacity: 0
    },
    to: {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1
    }
  },

  fadeOutDown: {
    from: {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1
    },
    to: {
      transform: 'translate3d(0, 12.5%, 0)',
      opacity: 0
    }
  },

  fadeInUpLong: {
    from: {
      transform: 'translate3d(0, 45%, 0)',
      opacity: 0
    },
    to: {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1
    }
  },

  slideInOut: {
    '0%': {
      transform: 'translate3d(0, 50%, 0)',
      opacity: 0
    },
    '15%': {
      transform: 'translate3d(0, -50%, 0)',
      opacity: 1
    },
    '85%': {
      transform: 'translate3d(0, -50%, 0)',
      opacity: 1
    },
    '100%': {
      transform: 'translate3d(0, -150%, 0)',
      opacity: 0
    }
  },

  fadeIn: {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  },

  fadeOut: {
    from: {
      opacity: 1
    },
    to: {
      opacity: 0
    }
  },

  fadeInSlowly: {
    '0%': {
      opacity: 0
    },
    '35%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },

  fadeOutSlowly: {
    '0%': {
      opacity: 1
    },
    '75%': {
      opacity: 0.75
    },
    '100%': {
      opacity: 0
    }
  },

  overflowVisibleSlowly: {
    '0%': {
      overflow: 'hidden'
    },
    '35%': {
      overflow: 'hidden'
    },
    '100%': {
      overflow: 'visible'
    }
  },
  overflowHiddenSlowly: {
    '0%': {
      overflow: 'visible'
    },
    '75%': {
      overflow: 'hidden'
    },
    '100%': {
      overflow: 'hidden'
    }
  },

  scaleOutIn: {
    from: {
      transform: 'scale(1.2)',
      opacity: '0',
      visibility: 'hidden'
    },
    to: {
      transform: 'scale(1)',
      opacity: '1',
      visibility: 'visible'
    }
  },
  scaleInOut: {
    from: {
      transform: 'scale(1)',
      opacity: '1',
      visibility: 'visible'
    },
    to: {
      transform: 'scale(1.2)',
      opacity: '0',
      visibility: 'hidden'
    }
  }
}
