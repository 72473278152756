'use strict'

import { opacify, getRgbTone } from '@symbo.ls/scratch'

export const getColorValue = (value, el, s) => {
  const { COLOR, GRADIENT } = s.__root.DATA.designSystem
  if (!COLOR) return

  if (!value) return

  if (value.includes(' ')) {
    const [color, opacity, shade] = value.split(' ')
    const actualValue = COLOR[color]

    if (shade) {
      const opacified = opacify(actualValue, opacity)
      return `rgba(${getRgbTone(opacified, shade)}, ${opacity})`
    } else if (opacity) {
      return opacify(actualValue, opacity)
    }

    return actualValue
  } else {
    return COLOR[value] || GRADIENT[value]
  }
}
