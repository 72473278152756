'use strict'

import { Button } from 'smbls'

import { CommonFieldLike } from '.'

import { Flex, IconText, ClickableItem, IconButton, FocusableComponent } from '..'

export const SelectField = {
  extend: [FocusableComponent, Flex, ClickableItem],

  tag: 'button',

  props: (el, s) => ({
    theme: 'field',
    align: 'center space-between',
    padding: 'Y Y Y A',
    round: 'Z',
    gap: 'Y',
    tabindex: -1,
    title: {
      icon: null,
      align: 'center flex-start',
      padding: '0 B 0 0',
      gap: 'Y2',
      value: { text: s.value || s.defaultValue || 'default' }
    },
    buttons: {
      gap: 'W',
      align: 'center'
    }
  }),

  title: {
    extend: IconText,
    Icon: {},
    value: {}
  },

  buttons: {
    extend: Flex,

    childExtend: {
      extend: IconButton,
      props: {
        tabindex: -1,
        theme: 'field-highlight',
        round: 'Y1',
        padding: 'Y1'
      }
    },

    arrow: {
      props: {
        icon: 'arrow angle mirroring vertical'
      }
    }
  }
}

export const SelectField2 = {
  extend: CommonFieldLike,
  props: {
    element: {
      padding: '- - - A',
      justifyContent: 'space-between',
      round: 'Z',
      background: 'gray2',
      color: 'white',
      ':hover': { background: 'gray3' },
      title: { text: 'Design' },
      button: {
        icon: 'arrowAngleMirroringVertical',
        color: 'gray8',
        background: 'transparent',
        theme: null
      }
    }
  },

  title: {},
  element: {
    extend: [Button],
    title: {},
    button: { extend: IconButton }
  }
}

export const SelectFieldWithTitle = {
  extend: CommonFieldLike,
  title: {},
  element: { extend: SelectField }
}

export const SelectFieldWithIcon = {
  props: {
    flexAlign: 'center flex-start',
    gap: 'Y',
    width: '100%',
    Icon: {
      display: 'block',
      fontSize: 'A2',
      color: 'caption'
    },
    tool: {
      flex: 1
      // spacingRatio: 1.8
    }
  },
  Icon: {},
  tool: SelectField
}

export const SelectFieldWithTitleAndIcon = {
  extend: CommonFieldLike,
  title: {},
  element: SelectFieldWithIcon
}
