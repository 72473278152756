'use strict'

import { Flex, Grid, Icon } from '.'

import { SizeUnits } from './SizeUnits'

export const side = {
  extend: Flex,
  props: {
    align: 'center center',
    height: '100%'
  },
  size: { extend: SizeUnits }
}

export const content = {
  extend: Flex,
  props: {
    flow: 'column',
    align: 'center center',
    position: 'relative',
    height: 'C1',
    border: 'solid, yellow .15',
    borderWidth: '.8px',
    padding: '- - - Y',
    round: 'Y2',
    style: { border: '.5px solid rgba(139, 147, 92, .25)' },
    lineHeight: {
      position: 'absolute',
      alignSelf: 'flex-start',
      style: { borderLeft: '.8px solid rgba(58, 90, 127, .4)' },
      padding: '- - - Y2',
      height: '70%'
    }
  },

  lineHeight: {
    extend: Flex,
    props: {
      align: 'center center'
    },
    Icon: {
      extend: Icon,
      props: {
        icon: 'textLineHeight',
        color: '#3A5A7F'
      }
    }
  },

  size: {
    extend: SizeUnits,
    props: {
      letterValue: null,
      numberValue: {
        text: '140%',
        color: '#3A5A7F'
      }
    }
  }
}

const subGrid = {
  extend: Grid,
  props: {
    columns: `${70 / 16}em auto ${70 / 16}em`,
    rows: `${70 / 16}em auto ${70 / 16}em`,
    style: { border: '.5px solid rgba(139, 147, 92, .25)' },
    round: 'A',
    templateAreas: `"x1 t x2"
      "l content r"
      "x3 b x4"`,
    position: 'relative',
    childProps: {
      size: {
        numberValue: { color: '#8B935C' }
      }
    }
  },
  ...[
    {
      props: {
        boxSize: '100%'
      }
    },

    {
      props: {
        boxSize: '100%'

      }
    },

    {
      props: {
        boxSize: '100%'
      }
    },

    {
      props: {
        boxSize: '100%'
      }
    },

    {
      extend: Icon,
      props: {
        position: 'absolute',
        top: 'Y1',
        left: 'Y1',
        icon: 'paddingColored',
        fontSize: 'A',
        color: '#8B935C'
      }
    },

    {
      extend: Icon,
      props: {
        icon: 'marginColored',
        position: 'absolute',
        top: '-Z1',
        left: '-Z1',
        fontSize: 'A',
        color: 'yellow'
      }
    },

    {
      extend: side,
      props: {
        gridArea: 't',
        size: {
          numberValue: { text: '16' },
          letterValue: { text: 'A' }
        }
      }
    },

    {
      extend: side,
      props: { gridArea: 'r' },
      size: {
        state: {
          numberValue: '20',
          letterValue: 'B1'
        }
      }
    },
    {
      extend: content,
      props: { gridArea: 'content' }
    },
    {
      extend: side,
      props: {
        gridArea: 'b',
        size: {
          numberValue: { text: '16' },
          letterValue: { text: 'A' }
        }
      }
    },
    {
      extend: side,
      props: { gridArea: 'l' }
    }
  ]
}

export const BoxModel = {
  extend: Grid,
  props: ({ state }) => ({
    color: 'gray6',
    fontSize: 'Z',
    border: 'solid, yellow .25',
    borderWidth: '.5px',
    position: 'relative',
    round: 'A2',
    overflow: 'hidden',
    // width: 'fit-content',
    columns: `${70 / 16}em auto ${70 / 16}em`,
    rows: `${70 / 16}em auto ${70 / 16}em`,
    templateAreas: `"x1 t x2"
      "l content r"
      "x3 b x4"`,
    alignItems: 'center',
    textAlign: 'center',
    childProps: {
      cursor: 'pointer',
      // border: '2px solid yellow',
      // background: 'green',
      position: 'relative',
      size: {
        numberValue: { color: '#85684B' }
      }
    }
  }),

  ...[
    {
      props: { boxSize: '100%' },
      class: {
        show: (element, state) => state.activeTopSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' },
        show2: (element, state) => state.activeLeftSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' }
      }
    },

    {
      props: { boxSize: '100%' },
      class: {
        show: (element, state) => state.activeRightSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' },
        show2: (element, state) => state.activeTopSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' }
      }
    },

    {
      props: { boxSize: '100%' },
      class: {
        show: (element, state) => state.activeBottomSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' },
        show2: (element, state) => state.activeLeftSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' }
      }
    },

    {
      props: { boxSize: '100%' },
      class: {
        show: (element, state) => state.activeRightSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' },
        show2: (element, state) => state.activeBottomSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' }
      }
    },

    {
      extend: side,
      props: {
        gridArea: 't'
      },

      on: {
        click: (event, element, state) => {
          state.activeTopSize
            ? state.update({ activeTopSize: false })
            : state.update({ activeTopSize: true })
        }
      },
      class: {
        show: (element, state) => state.activeTopSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' }
      },

      size: {
        state: {
          letterValue: '',
          numberValue: ''
        }
      }
    },

    {
      extend: side,
      props: {
        gridArea: 'r',
        size: {
          letterValue: { text: 'C2' },
          numberValue: { text: '28' }
        }
      },

      on: {
        click: (event, element, state) => {
          state.activeRightSize
            ? state.update({ activeRightSize: false })
            : state.update({ activeRightSize: true })
        }
      },
      class: {
        show: (element, state) => state.activeRightSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' }
      }
    },

    {
      extend: subGrid,
      props: {
        gridArea: 'content'
      }
    },

    {
      extend: side,
      props: {
        gridArea: 'b'
      },

      on: {
        click: (event, element, state) => {
          state.activeBottomSize
            ? state.update({ activeBottomSize: false })
            : state.update({ activeBottomSize: true })
        }
      },
      class: {
        show: (element, state) => state.activeBottomSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' }
      },

      size: {
        state: {
          letterValue: '',
          numberValue: ''
        }
      }
    },

    {
      extend: side,
      props: {
        gridArea: 'l',
        size: {
          letterValue: { text: 'C2' },
          numberValue: { text: '28' }
        }
      },

      on: {
        click: (event, element, state) => {
          state.activeLeftSize
            ? state.update({ activeLeftSize: false })
            : state.update({ activeLeftSize: true })
        }
      },
      class: {
        show: (element, state) => state.activeLeftSize
          ? { background: 'rgba(197, 208, 138, .15)' }
          : { background: 'transparent' }
      }
    }
  ]
}
