'use strict'

import { Flex } from '@symbo.ls/ui'

export const SingleClick = {
  extend: Flex,
  props: {
    theme: 'access',
    width: '100%',
    round: 'A2',
    align: 'center space-between',
    padding: 'A C+W1 A C'
  },

  H1: {
    text: 'Customize in a single click',
    fontSize: 'I2',
    maxWidth: 'D2+A',
    lineHeight: '1em',
    fontWeight: '900',
    '-webkit-text-stroke': '1px gray',
    color: 'transparent'
  },
  Gif: {
    boxSize: 'G+Y H1',
    theme: 'secondary',
    round: 'A'
  }
}
