'use strict'

import { Link } from '@symbo.ls/ui'
import { getActiveRoute } from '@symbo.ls/router'
import { Navbar } from './Navbar'

export const LibraryNavbar = {
  extend: Navbar,

  if: ({ state }) => Object.keys(state.SCHEMA.components || {}).length,

  NavbarButtonSet: {
    props: {},

    // components: {
    //   props: {
    //     icon: 'plus',
    //     href: '/library/add-component'
    //   }
    // },

    library: {
      extend: [Link],
      props: ({ state }) => ({
        active: state.active || window.location.pathname === state.href,
        icon: state.icon,
        href: state.href || '/components'
      }),
      state: {
        active: getActiveRoute(0) === '/library' || getActiveRoute(0) === '/export',
        icon: 'library outline',
        href: '/library'
      }
    },

    components: {
      props: {
        icon: 'grid',
        href: '/components'
      }
    }
  },

  SearchComponents: {
    margin: '- auto - C1'
  },

  // NavbarComponents: {
  //   margin: '- auto - C1'
  //   // props: {},
  //   // add: {
  //   //   state: {
  //   //     icon: 'plus',
  //   //     href: '/components'
  //   //   }
  //   // }
  // },

  NavbarTheming: { margin: '0' }
}
