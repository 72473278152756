'use strict'

const props = {
  maxWidth: `${486 / 16}em`,
  position: 'relative',
  maxHeight: 'E',
  boxSizing: 'border-box',
  // background: 'gray2',
  // round: 'A',

  ':after, &:before': {
    content: '""',
    position: 'absolute',
    zIndex: '10',
    boxSize: '30px 100%',
    pointerEvents: 'none'
  },

  ':before': {
    bottom: '-10px',
    left: '50%',
    transform: 'translate3d(-50%, -20%, 0)',
    background: `linear-gradient(
      to top,
      var(--quaternary-dark-background) 0%,
      var(--quaternary-dark-background) 100%
    )`
  },

  ':after': {
    top: '0',
    left: '0',
    background: `linear-gradient(
      to bottom,
      var(--quaternary-dark-background) 0%,
      var(--quaternary-dark-background) 100%
    )`
  }
}

export const TextareaField = {
  props,

  Textarea: {
    props: {
      variant: 'simple',
      width: '100%',
      height: '100%',
      theme: 'field',
      padding: 'A B A A1',
      fontFamily: 'smbls',
      round: 'A',
      position: 'relative',
      '@mobileXS': { fontSize: 'Z2' },

      style: {
        overflowY: 'auto',
        resize: 'none',
        scrollBehavior: 'smooth',
        '::-webkit-scrollbar': { display: 'none' }
      }
    },
    '.simple': {
      props: {
        theme: 'field'
      }
    }
  }
}
