'use strict'

import { Grid } from '..'

import { activeRow } from './transitions'
import { init, click } from './on'

import { MoreDetails } from './MoreDetails'
import { Values } from './Values'
import { Buttons } from './Buttons'
import { Types } from './Types'

const Title = {
  props: {
    text: 'colorName',
    lineHeight: '1',

    '@dark': { color: 'gray11' },
    '@light': { color: 'gray2' }
  },
  text: ({ state }) => state.title
}

const Description = {
  props: {
    maxWidth: 'fit-content',
    gap: '1ch',
    padding: '- C - -',
    lineHeight: `${20 / 14}em`,

    '@dark': { color: 'gray8' },
    '@light': { color: 'gray6' }
  },
  childExtend: {
    tag: 'span',
    props: ({ state }) => ({
      text: state.text || state.value,
      color: state.color
    })
  },
  $setStateCollection: ({ state }) => state.description
}

const props = ({ state, key }) => {
  const activeFromUrlHash = window.location.hash === `#${state.title}-property`
  const isActive = (state.active || (state.active !== false && activeFromUrlHash))
  const isActiveRow = isActive ? { ...activeRow } : {
    margin: '0',
    cursor: 'default',
    background: 'transparent 0',
    '@dark': { background: 'transparent 0' },
    '@light': { background: 'transparent 0' }
  }
  return {
    isActive,
    columns: '1.65fr 1.8fr 4fr 7fr',
    minHeight: 'D1',
    fontWeight: '400',
    overflow: 'hidden',
    fontSize: 'Z2',
    textDecoration: 'none',
    round: '0',
    padding: 'A A1',

    ':not(:first-child)': {
      '@dark': { border: '--color-line-highlight-dark, solid' },
      '@light': { border: '--color-line-highlight-light, solid' },
      borderWidth: '1px 0 0'
    },

    transition: '100ms, 100ms, 350ms, 350ms',
    transitionProperty: 'background, opacity, margin, padding',
    opacity: '0.85',

    ...isActiveRow,
    ':focus': activeRow,
    ':hover': activeRow
  }
}

export const Property = {
  extend: Grid,
  props,

  tag: 'a',
  attr: { id: ({ state }) => state.title + '-property' },

  on: { init, click },

  title: Title,
  types: Types,
  values: Values,
  description: Description,
  moredetails: MoreDetails,
  buttons: Buttons
}

export const ModalProperty = {
  extend: Property,
  props: {
    columns: '5fr 7fr',
    moredetails: {
      columns: '5fr 7fr',
      gridColumn: 'span 2',
      minHeight: '0'
    },
    buttons: {
      gridColumn: 'span 2'
    }
  },

  title: null,
  types: null,
  values: {},
  description: {},
  moredetails: {
    libraryItem: {},
    properties: {
      propertyValues: null
    }
  }
}
