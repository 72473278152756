'use strict'

import githubOutline from './github-outline.svg'
import github from './github.svg'

import discord from './discord.svg'

import twitter from './twitter.svg'

import medium from './medium.svg'

import figma from './figma.svg'
import xd from './xd.svg'
import sketch from './sketch.svg'

import jsOutline from './js-outline.svg'
import js from './js.svg'
import tsOutline from './ts-outline.svg'
import ts from './ts.svg'

import angular from './angular.svg'
import html from './html.svg'
import openSource from './opens.svg'
import pdf from './pdf.svg'
import react from './react.svg'
import stackoverflow from './stackoverflow.svg'
import svelte from './svelte.svg'
import vscode from './vscode.svg'
import vue from './vue.svg'
import webComponents from './web-components.svg'
import domql from './domql.svg'
import elm from './elm.svg'
import jquery from './jquery.svg'
import wordpress from './wordpress.svg'

export {
  githubOutline,
  github,

  discord,
  twitter,
  medium,

  figma,
  xd,
  sketch,

  jsOutline,
  js,
  tsOutline,
  ts,
  angular,
  html,
  openSource,
  pdf,
  react,
  stackoverflow,
  svelte,
  vscode,
  vue,
  webComponents,
  domql,
  jquery,
  wordpress,
  elm
}
