'use strict'

const props = ({ state }) => ({
  isBase: state.index === 0,

  padding: 'Y2 Z2 Z',
  width: 'fit-content',
  height: 'E',
  flexFlow: 'column',
  gap: 'Y',
  flex: 1,

  '@dark': {
    border: 'gray2, solid',
    borderWidth: '0 0 0 1px'
  },
  '@light': {
    border: 'gray11, solid',
    borderWidth: '0 0 0 1px'
  },

  ':first-child': {
    padding: '- - - 0',
    borderWidth: '0'
  },

  $isBase: {
    round: 'Z',
    border: 'none',
    '@dark': { background: 'gray3' },
    '@light': { background: 'gray11 .65' },
    style: {
      '& + div': { border: '0' }
    }
  },

  letter: {
    text: 'X',
    '@dark': { color: 'gray11' },
    '@light': { color: 'gray3' }
  },

  ems: {
    text: '1.125em',
    fontSize: 'Y',
    '@dark': { color: 'gray9' },
    '@light': { color: 'gray4' }
  },

  px: {
    text: '11px',
    fontSize: 'Z',
    overflow: 'hidden',
    width: '97.5%',
    maxWidth: '100%',
    '@dark': { color: 'gray8' },
    '@light': { color: 'gray5' },
    style: {
      textOverflow: 'ellipsis',
      marginTop: 'auto'
    }
  }
})

export const NumericSequenceItem = {
  props,
  if: ({ state }) => (state.key && state.key.length) === 1,

  letter: ({ state }) => ({ text: state.key }),
  ems: ({ state }) => ({ text: ('' + state.scaling).slice(0, 5) + 'em' }),
  px: ({ state }) => ({ text: state.val + 'px' })
}

export const NumericSequence = {
  props: {
    flexFlow: 'row',
    margin: '0 0 D'
  },

  childExtend: NumericSequenceItem
}
