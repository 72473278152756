'use strict'

import { deepClone } from '@domql/utils'
import { Link } from 'smbls'

export const AddToLibrary = {
  props: ({ state }) => ({
    title: 'Add to Library',
    icon: 'plus',
    href: '/export/' + state.key
  }),
  on: {
    click: (ev, el, s) => {
      const componentKey = s.key
      const { DATA, COMPONENTS } = s.__root

      const isInLib = DATA.components[componentKey]
      const isInMarket = COMPONENTS.filter(v => v.key === componentKey)[0]

      const { component, ...schema } = s.parse()

      if (!isInLib || isInMarket) {
        s.rootUpdate({
          DATA: {
            components: {
              [componentKey]: {
                extend: deepClone(component)
              }
            }
          },
          SCHEMA: {
            components: {
              [componentKey]: deepClone(schema)
            }
          }
        }, { preventUpdate: true })
      }

      Link.on.click(ev, el, s)
    }
  }
}
