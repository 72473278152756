'use strict'

export const detailsShowState = {
  opacity: 1,
  transform: 'translate3d(0, 0, 0)',
  minHeight: 'F1',
  height: 'auto'
}

export const buttonsShowState = {
  opacity: '1',
  overflow: 'initial',
  transform: 'translate3d(0, 0, 0)',
  minHeight: 'B2'
}

export const detailsIintialState = {
  transition: 'B, B, C',
  transitionProperty: 'minHeight, transform, opacity',
  overflow: 'hidden',
  height: '0px',
  minHeight: '0',
  transform: 'translate3d(0, 20px, 0)',
  opacity: '0'
}

export const activeRow = {
  cursor: 'pointer',
  opacity: '1',
  margin: '0 -X',
  '@dark': { theme: 'common-card-interactive @dark :hover' },
  '@light': { theme: 'common-card-interactive @light :hover' }
}
