'use strict'

import { Property } from '.'

export const PropertyList = {
  key: 'list',

  props: {
    title: {
      text: 'Properties',
      margin: 'B2 0 0',
      fontSize: 'Z',
      padding: '0 0 Z1',
      fontWeight: '500',
      color: 'caption'
    },

    list: {
      overflow: 'hidden',
      round: 'A',
      padding: '0 X',
      margin: '0 -Z',
      theme: 'common-card'
    }
  },

  title: { tag: 'h5' },
  list: { childExtend: [Property] }
}
