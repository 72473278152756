'use strict'

import { Icon } from '..'

const props = {
  position: 'relative',
  Flex: {
    align: 'flex-start flex-start',
    gap: 'Z',
    padding: '0 0 0 Z2',
    fontSize: 'B',
    color: 'gray8'
  }
}

const setTooltipPosition = (el, s, options = {}) => {
  const key = parseInt(el.key)
  const rowWidth = `(var(--font-size-B) + var(--spacing-Z))`
  const translateX = `calc(${rowWidth} * ${key} - 50%)`
  const transform = `translate3d(${translateX}, 0, 0)`
  const Tooltip = el.lookup('Tooltip')
  if (Tooltip.setProps) {
    Tooltip.setProps({
      opacity: options.visible && '1',
      visibility: options.visible && 'visible',
      text: s.value,
      transform
    })
  }
}

const Tooltip = {
  props: {
    background: 'gray1 .95',
    position: 'absolute',
    pointerEvents: 'none',
    top: 'B1',
    left: 'B',
    shape: 'tooltip',
    shapeDirection: 'top',
    padding: 'X2 Z1',
    transform: 'translate3d(-50%, 0, 0)',
    opacity: '0',
    visibility: 'hidden',
    minWidth: 'none',
    transition: 'B defaultBezier',
    transitionProperty: 'opacity, visibility, transform',
    textAlign: 'center',
    zIndex: 999,
    lineHeight: 1
  },
  Title: null,
  P: null
}

export const Types = {
  props,
  Tooltip,

  Flex: {
    childExtend: {
      extend: Icon,
      props: ({ state }) => ({
        icon: `${state.value} outline`
      }),
      on: {
        mouseover: (ev, el, s) => {
          if (!s.hover) {
            s.hover = true
            setTooltipPosition(el, s, { visible: true })
          }
        },
        mouseleave: (ev, el, s) => {
          if (s.hover) {
            s.hover = false
            el.lookup('Tooltip').setProps({
              opacity: '0',
              visibility: 'hidden'
            })
          }
        }
      }
    },
    $setStateCollection: ({ state }) => state.types
  }
}
