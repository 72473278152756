'use strict'

import { CommonFieldLike } from '..'

export const SurveyLabel = {
  extend: CommonFieldLike,
  props: {
    element: {
      maxWidth: 'H'
    }
  }
}
