'use strict'

export * from './Hero'
export * from './DreamBuild'
export * from './FrontendBenefits'
export * from './CreateUniversalComs'
export * from './FeaturesSetApart'
export * from './ResponsiveDesign'
export * from './UnlimitedComponents'
export * from './CustomizeSingleClick'
export * from './ReuseProject'
export * from './CreateCMS'
export * from './CreateContent'
export * from './CollaborateAnyone'
export * from './OpenEcosystem'
export * from './Footer'
