'use strict'

import { CommonFieldLike } from '.'
import { RangeWithButtons } from '..'

export const RangeField = {
  extend: RangeWithButtons,
  props: {
    flexAlign: 'center space-between',
    padding: 'X2',
    round: 'Y2',
    gap: 'Y'
  },

  minus: {
    props: {
      round: 'Y1',
      padding: `${5 / 16}em`
    }
  },
  value: {
    tag: 'span',
    props: { fontSize: 'Z' }
  },
  input: {},
  plus: {
    props: {
      round: 'Y1',
      padding: `${5 / 16}em`
    }
  }
}

export const RangeFieldWithTitle = {
  extend: CommonFieldLike,
  tag: 'section',
  title: {},
  element: RangeField
}

export const RangeFieldWithIcon = {
  props: {
    gap: 'Y',
    flexAlign: 'center flex-start',
    Icon: {
      display: 'block',
      color: 'caption'
    }
  },
  Icon: {},
  tool: RangeField
}

export const RangeFieldWithTitleAndIcon = {
  extend: CommonFieldLike,
  tag: 'section',
  title: {},
  element: RangeFieldWithIcon
}
