'use strict'

export const Light = {
  extend: 'Pseudo',

  props: {
    mixBlendMode: 'color-dodge',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '650px',
    height: '650px',
    willChange: 'transform, opacity',
    zIndex: 70,
    pointerEvents: 'none',
    transition: 'transform ease-in 0ms',
    // background: 'radial-gradient(ellipse at center, rgba(230, 230, 230, 0.75) 0%, black 65%, rgba(0, 0, 0, 0) 100%)',

    ':after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%, -50%, 1px)',
      width: '12px',
      height: '12px',
      border: '12px solid rgba(255, 255, 255, 0.35)',
      borderRadius: '26px'
    },

    '@media only screen and (max-width: $mobile)': {
      ':after': {
        content: 'none'
      },

      i: {
        opacity: 0
      }
    }
  },

  I: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    background: '#D5D5D5',
    filter: 'blur(100px)',
    zIndex: 100,
    transition: 'transform ease-in 75ms',
    transform: 'translate3d(0, 0, 1px) scale(1)'
  }
}
