'use strict'

import { TooltipHidden } from '../'

export const NavbarTooltip = {
  extend: TooltipHidden,
  props: {
    top: '90%',
    left: '0',
    shapeDirection: 'top',
    padding: 'Y Z2',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    minWidth: 'fit-content',
    lineHeight: 1,
    description: '',
    P: { color: 'white', fontSize: 'Z2' }
  },
  Title: null
}

export const setNavbarTooltipPosition = (el, s, options = {}) => {
  const topPosition = el.node.getBoundingClientRect().left - 46 + 'px'
  const translateX = `calc(${topPosition} - 50%)`
  const transform = `translate3d(${translateX}, 0%, 0)`
  const Tooltip = el.lookup('NavbarTooltip')
  if (el.props.title && Tooltip) {
    Tooltip.setProps({
      opacity: options.visible || '1',
      visibility: options.visible || 'visible',
      description: el.props.title,
      transform
    })
  }
}
