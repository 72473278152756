'use strict'

import { Navbar } from './Navbar'

const NAV = [{
  props: {
    title: 'Colors',
    href: '/color',
    Icon: { name: 'color outline colored' }
  }
}, {
  props: {
    title: 'Typography',
    href: '/typography',
    Icon: { name: 'typography outline colored' },
    opacity: '.7'
  }
}, {
  props: {
    title: 'Spacing',
    href: '/space',
    Icon: { name: 'space outline colored' },
    opacity: '.7'
  }
}, {
  props: {
    title: 'Shapes',
    href: '/shape',
    Icon: { name: 'shape outline colored' }
  }
}, {
  props: {
    title: 'Icons',
    href: '/icons',
    Icon: { name: 'icons outline colored' }
  }
}, {
  props: {
    title: 'Fonts',
    href: '/font',
    Icon: { name: 'fontFace' },
    opacity: '.7'
  }
}, {
  props: {
    title: 'Responsive',
    href: '/media-query',
    Icon: { name: 'device mobile half fill' },
    opacity: '.7'
  }
// }, {
//   props: {
//     title: 'Timing',
//     href: '/timing',
//     Icon: { name: 'clock colored' }
//   }
// }, {
//   props: {
//     title: 'Sequence',
//     href: '/sequence',
//     Icon: { name: 'sequence outline colored' }
//   }
}]

export const DesignSystemNavbar = {
  extend: Navbar,

  // NavbarCodePreview: {},
  NavbarBackButton: {
    margin: '- B2 - -'
  },

  NavbarButtonSet: {
    props: {
      margin: '- auto - -',
      gap: 'A2'
    },
    ...NAV
  },

  SearchComponents: {
    if: () => !window.location.pathname.includes('icons'),
    props: {
      placeholder: 'Search Design System'
    }
  },
  // NavbarStudioPreview: {}

  NavbarTheming: {
    margin: '- - - B2'
  }
  // NavbarResponsive: {},
}
