'use strict'

export const NativeSelect = {
  tag: 'select',
  props: {
    background: 'black',
    color: 'white',
    width: 'F3',
    padding: 'Y B2 Y A',
    border: 'none',
    style: { outline: 'none' }
  },
  childExtend: {
    tag: 'option'
  },
  ...[
    { props: { text: 'about' } },
    { props: { text: 'contact' } },
    { props: { text: 'market research' } },
    { props: { text: 'Recruiting Partner' } },
    { props: { text: 'Recruiting' } },
    { props: { text: 'Recruiting' } },
    { props: { text: 'Refer a Friend' } }
  ]

}
