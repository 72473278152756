'use strict'

import { Grid, Flex } from "@symbo.ls/ui"


export const Responsive = {
  extend: Grid,
  props: {
    theme: 'yellow',
    width: '100%',
    columns: '2fr 1fr .7fr .5fr',
    height: 'G+C',
    round: 'A2',
    overflow: 'hidden',
    boxSizing: 'border-box',
    padding: '- C2 - C',
  },
  childExtend: {
    extend: Flex,
    props: {
      boxSizing: 'border-box',
      padding: 'A A B2 -',
      flow: 'column',
      border: 'dashed, grey',
      borderWidth: '0 1px 0 0',
      justifyContent: 'space-between'
    },
    Icon: {
      fontSize: 'B1',
      alignSelf: 'flex-end'
    }
  },
  ...[
    {
      Icon: { icon: 'menu'},
      Title: {
        tag: 'h1',
        props: {
          fontSize: 'I2',
          fontWeight: '100',
          lineHeight: '.98em'
        },
        ...[
          {
            props: {
              text: 'Responsive',
              fontWeight: '900',
              letterSpacing: '.02em'
            },
          },
          'by design'
        ]
      }
    },
    {
      Icon: { icon: 'menu'}
    },
    {
      Icon: { icon: 'menu'}
    },
    {
      Icon: { icon: 'menu'}
    }
  ]
}