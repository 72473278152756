'use strict'

import { ArticleLink } from '.'

export const Pagination = {
  props: {
    display: 'flex',
    flexAlign: 'center flex-start',
    padding: 'E 0 0'
  },

  state: {},

  childExtend: ArticleLink,

  ...[{
    if: ({ state }) => state.previous,
    props: ({ state }) => ({
      padding: 'Z Z2 Z C',
      flexAlign: 'flex-end flex-start',
      caption: { text: 'Previous' },
      href: state.previous.href,
      title: { text: state.previous.title }
    })
  }, {
    if: ({ state }) => state.next,
    props: ({ state }) => ({
      padding: 'Z Z2 Z C',
      margin: '0 A1 0 auto',
      flexAlign: 'flex-end flex-start',
      caption: { text: 'Next up' },
      href: state.next.href,
      title: { text: state.next.title }
    })
  }, {
    if: ({ state }) => state.moveOn,
    props: ({ state }) => ({
      padding: 'Z C Z Z2',
      caption: { text: 'Next Chapter' },
      href: state.moveOn.href,
      title: { text: state.moveOn.title }
    })
  }]
}
