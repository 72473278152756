'use strict'

import Landing from './Landing'

export default {
  '/': Landing,
  '/about': {
    H3: 'This is Symbols starter-kit',
    P: 'Lorem ipsum dolor sit amet',
    Link: {
      href: '/',
      text: 'Go Back'
    }
  }
}
