'use strict'

import { getActiveConfig } from '@symbo.ls/scratch'
import { Grid, Link, Pseudo, IconText, Flex } from 'smbls'
import { TypePreview, TypeRow, ClickableItem } from '..'

const SequenceExtend = {
  extend: Grid,
  props: { columns: '1fr', gap: 'B2' },
  childExtend: { extend: TypeRow }
}

export * from './generate'

export const TypeScaleSequence = {
  extend: [SequenceExtend, Pseudo],

  props: {
    position: 'relative',
    '::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '100%'
    },
    '@dark': {
      '::after': {
        background: 'gray1',
        boxShadow: 'gray1, 0 0px 50px 50px'
      }
    },
    '@light': {
      '::after': {
        background: 'gray15',
        boxShadow: 'gray15, 0 0px 50px 50px'
      }
    }
  },

  childExtend: {
    state: true,

    props: (el, s) => {
      // const CONFIG = getActiveConfig()
      // const { BREAKPOINTS } = CONFIG
      // const previewSize = BREAKPOINTS[s.parent.previewSize]

      return {
        alignItems: 'start',
        gap: 'A',
        flow: 'row',

        TypeSizeTitle: {
          width: 'F',
          flow: 'column',
          alignItems: 'flex-end',
          gap: 'X2'
        },
        TypePreview: {
          padding: '0 B',
          round: '0',
          theme: null,
          href: undefined,
          background: 'none',
          width: '100%',
          // maxWidth: previewSize ? '~' + previewSize + 'px' : '100%',

          // TODO: fix this
          '@dark': {
            border: 'solid, gray4',
            borderWidth: '0 0 0 1px'
          },
          '@light': {
            border: 'solid, gray11',
            borderWidth: '0 0 0 1px'
          },
          ':hover': { transform: 'none', background: 'transparent' },

          style: {
            '&, & *': {
              transition: '165ms cubic-bezier(.29,.67,.51,.97) font-size'
            }
          },

          demo: {
            fontSize: s.val + 'px',
            margin: '0',
            lineHeight: 1.15,
            transition: 'A font-size'
          }
        }
      }
    },

    TypeSizeTitle: {},
    TypePreview: {
      props: {},
      tag: 'div',
      description: null,
      caption: null
    }
  }
}

export const DocumentStylesSequence = {
  extend: SequenceExtend,

  childExtend: {
    TypePreview: {
      extend: [ClickableItem, TypePreview],
      props: ({ state }) => {
        const { value } = state
        const { font, fontFamily, fontWeight, lineHeight } = value
        const sequenceValue = state.sequenceValue.val
        const { previewSize } = state.__root

        const CONFIG = getActiveConfig()
        const { BREAKPOINTS } = CONFIG
        const maxWidth = BREAKPOINTS[previewSize]

        return {
          padding: 'A B',
          fontWeight: '400',
          href: `/typography/edit-style/${state.key}`,
          scrollToTop: false,
          maxWidth: maxWidth ? maxWidth + 'px' : '100%',
          demo: {
            font,
            fontFamily,
            fontWeight,
            lineHeight,
            fontSize: sequenceValue + 'px',
            margin: '0'
          },
          ':hover': {
            transform: 'scale(1.005)'
          }
        }
      },
      on: {
        click: (ev, el, s) => {
          Link.on.click(ev, el, s)
        }
      }
    },

    TypeSizeTitle: {
      props: {
        gap: 'B1',
        color: 'gray8'
      },

      childExtend: {
        extend: IconText,
        props: ({ state }) => ({
          lineHeight: '1',
          gap: 'Z',
          Icon: { name: state.icon, color: 'gray7' },
          text: state.text
        })
      },

      title: {
        props: {
          marginBottom: '0',
          gap: 'X'
        },

        text: ({ state }) => `<${state.letterKey || state.key}>`,

        Span: {
          color: 'gray7',
          opacity: '0.5',
          text: '・'
        },

        seqName: {
          text: ({ state }) => `${state.sequenceValue.key}`
        },
        icon: null
      },

      size: {
        extend: Flex,
        props: {
          gap: 'X'
        },
        Icon: {
          props: { icon: 'fontSize' }
        },
        px: {
          text: ({ state }) => `${state.sequenceValue.scaling}em`
        },
        Span: {
          color: 'gray7',
          opacity: '0.5',
          text: '・'
        },
        Em: {
          text: ({ state }) => `~${state.sequenceValue.val}px`
        }
      },

      caption: null,

      $setStateCollection: ({ state }) => state.labels
    }
  }
}
