'use strict'

import { CommonFieldLike } from './Common'
import { UploadIcon } from '..'

const props = {
  title: {
    text: 'Project icon',
    fontSize: 'Z1',
    padding: '- - - X2'
  },
  element: {
    position: 'relative',
    padding: 'B1 B',
    boxSize: 'fit-content',
    border: 'none',
    background: 'gray2',
    Icon: {
      boxSize: 'A2 ',
      opacity: '1',
      color: 'gray7'
    },
    P: {
      text: 'Drag and drop or click',
      maxWidth: 'E',
      textAlign: 'center',
      color: 'gray6',
      opacity: '1',
      fontSize: 'Z1'
    }
  }
}

export const UploadField = {
  extend: CommonFieldLike,
  props,

  title: {},
  element: {
    extend: UploadIcon,
    Icon: {},
    P: { Span: null }
  }
}
