'use strict'

import { Flex } from '@symbo.ls/ui'

import { GifWithProgress } from '../components'

export const CreateDesignSystem = {
  extend: Flex,
  props: {
    theme: 'access',
    width: '100%',
    padding: 'A1 - A1 C',
    round: 'A2',
    align: 'center space-between'
  },

  Flex: {
    props: {
      flow: 'column',
      justifyContent: 'space-between',
      gap: 'F+A',
      boxSizing: 'border-box'
    },
    NumbTitleSteps: { padding: 'A1 - - -' },
    Title: {
      tag: 'h1',
      extend: Flex,
      props: {
        fontSize: 'I2+X',
        flow: 'column',
        maxWidth: 'E1',
        fontWeight: '900',
        lineHeight: '1.1em',
        padding: '- - W2 -'
      },
      ...[
        {
          props: {
            text: 'Create',
            '-webkit-text-stroke': '1px gray',
            color: 'transparent'
          }
        },
        'Automated ',
        'Design System'
      ]
    }
  },

  Box: {
    props: {
      maxWidth: 'H1',
      overflow: 'hidden',
      position: 'relative',
      ':before': {
        content: '""',
        boxSize: '100% 100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'linear-gradient(to right, rgba(33, 39, 167, 1) 0%,rgba(33, 39, 167, 0) 100%)',
        zIndex: '100',
        pointerEvents: 'none'
      }
    },

    Flex: {
      props: {
        gap: 'A',
        justifyContent: 'flex-start',
        padding: '- A - -',
        overflowX: 'auto'
      },
      childExtend: GifWithProgress,
      ...[
        {},
        {},
        {},
        {}
      ]
    },

    ArrowButton: {
      Icon: { name: 'arrowRight' },
      position: 'absolute',
      zIndex: '3',
      top: '50%',
      right: 'A1',
      transform: 'translate(0%, -50%)'
    }
  }
}
