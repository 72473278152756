'use strict'

import { setCookie } from '@domql/cookie'
import { redirectBasedOnAuth } from '@symbo.ls/auth-redirect'
import { checkRouteModal } from '@symbo.ls/platform-utils'

export const getActiveRoute = (level = 0, route = window.location.pathname) => {
  const routeArray = route.split('/')
  const activeRoute = routeArray[level + 1]
  if (activeRoute) return `/${activeRoute}`
}

export let lastPathname
export let lastLevel = 0

const defaultOptions = {
  level: lastLevel,
  initialRender: false,
  pushState: true,
  scrollToTop: true,
  scrollToNode: false,
  scrollDocument: false,
  useFragment: false,
  authCheck: true,
  updateState: true,
  scrollToOffset: 120,
  scrollToOptions: { behavior: 'smooth' }
}

export const router = (
  path = '/',
  element,
  state = {},
  passedOptions = {}
) => {
  const options = { ...defaultOptions, ...passedOptions }
  lastLevel = options.level

  const [pathname, hash] = path.split('#')
  const rootRoute = getActiveRoute(0, path)
  let routeArray = pathname.split('/')

  if (options.authCheck) {
    const authRedirect = redirectBasedOnAuth(rootRoute, element, element.state)
    // console.log('authRedirect')
    // console.log(authRedirect)

    if (authRedirect && rootRoute !== authRedirect) {
      routeArray = authRedirect.split('/')
    }
  }

  const route = routeArray.join('/').replace('//', '/')
  const routeInContent = `/${routeArray[lastLevel + 1] || ''}`

  // console.log('rootRoute')
  // console.log(rootRoute)
  // console.log('route')
  // console.log(route)
  // console.log('routeInContent')
  // console.log(routeInContent)

  const content = element.routes[routeInContent] || element.routes['/*']

  const wasModal = checkRouteModal(pathname, element)
  const isInitial = options.initialRender
  const hashChanged = hash && hash !== window.location.hash.slice(1)
  const pathChanged = pathname !== lastPathname
  lastPathname = pathname

  if (!content) return
  if (options.pushState) {
    window.history.pushState({ prevUrl: window.location.href, ...state}, null, route + (hash ? `#${hash}` : ''))
  }

  const isModal = checkRouteModal(pathname, element)

  if (pathChanged || !hashChanged) {
    const hasSet = isInitial || (!isInitial && !isModal)
    // const rootRouteHasChanged = rootRoute !== element.state.rootRoute
    //  || (!rootRouteHasChanged && { preventUpdateTriggerStateUpdate: true, preventContentUpdate: true })

    if (options.updateState) {
      element.state.update({ route, hash, routeInContent }, { preventUpdateTriggerStateUpdate: true, preventContentUpdate: true })
    }

    if (hasSet) {
      element.set({
        tag: options.useFragment && 'fragment',
        extend: content
      }, (!isInitial && wasModal && { preventContentUpdate: true }))
    }
  }

  const rootNode = element.node
  const scrollNode = options.scrollDocument ? document.documentElement : rootNode
  if (options.scrollToTop) {
    scrollNode.scrollTo({
      ...(options.scrollToOptions || {}), top: 0, left: 0
    })
  }
  if (options.scrollToNode) {
    content.content.node.scrollTo({
      ...(options.scrollToOptions || {}), top: 0, left: 0
    })
  }

  if (hash) {
    const activeNode = document.getElementById(hash)
    if (activeNode) {
      const top = activeNode.getBoundingClientRect().top + rootNode.scrollTop - options.scrollToOffset || 0
      scrollNode.scrollTo({
        ...(options.scrollToOptions || {}), top, left: 0
      })
    }
  }

  if (route !== '/*') setCookie('route', route)
}
