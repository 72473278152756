'use strict'

import { Flex, MenuItem, ArticleSmall } from '.'

export const SectionHeader = {
  tag: 'header',
  extend: Flex,

  props: ({ state, props }) => ({
    gap: 'B',
    align: 'center space-between',
    flow: 'row',
    margin: '0 0 B1',

    title: state.title || 'Title',
    p: state.p || 'Paragraph',

    heading: {
      flow: 'column',
      flex: 1,
      title: { text: props.title || state.title },
      p: { text: props.p || state.p }
    },

    nav: {
      flow: 'row-reverse',
      fontSize: 'A2',
      gap: 'A'
    }
  }),

  heading: {
    extend: ArticleSmall,
    title: {
      if: ({ parent }) => parent.props.title
    }
  },

  nav: {
    extend: Flex,
    childExtend: {
      extend: MenuItem,
      props: ({ state }) => ({ scrollToTop: false, ...state })
    },

    $setPropsCollection: ({ state }) => state.nav,

    toggle: {
      if: ({ state }) => state[state.__element.key] || state.toggleShow !== undefined,
      props: ({ state }) => ({
        active: !state.toggleShow,
        Icon: { name: 'arrowAngle' + (state.toggleShow ? 'Up' : 'Down') }
      }),

      on: {
        click: (event, element, state) => {
          state.update({ toggleShow: !state.toggleShow })
        }
      }
    }
  }
}
