'use strict'

export const positions = {}

export const scrollStack = []

export const registerScrollListener = (el) => scrollStack.push(el)

function animateScroll () {
  scrollStack.forEach(v => v.on.scroll({}, v, v.state))
  window.requestAnimationFrame(animateScroll)
}

// Start the animation
window.requestAnimationFrame(animateScroll)

document.addEventListener('mousemove', (event) => {
  positions.mouseX = event.clientX
  positions.mouseY = event.clientY
})

// document.addEventListener('scroll', (ev) => {
//   scrollStack.forEach(v => v.on.scroll(ev, v, v.state))
// })
