'use strict'

import { DesignElement } from '.'

const COLOR_MAPING = {
  // theme: 'color',
  selector: 'default',
  'media-query': 'default'
}

const ICON_MAPING = {
  selector: 'click',
  'media-query': 'deviceMobile'
}

export const StateDesignElement = {
  extend: DesignElement,

  props: ({ state }) => ({
    href: `/docs/${state.categoryType}`,
    margin: 'B -Z2 -Z2',
    gap: 'X',
    maxWidth: 'F_default',
    style: { cursor: 'alias' },
    button: {
      theme: COLOR_MAPING[state.categoryType] || state.categoryType,
      Icon: { name: (ICON_MAPING[state.categoryType] || state.categoryType) + 'Outline' }
    }
  }),

  button: {},
  heading: {
    title: null,
    p: ({ state }) => {
      return {
        childExtend: { tag: 'span' },
        ...[
          'This property belongs to ',
          { text: state.category, props: { '@dark': { color: 'white' }, '@light': { color: 'black' } } },
          ' component'
        ]
      }
    }
  }
}
