'use strict'

import DEFAULT_CONFIG from '@symbo.ls/default-config'

import { deepMerge } from '@domql/utils'
import platformDesignSystem from '@symbo.ls/config'

const landingDesignSystem = {
  useDefaultConfig: true,

  RESET: {
    html: {
      overflow: 'hidden auto'
    }
  },

  COLOR: {
    red2: '#AA0021',
    red3: '#BC0025',
    darkBlue: '#2127A7',
    yellow2: '#E6DA59',
    seaBlue: '#56B8C1',
    mosqueGreen: '#064247',
    headline: ['black', 'white']
    // headline: ['--black 1', '--white 1']
  },

  THEME: {
    dialog: {
      '@dark': {
        background: 'white .1',
        color: 'white'
      }
    },

    yellow: {
      '@dark': {
        color: 'black',
        background: 'yellow2'
      }
    },
    seaBlue: {
      '@dark': {
        color: 'black',
        background: 'seaBlue'
      }
    },
    redAlert: {
      '@dark': {
        color: 'white',
        background: 'red2'
      }
    },
    access: {
      '@dark': {
        color: 'white',
        background: 'darkBlue'
      }
    },
    whiteBlack: {
      '@dark': {
        color: 'black',
        background: 'white'
      }
    },
    stroke: {
      '@dark': {
        color: 'transparent',
        '-webkit-text-stroke': '1px gray'
      }
    }
  }
}

deepMerge(landingDesignSystem, platformDesignSystem)
deepMerge(landingDesignSystem, DEFAULT_CONFIG)

export default landingDesignSystem
