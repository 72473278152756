'use strict'

import { Flex } from '@symbo.ls/ui'

import { Reuse } from '../BannerShmaneri'

export const ReuseProject = {
  tag: 'section',
  extend: Flex,
  props: {
    padding: 'E - E3 -'
  },

  BannerShmaneri: { extend: Reuse },

  Flex: {
    props: {
      width: '100%',
      align: 'flex-start space-between',
      padding: 'C2 B1 C B2+X'
    },

    SectionTitle: {
      props: { maxWidth: 'G2' },
      ...[
        {
          props: {
            text: 'Whether you are running a platform or an agency ',
            fontWeight: '700'
          }
        },
        'you can reuse existing resources to another projects by only changing configuration'
      ]
    },

    AccessButtons: {
      props: {},
      ...[{
        props: { theme: 'redAlert' }
      }]
    }
  },

  IconText: {
    props: {
      maxWidth: 'H',
      alignItems: 'flex-start',
      display: 'inline',
      alignSelf: 'flex-start',
      lineHeight: '1.3em',
      margin: '- - - B2+X',
      Icon: {
        name: 'info',
        color: 'red3',
        display: 'inline'

      },
      text: 'New styles are instantly applied to a component when you move to an another project'
    }
  }
}
