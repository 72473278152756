'use strict'

import { Flex } from 'smbls'

export const App = {
  extend: Flex,
  props: {
    theme: 'document',
    flow: 'column',

    content: {
      padding: '- B1'
    }
  },

  HeaderOfLanding: {
    position: 'sticky',
    theme: 'header',
    top: '0'
  }
}
