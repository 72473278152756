'use strict'
export const styleUploadSVG = {

  div: {
    background: 'rgba(255, 255, 255, .05)',
    display: 'flex',
    alignItems: 'center'
  },

  input: { display: 'none' }
}
