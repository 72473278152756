'use strict'

import { Flex } from '@symbo.ls/ui'
import { registerScrollListener } from '../functions/scroll'

export const CreateUniversalComs = {
  tag: 'section',
  extend: Flex,

  props: {
    margin: 'F+A -',
    gap: 'E',
    style: { maxWidth: 'none !important' }
  },

  TitleParagraph: {
    props: {
      alignItems: 'center',
      gap: 'D'
    },

    Title: {
      tag: 'h1',
      props: {
        text: 'Create universal web components and design systems',
        fontSize: 'J',
        textAlign: 'center',
        maxWidth: 'F2',
        theme: 'stroke',
        fontWeight: '900'
      }
    },
    Paragraph: {
      props: {
        text: 'Use components to build the next big thing from our marketplace or easily create your own.',
        color: 'grey',
        maxWidth: 'G2',
        textAlign: 'center'
      }
    }
  },

  Box: {
    props: ({ node }) => ({
      position: 'relative',
      minHeight: '65vh',
      boxSize: 'I 100%',
      color: 'title',
      fontSize: '0.5vmax',
      childProps: {
        position: 'absolute'
      }
    }),

    childExtend: {
      on: {
        init: registerScrollListener,
        scroll: (ev, el, s) => {
          const { parent, node } = el
          const parentTop = parent.node.getBoundingClientRect().top - 200
          const siblings = parent.node?.children
          const index = siblings && Array.from(siblings).indexOf(node) % 3 + 1
          const calcY = (parentTop / 5) * (index * 1.2) + 'px'
          el.node.style = `transform: translate3d(0, ${calcY}, 1px)`
          // el.update()
          // const { scrollY } = window
          // s.update({ scrollY })
        }
      }
    },

    LogIn: {
      top: '10%',
      left: '50%'
    },
    UploadModal: {
      top: '50%',
      left: '0%'
    },
    ChatUser: {
      top: '80%',
      left: '75%'
    },
    UserButtonSet: {
      top: '80%',
      left: '25%'
    },
    BalanceCard: {
      theme: 'dialog',
      top: '26%',
      left: '5%'
    },
    Field: {
      top: '35%',
      left: '25%'
    },
    Radio: {
      top: '10%',
      left: '15%'
    },
    UploadedProcess: {
      top: '28%',
      right: '2%'
    },
    UserMessage: {
      right: '-2%',
      top: '10%'
    },
    Search: {
      top: '8%',
      left: '15%'
    },
    UserWithLabel: {
      top: '11%',
      left: '34%'
    },
    Toggle: {
      top: '45%',
      right: '15%'
    },
    IcontextButton: {
      top: '12%',
      left: '5%'
    },

  }
}
