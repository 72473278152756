'use strict'

import { isArray, setCookie as setCookieUtil } from '@domql/utils'
import { ButtonOptions, ButtonOption as DefaultButtonOption } from '..'
import { SurveyOptionsOtherInput } from './SurveyOtherInput'

const defineOptionVars = (el, s) => {
  const { state, props, parent } = el
  const rootComp = parent.parent.parent
  const fieldKey = rootComp.key
  const key = fieldKey.includes('.') ? fieldKey.split('.')[1] : fieldKey
  const isDisabled = props.disabled
  const isLowerCase = props.lowercase
  const setCookie = rootComp.props.setCookie
  const isMultiple = rootComp.props.multiple
  const componentKey = !isLowerCase ? el.key : el.key.toLowerCase()
  const value = state.parent[key]
  const valueArray = isArray(value) ? !isLowerCase ? value : value.map(v => v.toLowerCase()) : null
  const isChosen = isMultiple && valueArray ? valueArray.includes(componentKey) : value === componentKey
  const isOther = componentKey === (isLowerCase ? 'other' : 'Other')
  return {
    isOther,
    isDisabled,
    isLowerCase,
    setCookie,
    rootComp,
    fieldKey,
    key,
    isMultiple,
    value,
    isChosen
  }
}

const IconicOption = {
  props: (el, s) => {
    const { isChosen } = defineOptionVars(el, s)
    return {
      theme: 'field',
      active: isChosen,
      border: '1px, solid, transparent',
      transition: 'A defaultBezier',
      transitionProperty: 'borderColor, opacity',
      '.disabled': {
        border: '1px, solid, transparent',
        opacity: '.25',
        pointerEvents: 'none',
        theme: null,
        ':hover': null
      },
      '!active': {
        opacity: '.65'
      },
      '.active': {
        '@dark': { borderColor: '--color-line-highlight-dark' },
        '@light': { borderColor: '--color-line-highlight-light' }
      }
    }
  },

  on: {
    click: (ev, el, s) => {
      const { isOther, isLowerCase, isDisabled, rootComp, key, isMultiple, value, isChosen, setCookie } = defineOptionVars(el, s)
      const { SurveyOptionsOtherInput } = rootComp

      if (isDisabled) return

      const componentKey = !isLowerCase ? el.key : el.key.toLowerCase()

      if (isMultiple) {
        if (isOther) {
          SurveyOptionsOtherInput.node.value = ''
          s.update({ other: !s.other })
        } else {
          if (isChosen) value.splice(value.indexOf(componentKey), 1)
          else if (isArray(value)) value.push(componentKey)
          else s.parent[key] = [componentKey]
          s.parent.update()
        }
      } else {
        if (isOther) {
          s.update({ other: !s.other }, { preventUpdate: true })
          s.parent.update({ [key]: s.other ? 'Other' : '' })
          rootComp.SurveyOptionsOtherInput.node.focus()
        } else {
          s.update({ other: false }, { preventUpdate: true })
          s.parent.update({ [key]: componentKey })
          if (setCookie) setCookieUtil(key, value)
        }
      }
    }
  }
}

const ButtonOption = {
  extend: [IconicOption, DefaultButtonOption],

  props: (el, s) => {
    const { key, isLowerCase } = defineOptionVars(el, s)
    const componentKey = !isLowerCase ? el.key : el.key.toLowerCase()
    const stateKey = !isLowerCase ? s.parent[key] : s.parent[key] && s.parent[key].toLowerCase()
    return {
      align: 'center flex-start',
      padding: 'Z1 A2',
      minWidth: 'fit-content',
      Icon: {
        opacity: stateKey === componentKey ? 1 : 0.35
      },
      '!active': {
        border: '1px, solid, transparent',
        opacity: '.65'
      },
      '.active': {
        border: '1px, solid, gray6'
      }
    }
  },

  on: IconicOption.on
}

export const SurveyIconicOptions = {
  extend: ['CommonSection', ButtonOptions],
  state: {},
  props: ({ state, props }) => ({
    element: {
      '.other': props.multiple || {
        transition: 'A defaultBezier opacity',
        opacity: '.5',
        ':hover': { opacity: 1 }
      }
    },
    options: {
      Other: {
        label: 'Please specify',
        boxSizing: 'content-box',
        gap: 'Y',
        background: 'transparent',
        border: '1px, solid, --theme-quaternary-dark-hover-background',
        width: 'fit-content',
        borderRadius: 'B',
        aspectRatio: 'auto',
        text: props.multiple ? 'Others' : 'Other',
        icon: !state.other ? 'plus' : 'minus'
      }
    }
  }),

  title: { },
  element: {
    content: ({ parent }) => ({ tag: 'fragment', ...parent.props.options })
  },

  '.iconic': {
    element: {
      childExtend: IconicOption
    }
  },

  SurveyOptionsOtherInput
}

export const SurveyButtonOptions = {
  extend: [SurveyIconicOptions],
  props: {
    variant: 'buttons',
    element: {
      margin: '- -Z',
      maxWidth: 'none',
      columns: 'repeat(3, 1fr)'
    },

    SurveyOptionsOtherInput: {
      width: 'calc(66% - var(--spacing-A) / 2)'
    }
  },
  title: {},
  '.buttons': {
    element: {
      extend: 'Grid',
      childExtend: ButtonOption
    }
  },
  Span: {
    if: ({ parent }) => parent.props.multiple,
    props: {
      margin: '-Z - Z',
      color: 'gray7',
      text: 'Select all that apply'
    }
  }
}
