'use strict'

import { Flex, Icon } from '.'

import { SizeUnits } from './SizeUnits'

const boxSizeItem = {
  extend: Flex,
  props: {
    padding: 'Z Y1',
    gap: 'C',
    Icon: {
      icon: 'gap',
      color: '#465D64',
      boxSize: '20px 20px'
    },
    unit: {
      numberValue: { color: '#465D64' }
    },
    range: {
      text: 'Min',
      fontSize: 'Y2',
      alignSelf: 'flex-end',
      margin: '- - -Y -'
    }
  },

  Icon: { extend: Icon },
  unit: { extend: SizeUnits },
  range: {}
}

export const BoxSize = {
  extend: Flex,
  props: {
    round: 'Z1',
    maxWidth: 'fit-content',
    border: '.7px dashed #232E31',
    items: {
      border: '1px solid #232E31',
      round: 'Z1',
      childProps: {
        ':first-child': {
          border: '.7px dashed #232E31',
          round: 'Z1'
        }
      }
    }
  },

  items: {
    extend: Flex,
    childExtend: boxSizeItem,
    ...[
      {
        Icon: {},
        unit: {
          state: {
            letterValue: '',
            numberValue: ''
          }
        }
      },
      {
        Icon: { props: { opacity: '0' } },
        unit: {
          state: {
            letterValue: '',
            numberValue: ''
          }
        },
        range: { props: { opacity: '0' } }
      }
    ]
  },

  item: {
    extend: boxSizeItem,
    props: {
      Icon: { opacity: '0' },
      range: { text: 'Max' }
    },
    Icon: {},
    unit: {
      state: {
        letterValue: '',
        numberValue: ''
      }
    }
  }
}
