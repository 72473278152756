'use strict'

import { TitleParagraph, IconText } from '@symbo.ls/ui'

export const IconTitleParagraph = {
  extend: TitleParagraph,
  props: {
    alignItems: 'flex-start'
  },
  Title: {
    tag: 'h6',
    extend: IconText,
    props: {
      fontSize: 'A1',
      gap: 'Z',
      color: 'white',
      Icon: { color: 'grey' }
    }
  },

  Paragraph: {
    props: {
      maxWidth: 'F1',
      margin: '- - - B+W',
      color: 'gray'
    }
  }
}
