'use strict'

import { isObject, isString } from '@domql/utils'
import { CommonFieldLike, Link, DropdownParent, SelectField, RangeField, Flex } from '..'

export const ColorSelect = {
  extend: SelectField,
  props: (el, s) => ({
    padding: 'Y Y Y Z',
    title: {
      width: '100%',
      box: {
        boxSize: 'B',
        background: s.value,
        round: 'Y'
      },
      value: {
        fontSize: 'Z1',
        text: s.value || 'inherit'
      }
    }
  }),

  title: {
    box: {},
    value: {}
  },

  buttons: {
    0: null,
    add: {
      extend: Link,
      props: () => ({
        icon: 'plus',
        href: window.location.pathname + '/add-color'
      })
    },
    arrow: {
      props: {
        icon: 'arrowAngleMirroringVertical',
        theme: 'transparent'
      }
    }
  }
}

export const ColorSelectWithTitle = {
  extend: CommonFieldLike,
  props: {},
  title: {},
  element: {
    extend: ColorSelect
  }
}

const ColorDropdownItem = {
  extend: ColorSelect,
  props: (el, s) => {
    const key = el.lookup('Dropdown').parent.parent.key
    const parentValue = isObject(s.parent.value) ? s.parent.value[key] : s.parent.value
    const [color] = isString(parentValue) ? parentValue.split(' ') : [parentValue]
    const currentColorActive = color === s.key
    return {
      align: 'center flex-start',
      // width: '100%',
      round: 'Z',
      padding: 'Y2 Z Y2',
      margin: '0 X',
      wrap: 'wrap',

      active: currentColorActive || (isObject(s.active) && Object.values(s.active).includes(true)) || s.active,
      '.active': { theme: 'field-highlight' },
      '!active': { theme: 'transparent' },

      title: {
        fontSize: 'Z2',
        padding: '0',
        flex: 1,
        value: { fontSize: 'A', text: s.key },
        box: { background: s.value }
      },

      buttons: {
        margin: '-X -X1 -X -',
        opacity: '0'
      },

      ':hover': {
        style: {
          '[buttons]': {
            opacity: '1'
          }
        }
      }
    }
  },
  caption: null,
  Icon: null,
  title: {
    box: {},
    value: {}
  },
  buttons: {
    attr: { buttons: true },
    0: null,
    add: null,
    arrow: null,
    childExtend: {
      props: (el, s) => ({
        active: s.active && s.active[el.key],
        '.active': { theme: 'field-highlight' }
      }),
      on: {
        click: (ev, el, s, ctx) => {
          ev.preventDefault()
          ev.stopPropagation()

          const activeObj = s.active
          s.update({ active: { [el.key]: activeObj ? !activeObj[el.key] : true } })
          el.parent.parent.ranges[el.key].update()
        }
      }
    },
    opacity: {
      props: {
        icon: 'colorOpacity'
      }
    },
    shade: {
      props: {
        icon: 'colorShades'
      }
    }
  },
  ranges: {
    extend: Flex,
    props: {
      minWidth: '100%',
      flow: 'column',
      gap: 'Z',
      flex: 1,
      margin: 'X2 -X2 - -X2',
      padding: 'X2',
      borderStyle: 'solid',
      borderWidth: '1px 0 0',
      borderColor: 'dim',

      ':empty': { display: 'none' }
    },
    on: {
      click: (ev, el, s, ctx) => {
        ev.preventDefault()
        ev.stopPropagation()
      }
    },
    opacity: {
      extend: RangeField,
      if: (el, s) => {
        const itemState = s.parent
        const scopeState = itemState.parent
        const key = el.lookup('Dropdown').parent.parent.key
        const value = scopeState.value[key]
        const [color, opacity] = isString(value) ? value.split(' ') : []
        const activatedFromItem = itemState.active && itemState.active['opacity']
        return activatedFromItem || (color === itemState.key && opacity)
      },
      state: (el) => {
        const itemState = el.parent.state
        const key = el.lookup('Dropdown').parent.parent.key
        const scopeState = itemState.parent
        const color = itemState.key
        const value = scopeState.value[key]
        const [selectedColor, opacity] = isString(value) ? value.split(' ') : []
        const currentColorActive = color === selectedColor
        return { value: currentColorActive ? (parseFloat(opacity) * 100) ?? 100 : 100 }
      },
      props: {
        padding: 'X 0 0',
        onInput: (ev, el, s) => {
          const itemState = s.parent
          const scopeState = itemState.parent
          const color = itemState.key
          const rangeValue = parseFloat(el.node.value)
          const key = el.lookup('Dropdown').parent.parent.key
          const [selectedColor, , shade] = scopeState.value[key].split(' ')
          const currentColorActive = color === selectedColor
          s.update({ value: parseInt(rangeValue) }, { preventDefault: true })
          scopeState.update({ value: { [key]: `${color} ${rangeValue / 100}${shade && currentColorActive ? (' ' + shade) : ''}` } })
        },
        onDecrease: (ev, el, s) => {
          const value = parseFloat(s.parent.value)
          if (value > el.props.min) {
            s.parent.update({ value: value - 1 })
          }
        },
        onIncrease: (ev, el, s) => {
          const value = parseFloat(s.parent.value)
          if (value < el.props.max) {
            s.parent.update({ value: value + 1 })
          }
        }
      }
    },
    shade: {
      extend: Flex,
      if: (el, s) => {
        const itemState = el.parent.state
        const scopeState = itemState.parent
        const key = el.lookup('Dropdown').parent.parent.key
        const value = scopeState.value[key]
        const [selectedColor, , shade] = isString(value) ? value.split(' ') : []
        const color = itemState.key
        const activatedFromItem = itemState.active && itemState.active['shade']
        const currentColorActive = color === selectedColor
        return activatedFromItem || (color === itemState.key && shade && currentColorActive)
      },
      state: (el, s) => {
        const itemState = el.parent.state
        const color = itemState.key
        const { COLOR } = itemState.__root.DATA.designSystem
        if (!COLOR) return
        const colorVal = COLOR[color]

        return { value: colorVal, key: color }
      },

      props: {
        flow: 'column',
        overflow: 'hidden',
        round: 'X2',
        childProps: {
          position: 'relative',
          flex: 1,
          seq: [6, 10, 16, 26, 42, 68, 110, 178],
          width: '100%',
          onChoose: (ev, el, s) => {
            const itemState = s.parent
            const key = el.lookup('Dropdown').parent.parent.key
            const scopeState = itemState.parent
            const value = scopeState.value[key]
            const color = itemState.key
            const [selectedColor, opacity] = isString(value) ? value.split(' ') : []
            const currentColorActive = color === selectedColor
            scopeState.update({ value: { [key]: `${color} ${currentColorActive ? opacity ?? 1 : 1} ${el.props.value}` } })
          },
          childProps: {
            height: 'C'
          }
        }
      },

      ColorShade_left: {
        flow: 'row',
        asc: '+'
      },
      ColorShade_right: {
        flow: 'row',
        asc: '-'
      }
    }
  }
}

export const ColorDropwdown = {
  DropdownHeader: { text: 'Colors' },
  Space: { height: 'X' },
  ListInDropdown: {
    childExtend: ColorDropdownItem,
    $setStateCollection: ({ state }) => {
      const { COLOR } = state.__root.DATA.designSystem
      if (!COLOR) return
      const colors = Object.keys(COLOR).map(value => ({ value: COLOR[value], key: value }))
      return [{ value: 'inherit', key: 'none' }].concat(colors).concat()
    }
  }
}

export const GradientDropwdown = {
  DropdownHeader: { text: 'Gradients' },
  Space: { height: 'X' },
  ListInDropdown: {
    childExtend: { ...ColorDropdownItem, buttons: null },
    $setStateCollection: ({ state }) => {
      const { GRADIENT } = state.__root.DATA.designSystem
      if (!GRADIENT) return
      const gradients = Object.keys(GRADIENT).map(value => ({ value: GRADIENT[value], key: value }))
      return gradients
    }
  }
}

export const ColorSelectDropdown = {
  extend: [ColorSelect, DropdownParent],
  Dropdown: {
    props: {
      top: '115%',
      minWidth: 'G',
      left: '0'
      // left: '-X2'
    },

    ColorDropwdown,
    Space: { height: 'X' },
    GradientDropwdown,

    on: {
      choose: (ev, el, s, ctx) => {
        s.update({ value: ctx.value })
      }
    }
  }
}

export const ColorSelectDropdownWithTitle = {
  extend: CommonFieldLike,
  props: {
    minWidth: 'F2',
    element: {
      width: '100%',
      dropdown: {
        width: '100%',
        top: '110%'
      }
    }
  },
  title: {},
  element: {
    extend: ColorSelectDropdown
    // on: {
    // render: (el) => {
    //   window.requestAnimationFrame(() => {
    //     el.set({ extend: ColorSelectDropdown })
    //   })
    // }
    // }
  }
}
