'use strict'

import { TooltipHidden } from 'smbls'

export const HeaderTooltip = {
  extend: TooltipHidden,
  props: {
    ignoreChildExtend: true,
    background: 'gray2',
    top: 'E+Z',
    left: '90%',
    shape: 'tooltip',
    shapeDirection: 'left',
    whiteSpace: 'nowrap',
    padding: 'Y1 Z2',
    textAlign: 'start',
    lineHeight: 1,
    minWidth: '0',
    description: '',

    P: { color: 'white', fontSize: 'Z2' }
  },
  Title: null
}

export const setHeaderTooltipPosition = (el, s, options = {}) => {
  const topPosition = el.node.getBoundingClientRect().top + 'px'
  const translateY = `calc(${topPosition} + 25%)`
  const transform = `translate3d(0, ${translateY}, 0)`
  const Tooltip = el.lookup('HeaderTooltip')
  Tooltip.setProps({
    opacity: options.visible || '1',
    visibility: options.visible || 'visible',
    top: '0',
    description: s.title,
    transform
  })
}
