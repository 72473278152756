'use strict'

import { Flex, Button } from "@symbo.ls/ui"

import { ArrowButton } from "../components"

import DASHBOARD_PNG from '../assets/dashboard.png'

export const WebsiteTypes = {
  extend: Flex,
  props: {
    width: '100%',
    flow: 'column',
    theme: 'access',
    round: 'A2',
    padding: 'D2 Y - Y',
    maxHeight: 'H3+C',
    overflow: 'hidden',
    position: 'relative',
    ':after': {
      content: '""',
      boxSize: 'E 100%',
      position: 'absolute',
      bottom: '0',
      left: '0',
      zIndex: '2',
      background: 'linear-gradient(to top, rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 0) 100%)',
    }
  },

  TitleParagraph: {
    props: {
      alignItems: 'center',
      gap: 'B+W'
    },
    Title: {
      tag: 'h1',
      props: {
        fontWeight: '100',
        display: 'inline',
        maxWidth: 'F2',
        textAlign: 'center',
        lineHeight: '1.2em',
        fontSize: 'H'
      },
      ...[
        'Create entirely',
        {
          props: {
            text: ' new ',
            fontWeight: '700',
            display: 'inline'
          }
        },
        'or build on',
        {
          props: {
            text: ' existing ',
            fontWeight: '700',
            display: 'inline'
          }
        },
        'websites'
      ]
    },
    Paragraph: {
      tag: 'p',
      props: {
        text: 'For any type of business or industry.',
        fontSize: 'A1',
        color: 'white',
        fontWeight: '100'
      }
    }
  },

  Flex: {
    props: {
      align: 'center space-between',
      padding: 'B2 B2 C1 B2'
    },
    leftArrow: {
      extend: ArrowButton,
      props: { Icon: { name: 'arrowLeft' } }
    },

    nav: {
      extend: Flex,
      props: {
        gap: 'C1+W'
      },
      childExtend: {
        extend: Button,
        props: {
          icon: 'arrowUpRight',
          theme: 'transparent',
          color: 'white',
          padding: '0',
          flow: 'row-reverse',
          gap: 'Y'
        }
      },
      ...[{
        props: { text: 'E-commerce'}
      }, {
        props: { text: 'Portfolio'}
      },{
        props: { text: 'Blog'}
      },{
        props: { text: 'Marketplace'}
      },{
        props: { text: 'Web 3'}
      },{
        props: { text: 'Saas'}
      }]
    },

    rightArrow: {
      extend: ArrowButton,
      props: { Icon: { name: 'arrowRight' }}
    }
  },

  Img: { src: DASHBOARD_PNG }
}