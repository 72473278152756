'use strict'

import { Flex } from 'smbls'

export const SequenceGraphLine = {
  props: ({ state }) => ({
    flexFlow: 'column',
    width: 'fit-content',
    gap: '10px',

    '@dark': { color: 'gray11' },
    '@light': { color: 'gray2' },

    letter: {
      fontWeight: state.index === 0 ? '600' : '400',
      text: state.key,

      '@dark': { color: 'gray10' },
      '@light': { color: 'gray4' }
    },
    line: {
      height: state.index === 0 ? '5px' : '2px',
      background: 'blue',
      borderRadius: '12px',
      width: state.val + 'px',
      transitin: 'width 50ms'
    },
    px: {
      fontSize: 'Y',
      margin: 'X 0 0',
      text: state.val + 'px',

      '@dark': { color: 'gray8' },
      '@light': { color: 'gray5' }
    }
  }),

  letter: {},
  line: {},
  px: {}
}

export const SequenceGraphDot = {
  props: {
    border: '1px, solid, blue',
    borderRadius: '100%',
    opacity: '.35',
    width: 0,
    height: 0,
    margin: 'X1 0 0'
  },
  tag: 'span',
  letter: '',
  line: '',
  px: ''
}

export const SequenceGraph = {
  extend: Flex,
  props: {
    gap: 'A2'
  },

  childExtend: {
    component: ({ state }) => {
      return (state.key && state.key.length) === 1
        ? 'SequenceGraphLine' : 'SequenceGraphDot'
    }
  }
}
