'use strict'

export const Paragraph = {
  tag: 'p',
  props: {
    color: 'gray9',
    margin: 'A 0',
    gap: 'X'
  },
  childExtend: {
    tag: 'span'
  }
}
