'use strict'

import spaceOutline from './spaces-outline.svg'
import space from './spaces.svg'

import borderWidth from './border-width.svg'

import paddingLeft from './padding-left.svg'
import paddingTop from './padding-top.svg'
import paddingRight from './padding-right.svg'
import paddingBottom from './padding-bottom.svg'

import paddingInlineStart from './padding-inline-start.svg'
import paddingInlineEnd from './padding-inline-end.svg'
import paddingBlockStart from './padding-block-start.svg'
import paddingBlockEnd from './padding-block-end.svg'

import round from './round.svg'
import roundEndStart from './round-end-start.svg'
import roundEndEnd from './round-end-end.svg'
import roundStartStart from './round-start-start.svg'
import roundStartEnd from './round-start-end.svg'

import paddingAlt from './padding-outline.svg'
import paddingColored from './padding-colored.svg'
import padding from './padding-alt-colored.svg'

import sequence from './sequence-rows.svg'
import sequenceColored from './sequence-rows-colored.svg'
import sequenceAlt from './sequence-columns.svg'

import dimensions from './dimensions-outline.svg'
import dimensionsColored from './dimensions-colored.svg'
import dimensionsAlt from './dimensions-alt-outline.svg'
import dimensionsAltColored from './dimensions-alt-colored.svg'

import gap from './gap-horizontal.svg'
import gapVertical from './gap-vertical.svg'

import height from './height-outline.svg'
import heightColored from './height-colored.svg'

import width from './width-outline.svg'
import widthColored from './width-colored.svg'

import marginAlt from './margin-outline.svg'
import marginColored from './margin-colored.svg'
import margin from './margin-alt-colored.svg'

import proportion from './proportion-outline.svg'
import proportionColored from './proportion-colored.svg'
import proportionAlt from './proportion-alt-outline.svg'
import proportionAltColored from './proportion-alt-colored.svg'

export {
  spaceOutline,
  space,
  borderWidth,

  padding,
  paddingColored,
  paddingAlt,

  paddingLeft,
  paddingTop,
  paddingRight,
  paddingBottom,

  sequence,
  sequenceColored,
  sequenceAlt,

  paddingInlineStart,
  paddingInlineEnd,
  paddingBlockStart,
  paddingBlockEnd,

  round,
  roundEndStart,
  roundEndEnd,
  roundStartStart,
  roundStartEnd,

  round as borderRadius,
  roundEndStart as borderRadiusEndStart,
  roundEndEnd as borderRadiusEndEnd,
  roundStartStart as borderRadiusStartStart,
  roundStartEnd as borderRadiusStartEnd,

  dimensions,
  dimensionsColored,
  dimensionsAlt,
  dimensionsAltColored,

  gap,
  gapVertical,

  height,
  heightColored,

  width,
  widthColored,

  margin,
  marginColored,
  marginAlt,

  proportion,
  proportionColored,
  proportionAlt,
  proportionAltColored
}
