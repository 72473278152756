'use strict'

import { Dropdown, ListInDropdown } from '../Dropdown'
// import ToolBar from '../ToolBar'

import {
// FONT_SIZES,
// DIRECTIONS,
// ARROW_ICONS,
// SPECIAL_ICONS,
// SHAPES,
  SPACINGS
} from './defaults'

// export const FontSize = {
//   extend: Dropdown,
//   header: { text: 'Base font size' },
//   list: { extend: List, ...FONT_SIZES }
// }

// export const Direction = {
//   extend: Dropdown,
//   header: { text: 'Direction' },
//   list: { extend: List, ...DIRECTIONS }
// }

// export const Icons = {
//   extend: Dropdown,
//   style: {
//     width: '32em',
//     height: '32em'
//   },
//   arrows: {
//     tag: 'fragment',
//     header: 'Arrows',
//     list: { extend: Grid, ...ARROW_ICONS }
//   },
//   special: {
//     tag: 'fragment',
//     header: 'Special',
//     list: { extend: Grid, ...SPECIAL_ICONS }
//   },
//   toolbar: { extend: ToolBar }
// }

// export const Shapes = {
//   extend: Dropdown,
//   header: 'Shapes',
//   style: { width: '32em' },
//   list: {
//     extend: Grid,
//     childExtend: {
//       _Icon: {},
//       theme: 'tooltipShape',
//       shape: el => el.value
//     },
//     ...SHAPES
//   },
//   toolbar: { extend: ToolBar }
// }

// // export const round = {
// // }

// // export const themes = {
// // }

const MAPPED_SPACINGS = SPACINGS.map(v => ({ props: v }))
export const SpacingDropdown = {
  extend: Dropdown,
  header: { text: 'Spacing' },
  list: { extend: ListInDropdown, ...MAPPED_SPACINGS }
}
