'use strict'

import { SelectFieldWithTitle, Link } from '.'

export const ThemingSelectField = {
  extend: SelectFieldWithTitle,
  props: { minWidth: 'F1' },
  title: {},
  element: {
    props: { width: '100%' },
    title: {
      box: {
        props: {
          boxSize: 'A2 A2',
          background: 'black',
          round: 'Y'
        }
      },
      text: 'pink'
    },
    buttons: [{
      extend: Link,
      props: {
        icon: 'plus',
        href: '/color/global-theme/dark/add-color'
      }
    },
    {
      props: {
        icon: 'arrowAngleMirroringVertical',
        theme: 'transparent'
      }
    }]
  }
}
