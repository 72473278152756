'use strict'

import { isArray, setCookie as setCookieUtil } from '@domql/utils'
import { Input } from '..'

export const SurveyOtherInput = {
  extend: Input,
  props: {
    placeholder: 'Please specify',
    width: '100%',
    maxWidth: 'H',
    theme: 'quaternary',
    margin: '- -Z',
    padding: 'Z1 A2',
    hide: true
  },
  on: {
    change: (ev, el, s) => {
      const { parent } = el
      const fieldKey = parent.key
      const key = fieldKey.includes('.') ? fieldKey.split('.')[1] : fieldKey
      s.update({ [key]: el.node.value })
    }
  }
}

export const SurveyOptionsOtherInput = {
  extend: SurveyOtherInput,
  props: ({ state, parent }) => ({
    placeholder: parent.props.multiple ? 'Please specify (seperated by comma)' : 'Please specify',
    width: '100%',
    maxWidth: 'H',
    theme: 'quaternary',
    margin: '- -Z',
    padding: 'Z1 A2',
    hide: !state.other
  }),
  on: {
    change: (ev, el, s) => {
      const { parent, node } = el
      const fieldKey = parent.key
      const key = fieldKey.includes('.') ? fieldKey.split('.')[1] : fieldKey
      const { multiple, setCookie } = parent.props
      const stateValue = s.parent[key]
      const value = node.value
      if (multiple) {
        const arrValue = value.split(',').map(v => v.trim())
        s.parent.update({
          [key]: isArray(stateValue) ? stateValue.concat(arrValue) : arrValue
        })
      } else {
        s.parent.update({ [key]: value })
        if (setCookie) setCookieUtil(key, value)
      }
    }
  }
}
