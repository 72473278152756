'use strict'

import colorInvert from './color-invert.svg'
import colorShades from './color-shades.svg'
import colorOpacity from './color-opacity.svg'

import colorAlt from './colors-alt.svg'
import color from './colors-fill.svg'
import colorColored from './colors-colored.svg'
import colorOutline from './colors-outline.svg'
import colorOutlineColored from './colors-outline-colored.svg'

import borderOpacity from './border-opacity.svg'
import borderProps from './border-props.svg'

export {
  colorInvert,
  colorShades,
  colorOpacity,

  colorAlt,
  colorColored,
  color,
  colorOutline,
  colorOutlineColored,

  borderOpacity,
  borderProps
}
