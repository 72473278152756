'use strict'

import { Flex } from 'smbls'
import { TestText, RangeFieldWithTitleAndIcon } from '..'

export const PreviewSettings = {
  extend: Flex,

  props: {
    align: 'center space-between',
    margin: 'C 0',
    tools: { gap: 'C' }
  },

  testText: { extend: TestText },

  tools: {
    extend: Flex,
    childExtend: RangeFieldWithTitleAndIcon
  }
}

export const TypeRow = {
  tag: 'section',
  extend: Flex,

  props: {
    flow: 'column',
    minWidth: '0',
    gap: 'Z2',

    TypeSizeTitle: {
      padding: '- - - Z'
    },

    TypePreview: {
      flex: 1,
      minWidth: '0',
      flow: 'column',
      align: 'flex-start space-between'
    }
  },

  TypeSizeTitle: {},
  TypePreview: {}
}

export * from './TypePreview'
export * from './TypeSizeTitle'
