
export const RESET = {
  html: {
    overflow: 'hidden',
    maxWidth: '100vw',
    minWidth: '320px',
    minHeight: '100vh',
    height: 'auto',
    transform: 'none'
  },
  body: {
    transform: 'none'
  }
}
