'use strict'

import { Notification } from 'smbls'

export const QuickTip = {
  extend: Notification,
  props: {
    display: 'inline-flex',
    cursor: 'default',
    theme: null,
    background: 'yellow .1',
    color: 'yellow 1 -35',
    align: 'flex-start',
    margin: '0 -X2',
    alignSelf: 'flex-start',
    minWidth: 'G',
    Icon: {
      opacity: '0.65',
      order: '-1',
      margin: 'W',
      icon: 'questionMark'
    },
    article: {
      title: {
        text: 'Spread across document'
      },
      p: {
        text: 'explanation simply deeply'
      }
    }
  }
}
