'use strict'

import { Flex, AvatarBundle } from '@symbo.ls/ui'
import { CreatingContent } from '../BannerShmaneri'

export const CreateContent = {
  tag: 'section',
  extend: Flex,
  props: {
    aling: 'flex-start space-between',
    padding: 'D - E -'
  },

  BannerShmaneri: {
    extend: CreatingContent

  },

  Grid: {
    props: {
      width: '100%',
      padding: 'C1 B 0 B2',
      columns: 'repeat(2, 1fr)',
      columnGap: 'E',
      rowGap: 'D',
      alignSelf: 'flex-start'
    },

    childExtend: {
      extend: Flex,
      props: {
        flow: 'column',
        gap: 'A1'
      },
      SectionTitle: {
        props: {
          fontSize: 'A1'
        }
      }
    },

    ...[
      {
        avatarBundle: {
          extend: AvatarBundle,
          props: {
            childProps: {
              boxSize: 'B+X1'

            }
          },
          ...[{}, {}, {}, {}]
        },

        SectionTitle: {
          ...[
            'Allow anyone to',
            {
              props: {
                text: ' easily and quickly change the app content ',
                fontWeight: '700'
              }
            },
            'without the assistance of a developer. Creating schema is as easy as using Google Forms. '
          ]
        }
      },

      {
        props: { margin: 'Z - - -', gap: 'A2' },
        Icon: { props: { name: 'menu' } },
        SectionTitle: {
          ...[
            {
              props: {
                text: 'Integrate your own Backend. ',
                fontWeight: '700'
              }
            },
            'Point your service URL and Symbols will keep it updated on every change. No extensive hooks and services needed.'
          ]
        }
      },

      {
        Icon: { props: { name: 'menu' } },
        SectionTitle: {
          ...[
            {
              props: {
                text: 'Avoid the hassle of having to use expensive CMS plugins ',
                fontWeight: '700'
              }
            },
            'that slow your website down, require frequent updates and cause security vulnerabilities.'
          ]
        }
      }
    ]
  }
}
