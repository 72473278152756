'use strict'

import { hexToRgba } from '@symbo.ls/scratch'

export const COLOR = {
  transparent: 'transparent',
  black: 'black',
  white: 'white',

  blue: '#2485F0',
  cerulean: '#00BCFF',
  indigo: '#50E1FF',
  green: '#59AC56',
  red: '#EB6650',
  yellow: '#E8CC56',
  orange: '#E98232',
  brown: '#7D6755',
  pink: '#EE90BD',
  purple: '#7345AF',
  phosphorus: '#BCD835',
  indigoblue: `#55B7D8`,
  milk: '#D8D8D8',
  grassgreen: '#6A9A55',

  gray: '#57575c',

  gray1: '#040404',
  gray2: '#141416',
  gray3: '#242428',
  gray4: '#34343a',
  gray5: '#45454b',
  gray6: '#55555d',
  gray7: '#65656f',
  gray8: '#757581',
  gray9: '#878791',
  gray10: '#9898a2',
  gray11: '#aaaab2',
  gray12: '#bcbcc2',
  gray13: '#ceced2',
  gray14: '#e0e0e2',
  gray15: '#f1f1f3',
  gray16: '#fafafb',

  color: '--pink .25',
  typography: '--indigoblue .25',
  space: '--blue .25',
  shape: '--purple .3',
  icons: '--brown .2',
  css: '--yellow .25',
  html: '--green .25',

  padding: '#C5D08A',
  margin: '#FACD9F',
  sizing: '#EAAC46',
  gap: '#CA3CE6',
  border: '#FEDC9B',

  title: ['--gray1 1', '--gray15 1'],
  caption: ['--gray3 1', '--gray13 1'],
  paragraph: ['--gray5 1', '--gray10 1'],
  dim: ['--gray10 1', '--gray6 1'],
  shadow: ['--gray14 1', '--gray3 1'],

  line: ['--gray13 1', '--gray2 1 +4'],
  'line-highlight': ['--gray13 1 +4', '--gray2 1 +4']
}

const dots = `radial-gradient(rgba(0,0,0,0.06) 10%, transparent 10%)` // eslint-disable-line

const transparentBg = `linear-gradient(45deg, #1c1c1f 25%, transparent 25%), linear-gradient(-45deg, #1c1c1f 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #1c1c1f 75%), linear-gradient(-45deg, transparent 75%, #1c1c1f 75%)` // eslint-disable-line

const THEME_GRADIENT = ['#5BC2E5', '#6D47A9', '#EE90BD', '#3477F4', '#5BC2E5'].map(v =>
  hexToRgba(v, 0.3)).join(', ')

const THEME_GRADIENT_DARK = ['#5BC2E5', '#6D47A9', '#EE90BD', '#3477F4', '#5BC2E5'].map(v =>
  hexToRgba(v, 0.65)).join(', ')

export const GRADIENT = {
  dotsDark: `radial-gradient(#252527 6%, transparent 6%)`,
  dotsLight: `radial-gradient(#d9d9db 6%, transparent 6%)`,

  radialGrad: `radial-gradient(#08080E00 0%, #0A0A0B 100%)`,

  transparentBg,

  'shadow-overlay': {
    '@dark': `linear-gradient(0deg, var(--color-gray1) 0%, transparent 100%)`,
    '@light': `linear-gradient(0deg, var(--color-white) 0%, transparent 100%)`
  },

  'button-gradient': 'linear-gradient(to top, rgba(50, 114, 184, .2), rgba(0, 121, 253, .27), rgba(0, 121, 253, .27))',
  'button-gradient-active': 'linear-gradient(to top, rgba(50, 114, 184, .25), rgba(0, 121, 253, .32), rgba(0, 121, 253, .32))',

  theme: `conic-gradient(${THEME_GRADIENT})`,
  themeDark: `conic-gradient(${THEME_GRADIENT_DARK})`,

  'gradient-dark': `linear-gradient(0deg,
    rgba(0,0,0,0.06) 0%,
    rgba(0,0,0,0.07) 100%
  )`,
  'gradient-light': `linear-gradient(
    0deg,
    rgba(255,255,255,0.05) 0%,
    rgba(255,255,255,0.06) 100%
  )`,
  'gradient-light-active': `linear-gradient(
    0deg,
    rgba(255,255,255,0.09) 0%,
    rgba(255,255,255,0.10) 100%
  )`,
  'gradient-colorful': `linear-gradient(60deg,
    #0E1766 0%,
    #2127A7 65%,
    #1A1F8E 100%
  )`,
  'gradient-colorful-active': `linear-gradient(60deg,
    #00A2E7 0%,
    #185DF3 25%,
    #1E54F0 32%,
    #8B4CCA 75%,
    #C66894 100%
  )`
}
