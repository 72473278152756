'use strict'

import { Flex, Grid } from '..'

export const RESPONSIVE_SIZEMAPS = {
  mobileM: {
    width: '348px'
  },
  tabletM: {
    width: '742px'
  },
  tabletL: {
    width: '998px'
  },
  screenM: {
    width: '1246px'
  },
  screenL: {
    width: '1552px'
  }
}

export const ResponsiveGrid = {
  extend: Grid,

  props: {
    minHeight: 'H',
    columns: '307px 249px 256px 393px 375px',
    justifyContent: 'end',
    borderStyle: 'solid',
    borderColor: 'shadow',
    borderWidth: '0 1px 0 0'
  },

  childExtend: {
    extend: Flex,

    props: {
      borderStyle: 'solid',
      borderColor: 'shadow',
      borderWidth: '0 0 0 1px',
      align: 'flex-end flex-start',
      padding: '0 0 0 A1'
    },

    ButtonTitle: {
      props: ({ state }) => ({
        height: 'auto',
        gap: 'X',
        text: state.key,

        IconButton: {
          padding: 'Y2',
          Icon: { name: state.icon + ' outline' }
        }
      }),
      on: {
        click: (ev, el, s) => {
          const { width } = RESPONSIVE_SIZEMAPS[s.key]
          const previewSize = width !== s.parent.previewSize ? width : '100%'
          s.parent.update({ previewSize })
        }
      }
    }
  },

  $setStateCollection: () => [{
    key: 'screenL',
    icon: 'deviceBigScreen'
  }, {
    key: 'screenM',
    icon: 'deviceSmallScreen'
  }, {
    key: 'tabletL',
    icon: 'deviceTabletLandscape'
  }, {
    key: 'tabletM',
    icon: 'deviceTabletPortrait'
  }, {
    key: 'mobileM',
    icon: 'deviceMobile'
  }]
}
