'use strict'

import { Reuse } from './Reuse'

export const CreatingContent = {
  extend: Reuse,
  props: {
    theme: 'whiteBlack',
    flow: 'column',
    gap: 'C2',
    padding: 'C B2 B2 B2',
    round: 'A2',
    '> *': { alignSelf: 'flex-start' }
  },

  H1: {
    text: 'Start creating your content',
    maxWidth: 'E2',
    color: 'black',
    lineHeight: '1.05em'
  },

  ParagraphButton: {
    fontSize: 'Z1',
    gap: 'B',
    P: {
      text: 'Not sure how?'
    },
    Button: {
      text: 'Tell us your case',
      color: 'black',
      textDecoration: 'none',
      flow: 'row-reverse',
      gap: 'Z',
      Icon: {
        name: 'arrowRight',
        display: 'block'
      }
    }
  },
  Gif: null
}
