'use strict'

import { Link, IconButton } from '.'

export const MenuItem = {
  extend: [Link, IconButton],
  props: ({ props, __ref }, s) => {
    let active
    const { __root } = __ref

    if (props.href) {
      active = __root.state && __root.state.activePage === props.href.slice(1)
    }

    return {
      round: 'Z',
      fontSize: 'A',
      active
    }
  }
}
