'use strict'

import { NavbarCodePreview, NavbarOnHover } from './Navbar'

export const CodeNavbar = {
  extend: NavbarOnHover,

  NavbarButtonSet: {
    props: {
      margin: '- - - auto'
    },

    close: {
      props: ({ state, key }) => ({
        icon: 'crossmark'
      }),
      on: NavbarCodePreview.cod.on
    }
  }
}
