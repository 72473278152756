'use strict'

import { Scene, SequenceSliders, ResponsiveToolBar, Button } from '..'
import { ResponsiveGrid, RESPONSIVE_SIZEMAPS } from '.'

const header = {
  props: {
    flexFlow: 'column',
    gap: 'A1',
    title: {
      text: 'Here is how you can control sizing in media queries only with ',
      color: 'gray7',
      margin: '0'
    },

    content: {
      flexAlign: 'center space-between'
    }
  },

  title: { extend: 'Paragraph' },

  content: {
    scaleTools: {
      extend: SequenceSliders,
      base: { caption: null },
      ratio: { caption: null }
    },

    screenButtons: {
      extend: ResponsiveToolBar,
      devices: {
        childExtend: {
          on: {
            click: (ev, el, s) => {
              const { width } = RESPONSIVE_SIZEMAPS[s.key]
              const previewSize = width !== s.parent.previewSize ? width : '100%'
              s.parent.update({ previewSize })
            }
          }
        }
      },
      threeDots: null
    }
  }
}

const exampleButton = {
  extend: Button,
  props: ({ state }) => ({
    text: 'Continue',
    background: 'rgba(0, 0, 0, 0)',
    color: 'currentColor',
    round: 'A2',
    border: '2px, solid, blue .2',
    transition: 'B padding defaultBezier',
    padding: state.previewSize === RESPONSIVE_SIZEMAPS.mobileM.width ? 'Z1 D' : 'Z1 E1'
  })
}

const scene = {
  extend: Scene,

  props: ({ state }) => ({
    width: '100%',
    maxWidth: state.previewSize || '100%',
    margin: '- auto',

    position: 'absolute',
    height: 'F2',
    top: '50%',
    right: 'Z2',
    transform: 'translate3d(0%, -55%, 0)',

    scene: {
      zIndex: '2'
    }
  }),

  scene: { exampleButton }
}

export const ResponsiveWidget = {
  state: {},

  props: {
    flexFlow: 'column',
    gap: 'C',
    margin: '0',

    content: { position: 'relative' }
  },

  header,

  content: {
    grid: ResponsiveGrid,
    scene
  }
}
