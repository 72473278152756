'use strict'

import { SelectField } from '..'
import { SurveyLabel } from './SurveyLabel'
import { SurveyOtherInput } from './SurveyOtherInput'

export const SurveySelect = {
  extend: SurveyLabel,
  props: {
    element: {
      padding: '0',
      round: 'C1',
      style: { width: '-webkit-fill-available' },
      tabIndex: -1,
      margin: '- -Z',
      buttons: {
        position: 'absolute',
        right: 'Z',
        pointerEvents: 'none'
      }
    }
  },
  title: { },
  element: {
    extend: SelectField,
    title: null,

    props: { width: '-webkit-fill-available' },

    Select: {
      props: ({ state }) => {
        return {
          outline: 'none',
          border: 'none',
          background: 'transparent',
          color: 'currentColor',
          padding: 'Z1 A2',
          round: 'C1',
          width: '100%',
          appearance: 'none'
        }
      },

      childExtend: {
        props: (el, s) => {
          const compRoot = el.parent.parent.parent.parent
          const fieldKey = compRoot.key
          const key = fieldKey.includes('.') ? fieldKey.split('.')[1] : fieldKey
          const value = el.text
          const stateValue = s[key]
          if (el.key === '0' && !stateValue) return { selected: true }
          return {
            selected: value === stateValue
          }
        }
      },

      $setCollection: ({ parent }) => {
        return parent.parent.props.options
      },

      on: {
        change: (ev, el, s) => {
          const compRoot = el.parent.parent
          const fieldKey = compRoot.key
          const key = fieldKey.includes('.') ? fieldKey.split('.')[1] : fieldKey
          const value = ev.target.value
          if (value === 'Other') {
            s[key] = 'Other'
            return compRoot.setProps({ SurveyOtherInput: { hide: !compRoot.SurveyOtherInput.props.hide } })
          }
          compRoot.setProps({ SurveyOtherInput: { hide: true } }, { preventUpdate: true })
          s.update({ [key]: ev.target.value })
        }
      }
    }
  },

  SurveyOtherInput: {
    extend: SurveyOtherInput,
    props: {
      margin: '0 -Z'
    }
  }
}
