'use strict'

import { Flex, ColorSelectDropdownWithTitle } from '../'
import { getColorValue } from '@symbo.ls/platform-utils'

export const GlobalThemeColorFields = {
  extend: Flex,

  props: {
    align: 'center flex-start',
    gap: 'A'
  },

  childExtend: {
    extend: ColorSelectDropdownWithTitle,

    props: { width: '100%' },

    element: {
      props: (el, s) => {
        const { COLOR } = s.__root.DATA.designSystem
        if (!COLOR) return

        const key = s.value[el.parent.key]
        if (!key) return

        const value = getColorValue(key, el, s)


        console.log(key)
        return {
          title: {
            box: {
              background: value || key || 'transparentBg',
              backgroundSize: '6px 6px'
            },
            value: { text: key || 'inherit' }
          },
          Dropdown: { maxHeight: 'G' }
        }
      },
      Dropdown: {
        on: {
          choose: (ev, el, s, ctx) => {
            const key = el.parent.parent.key
            const value = { [key]: ctx.state.key }
            s.update({ value })
          }
        }
      }
    }
  },

  background: {
    title: { text: 'Background color' },
    element: {}
  },

  color: {
    title: { text: 'Text color' },
    element: {}
  }
}
