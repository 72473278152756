'use strict'

import { Flex } from '@symbo.ls/ui'

import {
  Hero, DreamBuild, FrontendBenefits, CreateUniversalComs, FeaturesSetApart,
  ResponsiveDesign, UnlimitedComponents, CustomizeSingleClick, ReuseProject,
  CreateCMS, CreateContent, CollaborateAnyone, OpenEcosystem, Footer
} from '../sections'

export default {
  extend: Flex,

  props: {
    flex: '1',
    padding: 'C -',
    flow: 'column',
    width: '100%',
    margin: '0 auto',
    childProps: {
      maxWidth: '1920px',
      position: 'relative',
      boxSize: '100%',
      flow: 'column',
      alignItems: 'center',
      alignSelf: 'center'
    }
  },

  Hero,
  DreamBuild,
  FrontendBenefits,
  CreateUniversalComs,
  FeaturesSetApart,
  ResponsiveDesign,
  UnlimitedComponents,
  CustomizeSingleClick,
  ReuseProject,
  CreateCMS,
  CreateContent,
  CollaborateAnyone,
  OpenEcosystem,
  Footer
}
