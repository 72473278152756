'use strict'

export const Code = {
  extend: 'Code',

  if: ({ state }) => state.code,
  props: ({ state }) => ({
    maxWidth: 'fit-content',
    margin: '0',
    title: {
      text: 'code sample',
      fontSize: 'Y',
      margin: '0',
      textTransform: 'uppercase',

      '@dark': { color: 'gray7' },
      '@light': { color: 'gray8' }
    },
    code: {
      text: state.code,
      padding: 'A A1',
      margin: '0',
      fontSize: '0.775em',
      theme: 'common-card-child'
    },
    style: {
      '&:first-child': {
        h5: { paddingBlockStart: 0 }
      }
    }
  }),
  buttons: null
}
