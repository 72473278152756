'use strict'

import { Flex, Pseudo, ArticleMedium, QuickStartElement } from '.'

const header = {
  extend: ArticleMedium,
  props: {
    '@mobileS': { padding: '- - - A' },
    title: {
      fontWeight: '600',
      text: 'Quick Start'
    },

    p: { text: `Welcome to the Symbols documentation page. Here you'll learn everything to get your page up and running starting from the initial setup to the end production.`
    },

    p2: {
      display: 'none',
      color: 'gray7',
      margin: '0'
    }
  },

  title: {},
  p: {},
  p2: {
    tag: 'p',
    ...[
      'Welcome to the Symbols documentation page.',
      { text: `Here you'll learn everything…` }
    ]
  }
}

const steps = {
  props: {
    position: 'relative',
    sh: {
      position: 'absolute',
      width: '1px',
      height: '100%',
      top: '0',
      right: '0',
      display: 'none',
      '@tabletL': { display: 'block' },
      '@mobileS': { display: 'none' },

      '::after': {
        content: '""',
        position: 'sticky',
        display: 'block',
        width: 'C',
        height: '100%',
        top: '0',
        right: '0',
        margin: '- -C - -'
      },
      '@dark': {
        '::after': {
          background: 'gray1',
          boxShadow: 'gray1, 0 0 70px 50px'
        }
      },
      '@light': {
        '::after': {
          background: 'gray15',
          boxShadow: 'gray15, 0 0px 50px 50px'
        }
      }
    }
  },

  container: {
    extend: Flex,
    props: {
      margin: '0 -Z',
      gap: 'Z',
      position: 'relative',
      boxSize: '100%',
      '@tabletL': {
        padding: '0 C_default 0 0',
        style: { overflowX: 'auto' }
      },
      '@mobileS': {
        flexFlow: 'column',
        alignItems: 'center',
        padding: '0',
        margin: '0'
      }
    },

    childExtend: QuickStartElement,

    ...[{
      ArticleSmall: {
        title: { text: 'Install Symbols' },
        p: { text: 'Install globally from npm' }
      },
      QuickStartTerminal: {
        QuickStartNumber: { width: '.87ch', text: '1' },
        Terminal: {
          command: {
            padding: '0',
            whiteSpace: 'nowrap',
            text: 'npm i smbls -g'
          }
        }
      }
    }, {
      ArticleSmall: {
        title: { text: 'Initialize' },
        p: { text: 'Add smbls to your local repository' }
      },
      QuickStartTerminal: {
        QuickStartNumber: { width: '.87ch', text: '2' },
        Terminal: {
          command: {
            padding: '0',
            whiteSpace: 'nowrap',
            text: 'npx smbls init'
          }
        }
      }
    }, {
      ArticleSmall: {
        title: { text: 'Setup your global themes' },
        p: { text: 'Define document colors for your app' }
      },
      QuickStartCode: {
        QuickStartNumber: { text: '2' },
        Code: {
          code: {
            text: {
              react: `import { SymbolsProvider } from '@symbo.ls/react'
// <SymbolsProvider designSystem={{ color… }} />`,
              domql: `import { init } from 'smbls'
init({ color, theme, typography… })`
            }
          }
        }
      }
    }]
  },
  sh: { extend: Pseudo }
}

export const QuickStart = {
  extend: Flex,
  props: {
    flow: 'column',
    gap: 'B1',
    padding: '0 0 E1 0',
    '@tabletL': {
      padding: 'C2_default 0 E1 0'
    }
  },

  header,
  steps
}
