'use strict'

export const StatusIndicator = {
  props: {
    boxSize: 'Z+V',
    background: 'green2',
    round: '100%',
    border: 'solid, gray',
    borderWidth: '2px'
  }
}
