'use strict'

import click from './click-fill.svg'
import clickOutline from './click-outline.svg'

import hover from './hover-fill.svg'
import hoverOutline from './hover-outline.svg'

import focus from './focus-fill.svg'
import focusOutline from './focus-outline.svg'

import selection from './selection.svg'
import selectionAlt from './selection-alt.svg'

import drag from './drag-fill.svg'
import dragOutline from './drag-outline.svg'

import choose from './choose-fill.svg'
import chooseOutline from './choose-outline.svg'

import switching from './switch-fill.svg'
import switchingOutline from './switch-outline.svg'

import input from './input-fill.svg'
import inputOutline from './input-outline.svg'

import keyboard from './keyboard.svg'
import mouse from './mouse.svg'

import cursor from './cursor.svg'
import cursorOutline from './cursor-outline.svg'
import cursorPointer from './cursor-pointer.svg'

import notAllowed from './not-allowed.svg'

export {
  click,
  clickOutline,

  hover,
  hoverOutline,

  focus,
  focusOutline,

  drag,
  dragOutline,

  selection,
  selectionAlt,

  choose,
  chooseOutline,

  switching,
  switchingOutline,

  input,
  inputOutline,

  mouse,
  keyboard,

  cursor,
  cursorOutline,
  cursorPointer,

  notAllowed
}
