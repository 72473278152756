'use strict'

import { IconCommonButton } from '@symbo.ls/ui'

export const ArrowButton = {
  extend: IconCommonButton,
  props: {
    round: '100%',
    theme: 'transparent',
    background: 'white .15'
  }
}
