'use strict'

import { Dropdown, DropdownParent, ListInDropdown, WiderButton, IconCaption, PropsSearch } from '.'

// export const ColorDropwdown = {
//   extend: Dropdown,
//   props: { theme: 'modalBackground' },
//   text: 'Colors' },
//   list: {
//     extend: ListInDropdown
//   }
// }

const filter = {
  props: {
    flexAlign: 'center flex-start',
    gap: 'C'
  },
  childExtend: {
    extend: [DropdownParent, IconCaption],
    props: {
      flexFlow: 'row-reverse',
      lineHeight: '1',
      Icon: { name: 'arrowAngleDown' },

      '@dark': { color: 'gray6' },
      '@light': { color: 'gray8' }
    },

    dropdown: {
      extend: Dropdown,
      list: { extend: ListInDropdown },
      on: {
        choose: (el, state, ctx) => {
          // state.update({ categoryFilter: key })
        }
      }
    }
  },

  ...[
    // { name: { text: 'Filter by properties' } },
    // { name: { text: 'Property types' } }
  ].map(props => ({ props }))
}

const categoryFilter = {
  props: {
    flexAlign: 'flex-start center',
    gap: 'A',
    margin: '0 0 0 auto'
  },

  childExtend: {
    extend: WiderButton,
    on: {
      click: (event, { key, state }) => {
        state.update({ categoryFilter: key })
      }
    }
  },

  ...['color', 'space', 'shape', 'icons', 'typography'].map(v => ({
    key: v,
    props: ({ state }) => ({
      isActive: state.categoryFilter === v,
      isNotActive: state.categoryFilter !== v,
      transition: 'color 150ms, background 150ms',
      theme: null,

      $isActive: { theme: v },
      $isNotActive: {
        color: 'gray8',
        background: 'transparent 0'
      },

      border: `solid, 1px, ${v} .25`,
      icon: v + (state.categoryFilter === v ? '' : ' outline'),
      ':hover': { theme: v }
    })
  }))
}

export const SearchHeader = {
  props: {
    flexAlign: 'center flex-start',
    gap: 'C',
    padding: 'A A2',
    round: 'A',
    search: { fontSize: 'Z2' },
    filter: { fontSize: 'Z2' },
    categoryFilter: { fontSize: 'Z2' },

    '@dark': { background: 'gray2' },
    '@light': { background: 'white' }
  },
  search: { extend: PropsSearch },
  filter,
  categoryFilter
}
