'use strict'

import { debounceOnContext } from '@domql/utils'

const props = {
  width: 'G',
  fontSize: 'A',
  gap: 'Y',
  flexAlign: 'center flex-start',
  round: 'B',
  '@dark': { background: 'gray3' },
  '@light': { background: 'gray15' }
}

export const PropsSearch = {
  props,
  tag: 'label',

  Icon: {
    icon: 'search outline',
    color: 'gray7',
    margin: '0 -B 0 B',
    zIndex: '1'
  },
  Input: {
    props: {
      flex: '1',
      margin: '0 0 0 -A2',
      padding: 'Z1 B Z1 C1',
      fontSize: 'A',
      transition: 'color 250ms ease',

      theme: null,
      background: 'transparent 0',

      '@dark': {
        color: 'white',
        ':placeholder': { color: 'gray8' }
      },
      '@light': {
        color: 'gray2',
        ':placeholder': { color: 'gray6' }
      },

      border: 'none',
      outline: 'none',
      ':focus::placeholder': { color: 'transparent' }
    },
    attr: {
      type: 'search',
      placeholder: ({ parent }) => parent.props.placeholder || 'Search properties',
      autofocus: ({ parent }) => parent.props.autofocus || 'autofocus'
    },
    on: {
      input: (event, { node, state }) => {
        debounceOnContext(state, state.update, 150)({ searched: node.value })
      }
    }
  }
}
