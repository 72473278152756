'use strict'

import { Flex } from '..'

export const CommonFieldLike = {
  tag: 'label',
  extend: Flex,

  props: {
    flow: 'column',
    align: 'flex-start',
    gap: 'Y1',
    minWidth: 'F'
  },

  title: {
    props: {
      text: 'Field Title',
      color: 'caption',
      userSelect: 'none',
      whiteSpace: 'nowrap'
    }
  },
  element: {
    props: {
      width: '100%'
    }
  }
}

export const CommonSection = {
  extend: CommonFieldLike,
  tag: 'section'
}
