'use strict'

export const styleHeaderOfGuest = {
  borderBottom: 'none',
  h3: {
    textAlign: 'center',
    borderLeft: 'none',
    paddingRight: `${72 / 16}em`
  },
  'nav > a': {
    cursor: 'pointer'
  }
}

export const styleTabHeader = {
  alignItems: 'center',
  borderBottom: '1px solid rgba(255, 255, 255, .1)',
  'a, button': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
}

export default {
}
