'use strict'

import { objectToString } from '@domql/utils'
import { toDashCase } from '@symbo.ls/utils'
// import { convert } from 'domql-to-mitosis/src/convert'

export const stringifyProps = (props, indent = 2) => objectToString(props, indent)
export const replaceKeysWithAttr = str => str
  .replaceAll('": "', '="')
  .replaceAll('\n  "', ' ')
  .replaceAll('{ ', '\n  ')
  .replaceAll('}', '')
  .replaceAll(', ', '\n  ')

export const codify = (key, props, appKey) => ({
  react: `import { ${key} } from '@symbo.ls/react'

const Component = props => {
  return <${key} {...props} />
}

Component.defaultProps = ${stringifyProps(props, 0)}`,

  domql: `import { ${key} } from 'smbls'

const Component = {
  extend: ${key},
  props: ${stringifyProps(props, 1)}
}`,

  iframe: `<iframe
  title="${key}"
  style="border: 0; resize: both; padding: 0 10px 10px 0"
  src="https://symbo.ls/scene/${key}/fullscreen/${appKey || ''}" 
  referrerpolicy="no-referrer-when-downgrade"
></iframe>`,

  html: `<script src="smbls.js"></script>

<${key && toDashCase(key)} ${replaceKeysWithAttr(stringifyProps(props))}/>`
})
