'use strict'

export const ClickableItem = {
  props: ({ state }) => ({
    transition: 'B defaultBezier',
    transitionProperty: 'opacity, transform',
    theme: 'secondary',
    opacity: 0.85,

    ':hover': {
      opacity: 0.9,
      transform: 'scale(1.005)'
    },
    ':active': {
      opacity: 1,
      transform: 'scale(1.005)'
    },
    ':focus': {
      opacity: 1
    },
    '.active': {
      opacity: 1,
      theme: `secondary @${state.__root.globalTheme || 'dark'} .active`
    }
  })
}
