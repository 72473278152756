'use strict'

import { Flex, Grid } from "@symbo.ls/ui"

import { Responsive } from "../BannerShmaneri"

export const ResponsiveDesign = {
  tag: 'section',
  extend: Flex,
  props: {
    padding: 'D+X - E -'
  },

  BannerShmaneri: { extend: Responsive },

  Flex: {
    props: {
      width: '100%',
      align: 'flex-start space-between',
      padding: 'C3+W1 B1 C+W B2+X'
    },
    SectionTitle: {
      props: {
        alignSelf: 'flex-start',
        maxWidth: 'G2'
      },
      ...[
        {
          props: {
            text: 'Preview your websites or the smallest component on different devices. ',
            fontWeight: '700'
          }
        },
        'Save time guessing what they will look like on your mobile vs desktop screens.'
      ]
    },
    AccessButtons: {
      props: {},
      ...[{
        props: { theme: 'yellow'}
      }]
    }
  },

  Gifs: {
    extend: Grid,
    props: {
      boxSize: 'G 100%',
      columns: '1fr 0.5fr 1fr',
      gap: 'Z2',
      boxSizing: 'border-box',
      padding: '- B'

    },
    childExtend: {
      props: {
        theme: 'secondary',
        round: 'A'
      }
    },
    ...[{}, {}, {}]
  },

  SectionTitle: {
    props: {
      alignSelf: 'flex-start',
      margin: 'C - - A2'
    },
    ...[
      {
        props: {
          text: 'Type scale for typography and sizing ',
          fontWeight: '700'
        }
      },
      'with golden ratio consistent and fluid layouts.'
    ]
  },
}