'use strict'

export const OnHover = {
  props: {
    transition: 'defaultBezier, A',
    transitionProperties: 'transform',

    ':hover': {
      transform: 'scale(1.05)'
    }
  }
}
