'use strict'

import { CommonFieldLike } from '.'
import { Grid, Input } from '..'

const NUMBERS = [null, null, null, null, null, null]

export const AccessCodeField = {
  extend: CommonFieldLike,

  props: {
    element: {
      columns: 'repeat(6, 1fr)',
      width: '100%',
      height: `C`,
      gap: `X2`,

      childProps: {
        boxSize: 'C',
        required: true,
        round: 'Y2',
        minlength: 1,
        maxlength: 1,
        fontFamily: 'Code'
      },

      Input: {
        type: 'hidden',
        required: true,
        placeholder: '••••••',
        pattern: '[0-9]*',
        minlength: 6,
        maxlength: 6,
        round: 'Y2'
      }
    }
  },

  title: { text: 'Invitation code' },

  element: {
    extend: Grid,
    state: { value: NUMBERS },

    childExtend: {
      extend: Input,
      props: ({ key, state }) => ({
        value: state.value[key] || ''
      }),
      on: {
        keyup: (event, element, state) => {
          const { target, keyCode } = event
          const { value } = target
          const next = element.nextElement()
          const previous = element.previousElement()

          const isNumber = (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)
          const isBackspace = event.keyCode === 8 || event.keyCode === 46

          target.select()

          if (isNumber && value.length && next) next.node.focus()
          if ((!value.length || isBackspace) && previous) previous.node.focus()

          state.value[element.key] = value
          element.parent.Input.setProps({
            value: Object.values(state.value).join('')
          })
        },
        paste: (event, element, state) => {
          event.preventDefault()
          const paste = (event.clipboardData || window.clipboardData).getData('text')
          if (!paste) return
          const value = paste.split('')
          state.value = value
          state.update()
        }
      }
    },

    ...NUMBERS.map(v => ({})),

    Input: {}
  }
}
