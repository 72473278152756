'use strict'

export const SectionTitle = {
  tag: 'h4',
  props: {
    fontWeight: '100',
    maxWidth: 'G3+C2',
    childProps: {
      display: 'inline',
      color: 'white'
    }
  },
  childExtend: {
    text: ({ props }) => props.text || props.inheritedString
  },
  ...[{}, {}, {}, {}]
}
