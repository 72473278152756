'use strict'

import { removeFromArray } from '@domql/utils'

import {
  IconText,
  Flex,
  Link,
  SelectField
} from '.'

export const FontObject = {
  extend: [Flex, Link],

  props: ({ state }) => ({
    href: state.href,
    round: 'A',
    flow: 'column',
    alignItems: 'flex-start',
    padding: 'A B B',
    textDecoration: 'none',
    fontWeight: '400',
    theme: 'secondary',
    scrollToTop: false,

    title: {
      style: { fontFamily: `"${state.title}", sans-serif` }
    },

    p: {
      margin: '0',
      lineHeight: 1.35,
      fontSize: 'E'
    },

    description: {
      width: '100%',
      padding: 'B1 0 0',
      gap: 'A2',
      flexAlign: 'center flex-start'
    }
  }),

  title: {
    tag: 'h5',
    text: (el, s) => s.title
  },

  p: {
    text: (el, s) => s.parent.sampleText
  },

  description: {
    if: ({ state }) => state.labels,

    childExtend: {
      extend: IconText,
      props: ({ state }) => ({
        gap: 'Z',
        Icon: { name: state.icon, color: 'gray7' },
        text: state.text
      })
    },
    $setStateCollection: ({ state }) => state.labels
  }
}

export const FontFamilyObject = {
  extend: [Flex, Link],

  props: ({ state }) => ({
    gap: 'X2',
    flow: 'column',
    align: 'flex-start flex-start',
    padding: 'A B A2',
    round: 'Z1',
    fontWeight: '400',
    href: state.href,
    theme: 'secondary',
    scrollToTop: false,

    title: {
      fontFamily: state.title
    },

    p: {
      margin: '0',
      fontWeight: '400',
      color: 'gray7'
    }
  }),

  title: {
    tag: 'h3',
    text: (el, s) => s.title
  },
  p: {
    text: (el, s) => s.val.value && Object.values(s.val.value).join(', ')
  }
}

export const FontWeightObject = {
  extend: FontObject,
  tag: 'section',

  props: ({ state }) => ({
    minWidth: 'H1',
    theme: 'quaternary',
    gap: 'A',
    padding: 'A A1 Z2',

    p: {
      text: state.parent.sampleText,
      fontWeight: state.fontWeight,
      fontSize: 'B'
    },

    '.disabled': {
      opacity: '.5',
      background: 'transparent 0',
      ':hover': {
        opacity: '.5',
        background: 'transparent 0'
      }
    }
  }),

  p: {},

  title: null,
  description: null,

  tools: {
    extend: Flex,
    props: {
      width: '100%',
      align: 'flex-start stretch'
    },

    variable: {
      extend: Flex,
      if: ({ state }) => state.isVariableFont,
      props: ({ state }) => ({
        align: 'center space-between'
      }),
      RangeFieldWithIcon: {
        Icon: { name: 'fontVariable' }
      }
    },

    weightSelector: {
      extend: Flex,
      if: ({ state }) => !state.isVariableFont,
      props: ({ state }) => ({
        width: '100%',
        align: 'center space-between'
      }),

      SelectDropdown: {
        props: (el, s) => ({
          gap: '0',
          padding: '0',
          textAlign: 'center',
          justifyContent: 'space-between',
          theme: 'transparent',
          title: {
            value: { text: s.fontWeight },
            padding: '0'
          },
          buttons: {
            arrow: {
              theme: 'transparent'
            }
          }
        }),

        Dropdown: {
          props: {
            options: new Array(9).fill(0).map((_, i) => ({ key: i * 100 + 100 }))
          },
          on: {
            choose: (ev, el, s, ctx) => {
              s.update({ fontWeight: ctx.text })
            }
          }
        }
      },

      SwitchFieldWithCaption: {
        props: (el, s) => ({
          margin: '- X2 - auto',
          flexFlow: 'row-reverse',
          caption: { text: s.disabled ? 'Style is disabled' : 'Style is enabled' },
          onChange: (ev, el, s) => {
            ev.preventDefault()
            s.update({ disabled: !s.disabled })
          }
        }),
        Input: { attr: { checked: (el, s) => !s.disabled } }
      },

      'IconButton.trash': {
        props: ({ state }) => ({
          theme: 'transparent',
          padding: 'Y1',
          opacity: '.5',
          margin: '- -Y1 - -',
          icon: 'trash outline'
        }),
        on: {
          click: (ev, el, s) => {
            const key = el.parent.parent.parent.key
            const { fontWeight } = s
            const text = `Do you want to remove the${fontWeight ?? (' ' + fontWeight)} weight?`
            if (window.confirm(text)) {
              s.parent.apply(({ value }) => {
                removeFromArray(value, key)
              })
            }
          }
        }
      }
    }
  }
}

export const TypeHelperObject = {
  extend: [FontObject, Link],
  caption: null,
  props: {
    href: '/typography/add-template',
    flow: 'column',
    justifyContent: 'space-between',
    textDecoration: 'none',
    p: { fontWeight: 'bold' }
  },

  description: [{
    text: 'Futura'
  }, {
    props: { icon: 'fontSize' },
    Icon: {},
    text: '+5',
    span: {
      props: { padding: '0 0 0 X1' },
      style: { opacity: '.35' },
      text: '3.052em'
    }
  }, {
    props: {
      icon: 'fontWeight',
      text: '700'
    }
  }, {
    props: {
      icon: 'lineHeight',
      text: '140%'
    }
  }]
}

export const HTMLTagTypeObject = {
  extend: [Link, SelectField],
  props: ({ key }) => ({
    fontWeight: '400',
    justifyContent: 'space-between',
    svg: { color: 'rgba(255, 255, 255, .35)' },
    scrollToTop: false,
    href: '/typography/edit-style/' + key,
    title: { value: { text: `<${key}>` } },
    Icon: { name: 'plus' }
  }),

  title: {},
  buttons: {
    plus: { props: { icon: 'plus' } },
    arrow: null
  }
}
