'use strict'

import {
  CommonFieldLike,
  DropdownParent,
  SelectField
} from '..'

const Dropdown = {
  props: {
    top: '121%',
    minWidth: 'F3',
    left: '0'
  },

  DropdownHeader: {},

  ListInDropdown: {
    childExtend: {
      extend: [{
        props: (el, s) => ({
          align: 'center flex-start',
          text: s.key
        })
      }]
    },

    $setStateCollection: ({ parent, state }) => parent.props.options || state.list
  }
}

export const SelectDropdown = {
  extend: [SelectField, DropdownParent],
  Dropdown
}

export const SelectDropdownWithTitle = {
  extend: CommonFieldLike,
  props: {
    minWidth: 'F1',
    element: {
      width: '100%',
      dropdown: {
        width: '100%',
        top: '110%'
      }
    }
  },
  title: {},
  element: {
    extend: SelectDropdown
  }
}
