'use strict'

import AvenirLight from '@symbo.ls/fonts/49c5f3e1-5867-4b1d-a843-2d07fa60d85d.woff2'
import AvenirRegular from '@symbo.ls/fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2'
import AvenirMedium from '@symbo.ls/fonts/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2'
import AvenirDemiBold from '@symbo.ls/fonts/6afe4676-059a-4aa8-b891-29856bbcba22.woff2'
import AvenirBold from '@symbo.ls/fonts/14c73713-e4df-4dba-933b-057feeac8dd1.woff2'
import AvenirHeavy from '@symbo.ls/fonts/5c57b2e2-f641-421e-a95f-65fcb47e409a.woff2'

export const FONT = {
  smbls: [{
    url: AvenirLight,
    fontWeight: 300
  }, {
    url: AvenirRegular,
    fontWeight: 400
  }, {
    url: AvenirMedium,
    fontWeight: 500
  }, {
    url: AvenirDemiBold,
    fontWeight: 600
  }, {
    url: AvenirBold,
    fontWeight: 700
  }, {
    url: AvenirHeavy,
    fontWeight: 900
  }],
  smblsVariable: {
    url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/2729/AvenirNext_Variable.ttf'
  }
}

export const FONT_FAMILY = {
  smbls: {
    isDefault: true,
    value: ['"smblsVariable"', '"smbls"'],
    type: 'serif'
  },
  EditorFamily: {
    value: ['"Europa"'],
    type: 'serif'
  },
  Code: {
    value: ['"Droid Sans Mono for Powerline"'],
    type: 'monospace'
  }
}
