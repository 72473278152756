'use strict'

import { Flex, Grid } from '@symbo.ls/ui'
import { IconTitleParagraph, SectionTitle } from '../components'
import { CreateDesignSystem } from '../BannerShmaneri'

export const FeaturesSetApart = {
  tag: 'section',
  extend: Flex,
  props: {
    alignSelf: 'center'
  },

  TitleParagraph: {
    alignItems: 'center',
    gap: 'B',
    Title: {
      tag: 'h1',
      color: 'headline',
      text: 'Features that set you apart',
      fontSize: 'I'
    },
    Paragraph: {
      text: 'Get ahead today and create your unfair advantage, with these industry leading features that will significantly boost your productivity and overall profitability.',
      maxWidth: 'H',
      textAlign: 'center',
      color: 'caption'
    }
  },

  AccessButtons: { padding: 'C1 - E -' },

  BannerShmaneri: { extend: CreateDesignSystem },

  SectionTitle: {
    props: {
      alignSelf: 'flex-start',
      margin: 'C - B A2'
    },
    ...[
      {
        props: {
          text: 'Create the most advanced Design System ',
          fontWeight: '700'
        }
      },
      'that automates any and all design changes to your web projects'
    ]
  },

  Gifs: {
    extend: Grid,
    props: {
      boxSize: 'G 100%',
      columns: '1fr 0.5fr 1fr',
      gap: 'Z2',
      boxSizing: 'border-box',
      padding: '- B'

    },
    childExtend: {
      props: {
        theme: 'secondary',
        round: 'A'
      }
    },
    ...[{}, {}, {}]
  },

  Flex: {
    props: {
      width: '100%',
      boxSizing: 'border-box',
      padding: 'D1 B1 C1 B2+X',
      align: 'flex-start space-between'
    },
    SectionTitle: {
      props: {
        alignSelf: 'flex-start'
      },

      ...[
        {
          props: {
            text: 'Type scale for typography and sizing ',
            fontWeight: '700'
          }
        },
        'with golden ratio consistent and fluid layouts.'
      ]
    },
    AccessButtons: {}
  },

  Gifs2: {
    extend: Grid,
    props: {
      boxSize: 'G 100%',
      columns: '1fr 0.5fr 1fr',
      gap: 'Z2',
      boxSizing: 'border-box',
      padding: '- B'

    },
    childExtend: {
      props: {
        theme: 'secondary',
        round: 'A'
      }
    },
    ...[{}, {}, {}]
  },

  SectionTitle2: {
    extend: SectionTitle,
    props: {
      alignSelf: 'flex-start',
      padding: 'C1 - B2 A2'
    },
    ...[
      {
        props: {
          text: 'Automate any design change ',
          fontWeight: '700'
        }
      },
      'from the smallest button to a whole website'
    ]
  },

  Grid: {
    props: {
      width: '100%',
      columns: 'repeat(3, 1fr)',
      columnGap: 'D3',
      rowGap: 'C1',
      padding: '- - - B2+X'
    },
    childExtend: {
      extend: IconTitleParagraph
    },
    ...[{
      Title: {
        props: {
          icon: 'menu',
          text: 'Headless framework'
        }
      },
      Paragraph: {
        props: {
          text: 'Use your Design System in any framework or no-code builder of your choise'
        }
      }
    }, {
      Title: {
        Icon: { props: { icon: 'menu' } },
        text: 'Figma & Sketch integration',
        Label: {
          background: 'gray .5',
          round: 'C',
          text: 'Soon',
          fontSIze: 'Y1',
          padding: 'V Z1',
          fontWeight: '500'
        }
      },
      Paragraph: {
        props: {
          text: 'Get systems synchrnoize in your favorite tools'
        }
      }
    }, {
      Title: {
        props: {
          icon: 'menu',
          text: 'Automatic synchronisation'
        }
      },
      Paragraph: {
        props: {
          text: 'Apply your changes to your website offline and online using our cloud platform and tools'
        }
      }
    }, {
      Title: {
        props: {
          icon: 'menu',
          text: 'Responsive by design'
        }
      },
      Paragraph: {
        props: {
          text: 'Symbols automates the whole process based on parameters you have set on your project.'
        }
      }
    }, {
      Title: {
        props: {
          icon: 'menu',
          text: 'Preview and share'
        }
      },
      Paragraph: {
        props: {
          text: 'Preview your changes on different devices and share it across your team members'
        }
      }
    }, {
      Title: {
        props: {
          icon: 'menu',
          text: 'All-in-one tool'
        }
      },
      Paragraph: {
        props: {
          text: 'Replace your tools with single platform and remove setting up and integrations hassle'
        }
      }
    }]
  }
}
