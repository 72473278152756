'use strict'

import { Flex, IconButton, Avatar } from '..'

export const DragNdropUser = {
  extend: Flex,

  props: ({ state }) => ({
    flow: 'column',
    align: 'center center',
    gap: 'A',
    round: 'A',

    img: { boxSize: 'C C', src: state.src },
    buttons: {
      gap: 'Y',
      childProps: {
        padding: '0',
        boxSize: 'B B',
        background: 'gray4',
        round: 'Y1',
        color: 'gray7',
        style: { '> svg': { fontSize: `${14 / 16}em` } }
      }
    }
  }),

  img: { extend: Avatar },

  buttons: {
    extend: Flex,
    childExtend: IconButton,
    ...[
      { props: { icon: 'reload' } },
      { props: { icon: 'trash' } }
    ]
  }
}
