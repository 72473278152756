'use strict'

import { isObject } from '@domql/utils'
import { Flex, ClickableItem } from '..'
import { AddToLibrary } from './AddToLibrary'
import { renderStateComponent } from '@symbo.ls/platform-utils'

export const ComponentTemplate = {
  extend: [ClickableItem, Flex],

  props: ({ key, state }) => ({
    position: 'relative',
    opacity: 1,
    padding: 'E C',
    fontWeight: '400',
    borderRadius: 'Z2',
    align: 'center center',
    textDecoration: 'none',
    columnStart: `span ${isObject(state.settings) && state.settings.gridOptions.colspan}`,
    rowStart: `span ${isObject(state.settings) && state.settings.gridOptions.rowspan}`,
    height: state.settings.gridOptions.rowspan > 1 ? 'auto' : '0',
    animation: 'fadeIn',
    animationDuration: 'F',
    animationDelay: parseInt(key) * 35 + 'ms',
    theme: 'scene-preview-interactive @' + state.__root.sceneTheme,
    lazyLoad: true,

    ':hover': {
      transform: 'scale(1.005)',

      '& > span': {
        transform: 'translate3d(0, 0, 0)',
        opacity: 1
      }
    },
    style: {
      '*:not(> span)': {
        pointerEvents: 'none !important'
      }
    }
  }),

  childExtendRecursive: {
    attr: { tabIndex: '-1' }
  },

  cnt: ({ state }) => ({
    extend: state.component,
    props: {
      position: 'relative'
    }
  }),

  Link: ({ state }) => ({
    href: `/component/${state.key}`,
    position: 'absolute',
    inset: '0 0 0 0',
    zIndex: '1'
  })
}

const intersectionObserverToLoadComponent = (el, opts) => {
  el.node.onload = () => {
    setTimeout(() => {
      (new window.IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            renderStateComponent(el.state.component, el, el.state, opts)
          }
        })
      })).observe(el.node)
    }, parseInt(el.parent.key) + 50)
  }
}

export const DefaultComponentTemplate = {
  extend: ComponentTemplate,

  props: ({ state }) => ({
    href: `/component/${state.key}`,
    padding: 'C',
    position: 'relative',
    overflow: 'hidden',
    minHeight: (isObject(state.settings) && state.settings.minHeight) || 'G1',
    gridColumnStart: `span ${isObject(state.settings) && state.settings.gridOptions.colspan}`,
    gridRowStart: `span ${isObject(state.settings) && state.settings.gridOptions.rowspan}`,
    lazyLoad: true
  }),

  cnt: null,

  Iframe: {
    props: {
      lazyLoad: true,
      inset: '0 0 0 0',
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      border: '0'
    },

    data: {},

    on: {
      render: (el, s) => {
        intersectionObserverToLoadComponent(el, {
          useLibrary: false,
          frameProps: {
            align: 'center center',
            position: 'absolute',
            inset: '0 0 0 0',
            boxSize: '100%'
          }
        })
      }
    }
  },

  Flex: {
    tag: 'span',
    props: {
      background: 'shadow-overlay',
      position: 'absolute',
      inset: 'auto 0 0 0',
      transform: 'translate3d(0, 100%, 0)',
      opacity: 0,
      transition: 'A2 defaultBezier',
      transitionProperty: 'transform, opacity',
      padding: 'A A A B',
      textAlign: 'center',
      align: 'center space-between',
      zIndex: '2'
    },

    text: ({ state }) => state.title,

    NavbarButtonSet: {
      props: { background: 'none' },

      ...[
        AddToLibrary,
        {
          props: ({ state }) => ({
            title: 'Magic Remix',
            icon: 'magicstar outline',
            href: '/preview/' + state.key
          }),
          on: {
            click: (ev, el, s) => {
              ev.preventDefault()
              const frameEl = el.lookup('Iframe')
              frameEl.data.frameelem.remove()
              frameEl.data.frameelem = null
              el.setProps({ opacity: '0.35', pointerEvents: 'none' })
              renderStateComponent(frameEl, s, {
                useLibrary: true,
                frameProps: {
                  align: 'center center',
                  position: 'absolute',
                  inset: '0 0 0 0',
                  boxSize: '100%',
                  padding: 'C'
                }
              })
              el.parent.parent.parent.setProps({ padding: '0' })
              // return false
            }
          }
        // }, {
        //   extend: [Link],
        //   props: ({ state }) => ({
        //     title: 'Editor',
        //     icon: 'edit',
        //     href: '/editor/' + state.key
        //   })
        }
      ]
    }
  }
}

export const ExportComponentTemplate = {
  extend: DefaultComponentTemplate,

  props: {
    padding: '0',
    lazyLoad: true,
    '@dark': { border: '1px, solid, --color-line-dark' },
    '@light': { border: '1px, solid, --color-line-light' }
  },

  Link: ({ state }) => ({
    href: `/export/${state.key}`,
    position: 'absolute',
    inset: '0 0 0 0',
    zIndex: '1'
  }),

  Iframe: {
    on: {
      render: (el, s) => {
        return intersectionObserverToLoadComponent(el, {
          useLibrary: true,
          frameProps: {
            align: 'center center',
            position: 'absolute',
            inset: '0 0 0 0',
            boxSize: '100%',
            padding: 'C'
          }
        })
      }
    }
  },

  Flex: {
    NavbarButtonSet: null
  }
}
