'use strict'

import { set } from '@symbo.ls/scratch'

import { ANIMATION } from './animation'
import { RESET } from './reset'
import { COLOR, GRADIENT } from './color'
import { THEME } from './theme'
import { FONT, FONT_FAMILY } from './font'
import { TYPOGRAPHY } from './typography'
import { SPACING } from './spacing'
import { ICONS } from './icons'
import { TIMING } from './timing'
import { MEDIA } from './media'

const CASES = {
  isDark: () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
  isLight: () => window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches
}

export default {
  ANIMATION,
  RESET,
  COLOR,
  GRADIENT,
  THEME,
  FONT,
  FONT_FAMILY,
  ICONS,
  TYPOGRAPHY,
  SPACING,
  CASES,
  TIMING,
  MEDIA
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
  set({ CASES })
})
