'use strict'

import arrowAngleDown from './arrow-angle-down.svg'
import arrowAngleLeft from './arrow-angle-left.svg'
import arrowAngleRight from './arrow-angle-right.svg'
import arrowAngleUp from './arrow-angle-up.svg'
import arrowAngleUpLeft from './arrow-angle-up-left.svg'
import arrowAngleUpRight from './arrow-angle-up-right.svg'
import arrowAngleDownLeft from './arrow-angle-down-left.svg'
import arrowAngleDownRight from './arrow-angle-down-right.svg'
import arrowAngleMirroringHorizontal from './arrow-angle-mirroring-horizontal.svg'
import arrowAngleMirroringVertical from './arrow-angle-mirroring-vertical.svg'
import arrowAngleDiagonalLeft from './arrow-angle-mirroring-diagonal-left.svg'
import arrowAngleDiagonalRight from './arrow-angle-mirroring-diagonal-right.svg'
import arrowDown from './arrow-down.svg'
import arrowLeft from './arrow-left.svg'
import arrowRight from './arrow-right.svg'
import arrowUp from './arrow-up.svg'
import arrowUpLeft from './arrow-up-left.svg'
import arrowUpRight from './arrow-up-right.svg'
import arrowDownLeft from './arrow-down-left.svg'
import arrowDownRight from './arrow-down-right.svg'
import arrowMirroringHorizontal from './arrow-mirroring-horizontal.svg'
import arrowMirroringVertical from './arrow-mirroring-vertical.svg'
import arrowTriangleDown from './arrow-triangle-down.svg'
import arrowTriangleLeft from './arrow-triangle-left.svg'
import arrowTriangleRight from './arrow-triangle-right.svg'
import arrowTriangleUp from './arrow-triangle-up.svg'
import arrowTriangleUpLeft from './arrow-triangle-up-left.svg'
import arrowTriangleUpRight from './arrow-triangle-up-right.svg'
import arrowTriangleDownLeft from './arrow-triangle-down-left.svg'
import arrowTriangleDownRight from './arrow-triangle-down-right.svg'
import arrowTriangleMirroringVertical from './arrow-triangle-mirroring-vertical.svg'
import arrowTriangleMirroringHorizontal from './arrow-triangle-mirroring-horizontal.svg'
import arrowTriangleMirroringDiagonalLeft from './arrow-triangle-mirroring-diagonal-left.svg'
import arrowTriangleMirroringDiagonalRight from './arrow-triangle-mirroring-diagonal-right.svg'
import arrowSwitchVertical from './arrow-switch-vertical.svg'
import arrowSwitchHorizontal from './arrow-switch-horizontal.svg'
import arrowSwitchDiagonalLeft from './arrow-switch-diagonal-left.svg'
import arrowSwitchDiagonalRight from './arrow-switch-diagonal-right.svg'

import reload from './reload.svg'
import refresh from './refresh.svg'

import minus from './minus.svg'
import plus from './plus.svg'

import info from './info.svg'
import infoOval from './info-oval-fill.svg'

import checkmark from './checkmark.svg'
import crossmark from './crossmark.svg'

import questionMark from './question-mark.svg'
import questionMarkFill from './question-mark-fill.svg'

import logout from './logout.svg'

import shuffle from './shuffle.svg'
import shuffleAlt from './shuffle-alt.svg'
import shuffleAlt2 from './shuffle-alt-2.svg'

import reset from './reset.svg'

import play from './play-fill.svg'
import playOutline from './play-outline.svg'

export {
  arrowAngleDown,
  arrowAngleLeft,
  arrowAngleRight,
  arrowAngleUp,
  arrowAngleMirroringHorizontal,
  arrowAngleMirroringVertical,
  arrowAngleDiagonalLeft,
  arrowAngleDiagonalRight,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  arrowMirroringHorizontal,
  arrowMirroringVertical,
  arrowTriangleDown,
  arrowTriangleLeft,
  arrowTriangleRight,
  arrowTriangleUp,
  arrowTriangleMirroringVertical,
  arrowTriangleMirroringHorizontal,

  arrowAngleUpLeft,
  arrowAngleUpRight,
  arrowAngleDownLeft,
  arrowAngleDownRight,
  arrowUpLeft,
  arrowUpRight,
  arrowDownLeft,
  arrowDownRight,
  arrowTriangleUpLeft,
  arrowTriangleUpRight,
  arrowTriangleDownLeft,
  arrowTriangleDownRight,
  arrowTriangleMirroringDiagonalLeft,
  arrowTriangleMirroringDiagonalRight,
  arrowSwitchVertical,
  arrowSwitchHorizontal,
  arrowSwitchDiagonalLeft,
  arrowSwitchDiagonalRight,

  reload,
  refresh,

  minus,
  plus,

  info,
  infoOval,

  checkmark,
  crossmark,
  questionMark,
  questionMarkFill,

  logout,

  shuffle,
  shuffleAlt,
  shuffleAlt2,

  reset,
  play,
  playOutline
}
