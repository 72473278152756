'use strict'

import { Flex } from '@symbo.ls/ui'

export const OpenSource = {
  extend: Flex,
  props: {
    flow: 'column',
    alignItems: 'center',
    border: 'solid, darkBlue',
    borderWidth: '1px',
    padding: 'C C B1 C',
    round: 'A2',
    width: '100%'
  },

  TitleParagraph: {
    props: {
      alignItems: 'center',
      gap: 'B1+Y'
    },
    Title: {
      tag: 'h1',
      props: {
        text: 'Open-Source Ecosystem',
        fontSize: 'I2',
        theme: 'stroke',
        textStroke: '1.5px, darkBlue'
      }
    },
    Paragraph: {
      tag: 'p',
      props: {
        textAlign: 'center',
        flow: 'column',
        display: 'flex'
      },
      ...[
        { text: 'Develop with confidence, as Symbols is built from the ground up ' },
        'with open-source in mind.'
      ]
    }
  },

  Flex: {
    props: {
      gap: 'B2',
      margin: 'D1 - D -'
    },

    QuickStart: {}
  },

  Button: {
    text: 'Developer docs',
    fontSize: 'Z',
    theme: 'transparent',
    fontWeight: '400',
    flow: 'row-reverse',
    gap: 'Z1',
    padding: '0',
    color: 'grey',
    Icon: { name: 'arrowRight' }
  }

}
