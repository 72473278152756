'use strict'

import { Flex } from '.'

export const QuickStartNumber = {
  props: {
    width: '1ch',
    text: '1',
    fontSize: 'I1_default',
    lineHeight: '1',
    color: 'black 0',
    display: 'inline',
    fontWeight: '900',
    margin: '0',
    style: { userSelect: 'none' },
    '@tabletL': { margin: '5px 0 0 0' },
    '@dark': { textStroke: '1px, gray6' },
    '@light': { textStroke: '1px, gray11' }
  }
}

export const QuickStartCode = {
  extend: Flex,
  props: {
    margin: '0 -Y1',
    gap: 'Z2',
    alignItems: 'flex-start',

    Code: {
      margin: 'Y2 0 0 0',
      position: 'relative',
      style: { boxSizing: 'border-box' },
      minWidth: 'G_default',
      width: '100%',
      maxWidth: '100%',
      '@mobileXS': { minWidth: 'F3' },

      code: {
        padding: 'Z A',
        margin: '0',
        round: 'A'
      },

      buttons: {
        position: 'absolute',
        right: 'W',
        top: 'W',
        margin: '0',
        '@mobileXS': { right: 'V', top: 'W' },

        childProps: {
          fontSize: 'Z1'
        }
      }
    }
  },

  QuickStartNumber: {},
  Code: {
    extend: true,
    title: null,
    code: {},
    buttons: {
      copy: {},
      link: null,
      settins: null
    }
  }
}

export const QuickStartTerminal = {
  extend: Flex,

  props: {
    margin: '0',
    gap: 'A',
    align: 'flex-start',

    Terminal: {
      margin: 'Y2 0 0 0',
      minWidth: 'F3',
      maxWidth: '100%',

      copy: {
        fontSize: 'Z1'
      }
    }
  },

  QuickStartNumber: {},
  Terminal: {}
}

export const QuickStartElement = {
  extend: Flex,

  props: {
    flex: '1',
    padding: 'Z2 A1 X',
    flow: 'column',
    minWidth: 'G3',
    justifyContent: 'space-between',
    round: 'A',
    gap: 'X2',

    '@mobileS': {
      minWidth: '100%',
      maxWidth: '100%'
    },
    '@mobileXS': { padding: 'Z2 B1 Z1 Z1' },

    '@dark': {
      border: 'gray2 .35, solid',
      background: 'gray2',
      borderWidth: '0 0 0 1px'
    },
    '@light': {
      border: 'gray15 .5, solid',
      background: 'white .35',
      borderWidth: '0 0 0 1px'
    },

    style: {
      code: { transition: 'opacity 250ms' }
    },

    ':not(:hover)': {
      style: {
        filter: 'grayscale(100%)',
        code: { opacity: '.5' }
      }
    }
  },

  ArticleSmall: {
    gap: '0',
    title: {
      '@dark': { color: 'gray10' },
      '@light': { color: 'gray2' }
    }
  }
}
