'use strict'

import { Input } from 'smbls'
import { CommonFieldLike } from '.'

export const InputField = {
  extend: CommonFieldLike,
  props: {
    element: {
      boxSizing: 'border-box',
      theme: 'field',
      display: 'block',
      widthRange: '0 100%'
    }
  },

  title: { props: { text: 'Name the font' } },

  element: {
    extend: Input,
    props: {
      placeholder: 'Font name'
    }
  }
}
