'use strict'

import { DropdownParent } from '..'
import { SelectField } from '../Fields'

export const ThemeSelect = {
  extend: SelectField,

  props: {
    value: 'transparent',
    width: 'F2'
  },

  themePreview: {
    props: (el, s) => ({
      theme: s.value,
      width: 'B',
      height: 'B',
      round: 'W2',
      text: 'A'
    }),
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
    },

    dot: {
      style: {
        border: '2px solid #EDCB38',
        position: 'absolute',
        right: '2px',
        bottom: '6px'
      },
      props: (el, s) => ({
        width: 'X',
        height: 'X',
        padding: 0,
        round: 'A'
        // theme: [props.theme, 'helpers']
      })
    }
  },

  title: {
    props: (el, s) => ({
      text: s.value,
      padding: '0 0 0 Y',
      fontSize: 'A'
    }),

    style: { flex: 1 }
  },

  buttons: {
    colorInvert: { props: { icon: 'colorInvert' } }
  }
}

const ThemeDropdownItem = {
  extend: ThemeSelect,
  props: (el, s) => ({
    padding: 'Z1 A',
    theme: s.theme === el.key ? 'dropdownItemActive' : 'dropdownItem'
  }),
  buttons: null
}

export const ThemeDropwdown = {
  props: {
    header: { text: 'Pick a theme' }
  },

  ListInDropdown: {
    childExtend: ThemeDropdownItem,
    $setStateCollection: ({ state }) => {
      const THEME = state.__root.DATA.designSystem.THEME
      if (!THEME) return
      const allColorKeys = Object.keys(THEME)
      return allColorKeys.map(key => ({
        key,
        props: {
          icon: '',
          value: key,
          text: null
        }
      }))
    }
  },

  on: {
    choose: (ev, el, s, ctx) => {
      s.update({ value: ctx.value })
    }
  }
}

export const ThemeSelectDropdown = {
  extend: [ThemeSelect, DropdownParent],
  Dropdown: ThemeDropwdown
}

// needed for scene
// props: (el, s) => {
//   const props = el.lookup('content').props.defaultProps
//   if (!props) return
//   return {
//     value: s.theme || props.theme
//   }
// },
