'use strict'

import { Link, IconButton, NavbarButtonSet, PropsSearch } from '..'
import { SelectDropdown } from '../Selects'
import { getActiveRoute } from '@symbo.ls/router'

export const SearchComponents = {
  extend: PropsSearch,

  props: {
    placeholder: 'Search components',
    autofocus: true,
    Icon: {},
    Input: {
      paddingBlock: 'Z+W',
      theme: 'secondary',

      '@dark': {
        color: 'white',
        ':placeholder': { color: 'gray8' }
      },
      '@light': {
        color: 'gray2',
        ':placeholder': { color: 'gray6' }
      }
    }
  }

}

export const FilterCategories = {
  extend: SelectDropdown,

  props: {
    theme: 'quinary',
    background: 'transparent'
  },

  title: {
    value: {
      text: ({ state }) => {
        const route = getActiveRoute(1)
        if (!route) return 'Categories'
        const activeCategory = state.CATEGORIES.filter(v => v.key === route.slice(1))[0]
        if (!activeCategory) return 'Categories'
        return activeCategory.text
      }
    }
  },

  buttons: {
    arrow: {
      props: {
        background: 'transparent'
      }
    }
  },

  Dropdown: {
    props: {
      top: '121%'
    },

    DropdownHeader: { text: 'Categories' },

    ListInDropdown: {
      childExtend: {
        extend: [{
          props: (el, s) => ({
            active: s.parent.parent.category === el.key,
            text: s.text,
            href: `/components/${s.key}`
          })
        }, Link],

        props: {
          fontWeight: '400'
        },

        on: {
          click: (ev, el, s) => {
            if (s.category === el.key) s.update({ category: null })
            else s.update({ category: s.key })
            Link.on.click(ev, el, s)
          }
        }
      },

      $setStateCollection: ({ state }) => state.CATEGORIES
    }
  }
}

const FilterButton = {
  extend: IconButton,

  props: ({ parent, key }, s) => {
    const filterType = parent.parent.key
    const active = s.filters[filterType] === key
    return {
      active,
      color: 'caption',
      background: 'transparent',
      padding: 'Y2',
      Icon: {
        active,
        name: active ? key : key + ` outline`,
        '.active': { name: key }
      },

      theme: null,
      ':hover': { theme: 'quinary' },
      '.active': {
        theme: 'quinary .active',
        Icon: { name: key }
      }
    }
  },

  on: {
    click: (ev, el, s) => {
      const filterType = el.parent.key
      s.update({
        filters: {
          [filterType]: s.filters[filterType] === el.key ? null : el.key
        }
      })
    }
  }
}

export const FilterInteractivity = {
  key: 'interactivity',
  extend: NavbarButtonSet,
  childExtend: FilterButton,

  hover: {},
  click: {},
  choose: {},
  drag: {},
  input: {},
  focus: {}
}

export const FilterDataTypes = {
  key: 'dataTypes',
  extend: NavbarButtonSet,
  childExtend: FilterButton,

  boolean: {},
  int: {},
  string: {},
  object: {},
  array: {},
  date: {}
}

export const ComponentsGridViews = {
  key: 'gridviews',
  extend: NavbarButtonSet,
  childExtend: FilterButton,

  star: {
    props: (el, s) => ({
      Icon: { name: s.favorites ? 'star' : 'star outline' },
      href: null
    }),
    on: {
      click: (ev, el, s) => {
        s.update({ favorites: !s.favorites })
      }
    }
  },

  layout: {
    props: (el, s) => {
      const active = s.view === 'grid'
      return {
        active,
        href: null,
        Icon: { name: active ? 'layout' : 'layout outline' }
      }
    },
    on: {
      click: (ev, el, s) => {
        s.update({ view: 'grid' })
      }
    }
  },

  columns: {
    props: (el, s) => {
      const active = s.view === 'column'
      return {
        active,
        href: null,
        Icon: { name: active ? 'columns' : 'columns outline' }
      }
    },
    on: {
      click: (ev, el, s) => {
        s.update({ view: 'column' })
      }
    }
  },

  rows: {
    props: (el, s) => {
      const active = s.view === 'row'
      return {
        active,
        href: null,
        Icon: { name: active ? 'rows' : 'rows outline' }
      }
    },
    on: {
      click: (ev, el, s) => {
        s.update({ view: 'row' })
      }
    }
  }
}
