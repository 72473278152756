'use strict'

import { Flex, Grid } from '@symbo.ls/ui'

import { SingleClick } from '../BannerShmaneri'

export const CustomizeSingleClick = {
  tag: 'section',
  extend: Flex,
  props: {
  },

  BannerShmaneri: { extend: SingleClick },

  Flex: {
    props: {
      width: '100%',
      align: 'flex-start space-between',
      padding: 'C3 A2 D+X1 A2'
    },

    SectionTitle: {
      props: {},
      ...[
        {
          props: {
            text: 'Make any changes to your components. ',
            fontWeight: '700'
          }
        },
        'Save yourself weeks of work by automatically updating any of all components in your library.'
      ]
    },

    AccessButtons: {}
  },

  Gifs: {
    extend: Grid,
    props: {
      boxSize: 'G 100%',
      columns: '1fr 0.5fr 1fr',
      gap: 'Z2',
      boxSizing: 'border-box'
    },
    childExtend: {
      props: {
        theme: 'secondary',
        round: 'A'
      }
    },
    ...[{}, {}, {}]
  },

  SectionTitle: {
    props: {
      alignSelf: 'flex-start',
      margin: 'C - B3 A2'
    },
    ...[
      {
        props: {
          text: 'Use components anywhere ',
          fontWeight: '700'
        }
      },
      'by switching between frameworks, and integrating into your existing tech stack. Including injecting components into websites built on Wordpress, Shopify, Webflow and more.'
    ]
  },

  FrameworkCardsSwitcher: {
    props: {
      width: '100%'
    },
    title: null
  },

  Flex_2: {
    props: {
      justifyContent: 'space-between',
      margin: 'C - B3 A2',
      width: '100%'
    },
    p: {
      props: {
        text: 'We often add new integration to the system where you can easily convert our components to your stack.',
        maxWidth: 'G3'
      }
    },

    ParagraphButton: {
      fontSize: 'Z1',
      gap: 'A1',
      P: {
        text: 'Not your stack?'
      },

      Button: {
        text: 'Request a feature',
        textDecoration: 'none',
        flow: 'row-reverse',
        gap: 'A2',
        Icon: {
          display: 'block',
          name: 'info'
        }
      }

    }
  }
}
