'use strict'

import { Link } from '..'
import { Navbar } from './index'
// import { ComponentsGridViews } from '../ComponentFilters'

export const ComponentsNavbar = {
  extend: Navbar,

  NavbarButtonSet: {
    props: {},

    childExtend: {
      extend: [Link],
      props: ({ props }) => ({
        active: window.location.pathname === props.href
      })
    },

    library: {
      props: {
        icon: 'library outline',
        href: '/library'
      }
    },

    components: {
      props: {
        icon: 'grid',
        href: '/components'
      }
    }
  },

  SearchComponents: {
    margin: '- - - C1'
  },

  FilterCategories: {
    margin: '- auto - C1'
  },

  // FilterInteractivity: {
  //   props: {},
  //   if: (el, s) => s.__root.userToken
  // },

  // FilterDataTypes: {
  //   props: {},
  //   if: (el, s) => s.__root.userToken
  // },

  // ComponentsGridViews,

  NavbarTheming: { margin: '0' }
}
