'use strict'

import { generateSequence } from '@symbo.ls/scratch'

export const sortSequence = (a, b) => a.index - b.index

export const mapSequence = (state, sort) => {
  generateSequence(state)
  const { sequence } = state
  if (sort) {
    const values = Object.values(sequence)
    return values.sort(sort)
  }
  return sequence
}
