'use strict'

import { ButtonOption } from '..'

export const SurveyRankingButton = {
  extend: [ButtonOption]
}

export const SurveyRanking = {
  props: {},
  title: {},
  element: {}
}
