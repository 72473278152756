'use strict'

import { Link, Article, NumbCaption, DotCaption } from '.'

export const NumbCaptionArticleBig = {
  extend: Article,
  props: {
    gap: 'X2',
    p: { padding: '0 0 0 A2' }
  },
  title: { extend: NumbCaption }
}

export const NumbCaptionArticleSmall = {
  extend: Article,
  props: {
    gap: 'X',
    p: { padding: '0 0 0 A2' }
  },
  title: {
    extend: NumbCaption,
    props: { gap: '0' }
  }
}

export const DotCaptionArticle = {
  extend: Article,
  props: {
    gap: 'W1',
    p: { padding: '0 0 0 A1' }
  },
  title: {
    extend: [Link, DotCaption],
    props: 'match'
  }
}
