'use strict'

import { setCookie } from '@domql/cookie'
import { router } from '@symbo.ls/router'
import { Link, Avatar, Clickable, CommonSection } from '.'

const props = {
  minWidth: 'fit-content',
  align: 'center center',
  gap: 'A',
  element: {
    fontSize: 'A',
    boxSize: 'D2',
    round: 'A2'
  },
  title: {
    fontWeight: '400',
    color: 'white',
    fontSize: 'A'
  }
}

export const ProjectTab = {
  extend: [Link, CommonSection],
  props,

  element: {
    extend: [Clickable, Avatar],
    props: ({ state }) => ({
      key: state.key,
      src: state.icon && state.icon.src,
      text: state.name
    })
  },

  title: { text: ({ state }) => state.name },

  on: {
    click: async (ev, el, s, ctx) => {
      setCookie('activeProject', el.key + '', 3)
      const { id, name } = s.parse()
      await ctx.utils.fetchProject(el, s.parent, {
        username: name,
        projectId: id,
        activeProject: el.key
      })
      router('/init/personalize', el.__ref.__root, {})
    }
  }
}
