'use strict'

import { Flex } from '@symbo.ls/ui'

export const NumbTitleSteps = {
  extend: Flex,
  props: {
    gap: 'B2'
  },
  childExtend: {
    tag: 'h6',
    props: {
      fontSize: 'A2',
      fontWeight: '100',
      '.isActive': {
        fontWeight: '700'
      }
    }

  },
  ...[{
    props: { text: '1. Import' }
  }, {
    props: {
      text: '2. Automate',
      isActive: true
    }
  }, {
    props: { text: '3. Export' }
  }]
}
