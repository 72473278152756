'use strict'

import { copyStringToClipboard } from '@symbo.ls/utils'
import { ClickableItem, Link, IconText } from '@symbo.ls/ui'

export const IconTemplate = {
  extend: [ClickableItem, Link, IconText],

  props: ({ state }) => ({
    round: 'X',
    size: 'C1',
    scrollToTop: false,
    aspectRatio: '1 / 1',
    flexAlign: 'center center',
    href: '/icons/edit-icon/' + state.value,
    theme: 'secondary',
    transition: 'A defaultBezier',
    transitionProperty: 'transform, opacity, border-radius',
    Icon: {
      transition: 'A defaultBezier font-size',
      fontSize: 'C2',
      src: state.__root.DATA.designSystem.ICONS[state.value]
    },
    ':hover': {
      transform: 'scale(1.015)',
      '> svg': {
        fontSize: 'C1'
      }
    }
  }),

  on: {
    click: (event, element, state) => {
      copyStringToClipboard(state.value)
      Link.on.click(event, element, state)
    }
  }
}

export const IconsGroup = {
  state: { toggleShow: true },
  props: ({ state }) => ({
    lazyLoad: true,
    hide: !Object.keys(state.list).length
  }),

  SectionHeader: {
    extend: true,
    heading: {},
    nav: {
      add: {
        props: {
          icon: 'plus',
          href: '/icons/add-icon'
        }
      }
    }
  },

  Grid: {
    props: ({ state }) => ({
      hide: !state.toggleShow,
      columns: 'repeat(12, 1fr)',
      gap: 'X',
      margin: '- -X2'
    }),
    childExtend: IconTemplate,
    $setStateCollection: ({ state }) => state.list
  }
}
