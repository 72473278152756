'use strict'

import { Flex, IconText } from "@symbo.ls/ui"
import { Icon } from "smbls"

export const UnlimitComponents = {
  extend: Flex,
  props: {
    theme: 'seaBlue',
    width: '100%',
    round: 'A2',
    padding: 'A1 D A1 C',
   justifyContent: 'space-between'
  },

  Flex: {
    props: {
      flow: 'column',
      justifyContent: 'space-between',
      padding: 'A1 -'
    },

    H1: {
      text: 'Create unlimited components',
      fontSize: 'I2',
      maxWidth: 'D2+A',
      lineHeight: '1em',
      fontWeight: '900'
    },

    list: {
      extend: Flex,
      props: {
        flow: 'column',
        alignItems: 'flex-start',
        gap: 'B1'
      },
      childExtend: {
        extend: IconText,
        props: {
          Icon:{
            name: 'arrowDown',
            opacity: '.65'
          },
          fontSize: 'A2',
          gap: 'Z',
          color: 'mosqueGreen'
        }
      },
      ...[{
        props: { text: '300+ components' }
      },{
        props: { text: 'No-code customisation' }
      },{
        props: { text: 'Apply functions' }
      }, {
        props: { text: 'Seamless integration' }
      }]
    }
  },

  Gif: {
    boxSize: 'H+B2 H',
    background: '#141416',
    round: 'A'
  }
}